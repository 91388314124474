import { Children, ReactNode } from 'react'
import { ToggleButtonGroup, ToggleButton, SxProps } from '@mui/material'

interface ToggleButtonProps {
  label: ReactNode
  value: string | number | boolean
}

interface ToggleButtonsProps {
  fullWidth?: boolean
  value: string | number | boolean
  onChange?: (value: string | number | boolean) => void
  buttons: ToggleButtonProps[]
  sx?: SxProps
  disabled?: boolean
}

const ToggleButtons = (props: ToggleButtonsProps) => {
  const { fullWidth = false, value, onChange, buttons, sx, disabled } = props
  return (
    <ToggleButtonGroup
      disabled={disabled}
      sx={{ height: '3.5rem', ...sx }}
      fullWidth={fullWidth}
      exclusive
      size="small"
      value={value}
      onChange={(_e, value) => {
        if (value === null) return
        if (onChange) onChange(value)
      }}
    >
      {Children.toArray(
        buttons.map((button) => (
          <ToggleButton value={button.value}>{button.label}</ToggleButton>
        )),
      )}
    </ToggleButtonGroup>
  )
}

export default ToggleButtons
