import { TextField, InputAdornment } from '@mui/material'
import { useRef } from 'react'

interface InputFieldProps {
  label: string
  fullWidth?: boolean
  isValid?: boolean
  value: string | undefined
  onChange?: (value: string | undefined) => void
  transformation?: 'uppercase'
  inputValidation?: RegExp
  type?: 'number' | 'text'
  startAdornment?: string
  endAdornment?: string
  shrinkLabel?: boolean
  disabled?: boolean
  displayValidation?: boolean
  required?: boolean
  min?: number
  max?: number
  rows?: number
}

const InputField = (props: InputFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    label,
    value,
    onChange,
    isValid = true,
    fullWidth = false,
    transformation,
    inputValidation,
    type = 'text',
    startAdornment,
    endAdornment,
    shrinkLabel,
    disabled = false,
    displayValidation = false,
    required = false,
    min,
    max,
    rows,
  } = props

  return (
    <TextField
      required={required}
      disabled={disabled}
      type={type}
      inputProps={{
        ref: inputRef,
        min: min,
        max: max,
      }}
      multiline={!!rows}
      rows={rows}
      label={label}
      value={value || ''}
      error={displayValidation && !isValid}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : undefined,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : undefined,
      }}
      InputLabelProps={{
        shrink: shrinkLabel,
      }}
      onChange={(e) => {
        let value = e.target.value
        let cursorLocation = inputRef.current?.selectionStart || 0

        // Transform the input value if needed
        if (transformation === 'uppercase') {
          value = value.toUpperCase()
        }

        // If there is no value, no validation, or the input passes validation update the value
        if (!value || !inputValidation || inputValidation.test(value)) {
          if (onChange) onChange(!value ? undefined : value)
        } else {
          // If there is validation and it doesn't pass validation, don't update the value and return
          // the cursor to its original location
          cursorLocation -= 1

          setTimeout(() => {
            inputRef.current?.setSelectionRange(cursorLocation, cursorLocation)
          }, 0)
        }
      }}
    />
  )
}

export default InputField
