// updateUserDetails

import { useMutation } from 'react-query'
import { updateUserDetails } from 'utils/api/user.api'
import { queryClient } from 'utils'
import { UserEndpoint, UpdateUserRequest } from 'utils/api/user.types'

interface MutateUserData {
  data: UpdateUserRequest
  userId: string
}

const useMutateUserData = () => {
  const { mutateAsync, isLoading } =
    useMutation<unknown, unknown, MutateUserData>(
      ({ data, userId }) => updateUserDetails(data, userId),
      {
        onSuccess: (_, { userId }) => { queryClient.invalidateQueries(`${UserEndpoint.User}/${userId}`) }
      }
    )

  return { mutateAsync, isLoading }
}

export default useMutateUserData