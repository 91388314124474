/* eslint-disable unicorn/filename-case */
const provinces = {
  ALBERTA: 'Alberta',
  BRITISH_COLUMBIA: 'British Columbia',
  MANITOBA: 'Manitoba',
  NEW_BRUNSWICK: 'New Brunswick',
  NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
  NORTHWEST_TERRITORIES: 'Northwest Territories',
  NOVA_SCOTIA: 'Nova Scotia',
  NUNAVUT: 'Nunavut',
  ONTARIO: 'Ontario',
  PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
  QUEBEC: 'Quebec',
  SASKATCHEWAN: 'Saskatchewan',
  YUKON: 'Yukon',
}

const docTypes = {
  WILL: 'will',
  PERSONAL_POA: 'personalPoa',
  PROPERTY_POA: 'propertyPoa',
  WISHES: 'wishes',
  /*
   * Do not remove, even after COVID situation. Some users could still have
   * COVID doc in their documents array. This doc must remain defunct forever.
   */
  COVID: 'covid',
}

const docSectionTypes = {
  COVER: 'cover',
  MAIN: 'main',
  FEE_AGREEMENT: 'fee-agreement',
  END: 'end',
}

const socialDocTypes = {
  social: {
    type: 'social',
    isFree: true,
    answerStoreUpdatedSinceGenerateType:
      'socialAnswerStoreUpdatedSinceGenerate',
  },
}

const worldTypes = {
  WILL: 'will',
  SOCIAL: 'social',
}

const dropOffTypes = {
  SPOUSAL_TRUST: 'spousalTrust',
  FAMILY_DISABILITY: 'familyDisability',
  NOT_RIGHT_FOR_ME: 'notRightForMe',
  FOREIGN_PROPERTY: 'foreignProperty',
}

/*
 * The User object has a 'codes' field that takes an array of objects.
 * The objects in the 'codes' array contains a property of 'label', these
 * are the potential options for that label
 */
const codeLabels = {
  // TAG: notice connect noticeConnect notice-connect
  NOTICE_CONNECT: 'notice-connect',
  CUSTOMER_REFERRAL: 'customer-referral',
}

const productTypes = {
  WILL: 'will',
  WILL_POA: 'willPoa',
}

const notifierTypes = {
  TEST: 'test',
  ERROR: 'error',
  PAYMENT: 'payment',
  NOTIFICATION: 'notification',
}

const addOnTypes = {
  PRINTING: {
    type: 'printing',
    label: 'Printing + Shipping',
    oneTimeUse: true,
    price: {
      individual: 24,
      couples: 44,
    },
  },
}

const roleTypes = {
  user: {
    type: 'user',
    roles: ['user'],
  },
  admin: {
    type: 'admin',
    roles: ['admin', 'user'],
  },
  superAdmin: {
    type: 'superAdmin',
    roles: ['superAdmin', 'admin', 'user'],
  },
}

const corporateExecutors = {
  rbc: { contactFormEmail: 'rbctru@rbc.com' },
}

const referrerProperties = ['ref', 'reftag']

const logLevelTypes = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
}

const charityCategories = {
  ANIMALS: 'Animals',
  ARTS_AND_CULTURE: 'Arts & Culture',
  COMMUNITY_BENEFIT: 'Community Benefit',
  EDUCATION: 'Education',
  ENVIRONMENT: 'Environment',
  HEALTH: 'Health',
  INDIGENOUS_LED: 'Indigenous-Led',
  INTERNATIONAL: 'International',
  RELIGION: 'Religion',
  SOCIAL_SERVICE: 'Social Service',
  SPORTS_RECREATION: 'Sports/Recreation',
} as const

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  docTypes,
  logLevelTypes,
  docSectionTypes,
  socialDocTypes,
  dropOffTypes,
  bonusDocs: [docTypes.WISHES],
  tagAlongDocs: [],
  defunctDocs: [docTypes.COVID],
  addOnTypes,
  roleTypes,
  worldTypes,
  productTypes,
  notifierTypes,
  codeLabels,
  provinces,
  referrerProperties,
  corporateExecutors,
  // TAG: province-specific
  supportedProvinces: [
    provinces.ALBERTA,
    provinces.BRITISH_COLUMBIA,
    provinces.MANITOBA,
    provinces.NEW_BRUNSWICK,
    provinces.NEWFOUNDLAND_AND_LABRADOR,
    provinces.NOVA_SCOTIA,
    provinces.ONTARIO,
    provinces.PRINCE_EDWARD_ISLAND,
    provinces.SASKATCHEWAN,
  ],
  externalUserPermissions: ['create:questionnaire'],
  epiloguePhoneNumber: '(289) 678-1689',
  charityCategories,
}
