import { useState } from 'react'
import { Button, Box, Alert } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { Modal } from 'components'
import { sendResetPassword } from 'utils/api/user.api'

const PasswordResetEmailConfirmationModal = ({
  email,
  show,
  setShow,
}: {
  email: string
  show: boolean
  setShow: (show: boolean) => void
}) => {
  const [isWorking, setIsWorking] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const resetState = () => {
    setIsWorking(false)
    setIsSuccessful(false)
    setError(undefined)
  }

  const onSend = () => {
    setIsWorking(true)
    setIsSuccessful(false)
    setError(undefined)

    sendResetPassword(email)
      .then(() => {
        setIsSuccessful(true)
      })
      .catch((res) => {
        setError(
          res.response.data.errorMessage ||
            'Failed to send reset password email',
        )
      })
      .finally(() => {
        setIsWorking(false)
      })
  }

  return (
    <Modal
      title="Send Reset Password Request Email"
      onClose={() => {
        setShow(false)
      }}
      maxWidth="sm"
      show={show}
      working={isWorking}
      actions={
        <>
          <Button
            onClick={() => {
              resetState()
              setShow(false)
            }}
            variant="outlined"
          >
            Close
          </Button>
          <Button variant="contained" onClick={onSend} disabled={isSuccessful}>
            Send
          </Button>
        </>
      }
    >
      <Box sx={{ textAlign: 'center', lineHeight: 1.5 }}>
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}

        {!isSuccessful && (
          <>You are about to send a 'Forgot Password' email to {email}</>
        )}

        {isSuccessful && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: 2,
            }}
          >
            <CheckCircleOutlineIcon fontSize="large" color="success" />
            'Forgot Password' email sent successfully
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default PasswordResetEmailConfirmationModal
