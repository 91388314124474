import { useMutation } from 'react-query'

import queryClient from 'utils/query/queryClient'
import { createLawyer } from 'utils/api/lawyer.api'
import { Lawyer, LawyerEndpoint } from 'utils/api/lawyer.types'


const useCreateLawyer = () => {
  const { mutateAsync, isLoading } =
    useMutation<unknown, unknown, Lawyer>(
      (data) => createLawyer(data),
      {
        onSuccess: () => {
          Object.values(LawyerEndpoint).forEach(endpoint => {
            queryClient.invalidateQueries(endpoint)
          })
        }
      }
    )

  return { mutateAsync, isLoading }
}

export default useCreateLawyer
