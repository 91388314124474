
const copyText = (value: string) => {
  try {
    navigator.clipboard.writeText(value)
  } catch (error) {
    window.alert('Failed to copy to clipboard')
    console.error(error)
  }
}

export default copyText