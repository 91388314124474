import { Box, Paper, Typography } from '@mui/material'
import { Children } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { format } from 'utils'

const INNER_LABEL_CUTOFF = 0.15

interface PieChartData {
  id: string
  value: number
  color: string
}

interface PieChartProps {
  data: PieChartData[]
  title?: string
  elevation?: number
  legendFormatter?: (value: string) => string
}

const Legend = ({
  data,
  formatter,
}: {
  data: PieChartData[]
  formatter?: (value: string) => string
}) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: `repeat(${
        data.length < 3 ? data.length : 3
      }, 1rem 1fr)`,
      gridTemplateRows: `repeat(${Math.ceil(data.length / 3)}, 1fr)`,
      gridColumnGap: '0.4rem',
      gridRowGap: '0.5rem',
    }}
  >
    {data
      .map(({ id, color }) => [
        <Box
          sx={{
            width: '1rem',
            height: '1rem',
            position: 'relative',
            top: '0.25rem',
            backgroundColor: color,
            borderRadius: '0.25rem',
          }}
        />,
        <Typography
          sx={{
            mr: '0.5rem',
          }}
        >
          {formatter ? formatter(id) : format.titleCase(id)}
        </Typography>,
      ])
      .flat()}
  </Box>
)

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
  index: number
}) => {
  if (percent < INNER_LABEL_CUTOFF) {
    const radius = outerRadius * 1.55
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180)
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180)

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor="middle"
        fontSize="smaller"
        dominantBaseline="central"
      >
        {format.percentage(percent * 100)}
      </text>
    )
  } else {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180)
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        fontSize="smaller"
        dominantBaseline="central"
      >
        {format.percentage(percent * 100)}
      </text>
    )
  }
}

const CustomizedLabelLine = (props: {
  stroke: string
  points: { x: number; y: number }[]
  value: number
  title: string
  percent: number
}) => {
  const { value, points, stroke, percent } = props

  return value !== 0 && percent <= INNER_LABEL_CUTOFF ? (
    <path
      stroke={stroke}
      d={`M${points[0].x},${points[0].y}L${points[1].x},${points[1].y}`}
    />
  ) : (
    <polyline stroke={stroke} fill="none" />
  )
}

const _PieChart = (props: PieChartProps) => {
  const { data, title, elevation = 8, legendFormatter } = props

  return (
    <Paper
      elevation={elevation}
      sx={{
        p: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {title && <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>}
      <PieChart width={300} height={300}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          label={CustomLabel}
          labelLine={CustomizedLabelLine}
          fill="#8884d8"
          dataKey="value"
          animationEasing="ease-in"
          animationDuration={500}
          minAngle={1.8}
          paddingAngle={1}
        >
          {Children.toArray(
            data.map(({ color, value }) => (
              <Cell fill={color} focusable={false} />
            )),
          )}
        </Pie>
      </PieChart>
      <Legend data={data} formatter={legendFormatter} />
    </Paper>
  )
}

export default _PieChart
