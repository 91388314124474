import {
  Box,
  Theme,
  Dialog,
  IconButton,
  Breakpoint,
  DialogContent,
  useMediaQuery,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import { ReactNode } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

interface ModalProps {
  onClose: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton',
  ) => void
  show: boolean
  title?: string
  working?: boolean
  children: ReactNode
  fullBleed?: boolean
  actions?: ReactNode
  preventClose?: boolean
  maxWidth?: false | Breakpoint
  fullscreenBreakpoint?: Breakpoint
}

const Modal = (props: ModalProps) => {
  const {
    show,
    title,
    onClose,
    actions,
    children,
    working = false,
    maxWidth = false,
    fullBleed = false,
    preventClose = false,
    fullscreenBreakpoint = 'sm',
  } = props

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(fullscreenBreakpoint),
  )

  return (
    <Dialog
      open={show}
      onClose={(event, reason) => {
        if (!preventClose) {
          onClose(event, reason)
        }
      }}
      PaperProps={{ style: { position: 'relative' } }}
      maxWidth={maxWidth}
      fullScreen={isMobile}
    >
      {!preventClose && (
        <IconButton
          onClick={(event) => {
            onClose(event, 'closeButton')
          }}
          sx={{
            zIndex: 9999,
            top: '0.05rem',
            right: '0.05rem',
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,0.4)',
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
      )}
      {title && (
        <DialogTitle sx={{ mx: !preventClose ? 4 : 0, my: 2 }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',

          padding: fullBleed ? '0' : isMobile ? '1.5rem' : '2rem',
        }}
      >
        <Box
          sx={{
            m: 'auto',
            opacity: working ? '50%' : undefined,
            minHeight: '2rem',
            width: '100%',
          }}
        >
          {children}
        </Box>
        {working && <CircularProgress sx={{ position: 'absolute' }} />}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

export default Modal
