import { createWorkerFactory, useWorker } from '@shopify/react-web-worker'
import { useEffect, useMemo, useState } from 'react'
import { BusinessReportResponse } from 'utils/api/data.types'
import {
  ProcessedBusinessReportData,
  WorkerParams,
} from 'utils/workers/processBusinessDataWorkers.types'

interface UseProcessedBusinessReportDataParams {
  reportData: BusinessReportResponse[]
  setIsProcessingData: (isProcessingData: boolean) => void
  setProcessDataError: (error: string | undefined) => void
  setProcessingStatus: (status: string | undefined) => void
  setProcessingProgress: (process: number) => void
}

interface ProcessStep {
  name: string
  key: keyof ProcessedBusinessReportData
  function: (params: WorkerParams) => any
}

const createWorker = createWorkerFactory(
  () => import('../workers/processBusinessDataWorkers'),
)

const resetProcessingState = ({
  setIsProcessingData,
  setProcessDataError,
  setProcessingStatus,
}: Omit<
  UseProcessedBusinessReportDataParams,
  'reportData' | 'setProcessingProgress'
>) => {
  setIsProcessingData(true)
  setProcessDataError(undefined)
  setProcessingStatus(undefined)
}

const useProcessedBusinessReportData = ({
  reportData,
  setIsProcessingData,
  setProcessDataError,
  setProcessingStatus,
  setProcessingProgress,
}: UseProcessedBusinessReportDataParams) => {
  const worker = useWorker(createWorker)

  const [processedReportData, setProcessedReportData] = useState<
    ProcessedBusinessReportData | undefined
  >(undefined)

  const steps: ProcessStep[] = useMemo(
    () => [
      {
        name: 'How Did You Hear About Us',
        key: 'howDidYouHearAboutUs',
        function: worker.howDidYouHearAboutUs,
      },
      {
        name: 'Completion and Revenue Rates',
        key: 'completionAndRevenueRates',
        function: worker.completionAndRevenueRates,
      },
      {
        name: 'Promo Codes By Month',
        key: 'promoCodesByMonth',
        function: worker.promoCodesByMonth,
      },
      {
        name: 'Questionnaires Over Time',
        key: 'questionnairesOverTime',
        function: worker.questionnairesOverTime,
      },
      {
        name: 'Demographics',
        key: 'demographics',
        function: worker.demographics,
      },
      {
        name: 'RBC Monthly Report',
        key: 'rbcMonthlyReport',
        function: worker.rbcMonthlyReport,
      },
    ],
    [worker],
  )

  useEffect(() => {
    // Do nothing if there is no data
    if (reportData.length === 0) {
      return
    }

    resetProcessingState({
      setIsProcessingData,
      setProcessDataError,
      setProcessingStatus,
    })

    const process = async () => {
      const workerParams = {
        reportData,
      }

      try {
        const totalSteps = steps.length

        setProcessingProgress(0)

        const processedData: Partial<ProcessedBusinessReportData> = {
          howDidYouHearAboutUs: undefined,
          completionAndRevenueRates: undefined,
          promoCodesByMonth: undefined,
          questionnairesOverTime: undefined,
          demographics: undefined,
        }

        for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
          const step = steps[stepIndex]

          setProcessingStatus(`Processing '${step.name}' data`)
          processedData[step.key] = (await step.function(workerParams)) as any
          setProcessingProgress(((stepIndex + 1) / totalSteps) * 100)
        }

        setProcessedReportData(processedData as ProcessedBusinessReportData)
      } catch (error) {
        setProcessDataError((error as Error).message)
        setProcessedReportData(undefined)
      } finally {
        setProcessingProgress(100)
        setIsProcessingData(false)
        setProcessingStatus('Processing completed')
      }
    }

    process()
  }, [
    steps,
    worker,
    reportData,
    setIsProcessingData,
    setProcessDataError,
    setProcessingStatus,
    setProcessingProgress,
  ])

  return {
    processedReportData,
  }
}

export default useProcessedBusinessReportData
