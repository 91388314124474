import store from 'store2'

const write = (key: string, value: string) => {
  return store.set(key, value)
}

const read = (key: string) => {
  return store.get(key)
}

const del = (key: String) => {
  return store.remove(key)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  write,
  read,
  delete: del
}