import { Dispatch, SetStateAction } from 'react'

import {
  BusinessReportRequest,
  BusinessReportResponse,
} from 'utils/api/data.types'
import { ProcessedBusinessReportData } from 'utils/workers/processBusinessDataWorkers.types'

export enum ReportView {
  HowDidYouHerAboutUs = 'howDidYouHerAboutUs',
  CompletionAndRevenueRates = 'completionAndRevenueRates',
  PromoCodesByMonth = 'promoCodesByMonth',
  QuestionnairesOverTime = 'questionnairesOverTime',
  Demographics = 'demographics',
  RbcMonthlyReport = 'rbcMonthlyReport',
}

export interface Interface {
  view: ReportView
  setView: Dispatch<SetStateAction<ReportView>>

  filtersHeight: number
  setFiltersHeight: Dispatch<SetStateAction<number>>

  showFilters: boolean
  toggleShowFilters: () => void

  pinFilters: boolean
  togglePinFilters: () => void

  showLoadingOverlay: boolean
  setShowLoadingOverlay: Dispatch<SetStateAction<boolean>>
}

export interface DataContext {
  setReportData: Dispatch<SetStateAction<BusinessReportResponse[]>>
  reportData: BusinessReportResponse[]

  showData: boolean
  toggleShowData: Dispatch<SetStateAction<boolean>>

  isLoadingData: boolean

  processedReportData: ProcessedBusinessReportData | undefined

  isProcessingData: boolean
  processDataError: string | undefined
  processingStatus: string | undefined
  processingProgress: number

  currentlyProcessedCount: number
  setCurrentlyProcessedCount: Dispatch<SetStateAction<number>>

  dataLoadingError: string | undefined
  setDataLoadingError: Dispatch<SetStateAction<string | undefined>>

  refetchData: () => void

  hasInitialData: boolean
}

export interface FiltersContext {
  filters: BusinessReportRequest
  setFilters: Dispatch<SetStateAction<BusinessReportRequest>>

  debouncedFilters: BusinessReportRequest
}

export interface IBusinessReportContext {
  data: DataContext
  filters: FiltersContext
  interface: Interface
}
