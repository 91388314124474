import { useEffect, useState } from 'react'
import { Alert, CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { CharityForm } from 'components'
import { useGetCharityPartner, useMutateCharityPartner } from 'utils'

const CharityPartnerDetailsView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, isError, isLoading } = useGetCharityPartner(id || '')
  const { mutateAsync } = useMutateCharityPartner()
  const [error, setError] = useState<undefined | string>(undefined)

  useEffect(() => {
    if (isError) {
      navigate('/charity/view')
    }
  }, [isError, navigate])

  return isLoading ? (
    <>
      <CircularProgress
        sx={{ position: 'relative', mx: 'auto', top: '40vh' }}
      />
    </>
  ) : (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2, ml: 2, mr: 4 }}>
          {error}
        </Alert>
      )}
      <CharityForm
        initialCharityData={data}
        onSubmit={({ charityData }) => {
          mutateAsync(charityData)
            .then(() => {
              setError(undefined)
            })
            .catch((err) => {
              setError(err.response.data.errorMessage)
            })
        }}
      />
    </>
  )
}

export default CharityPartnerDetailsView
