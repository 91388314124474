import {
  Box,
  Theme,
  Paper,
  useMediaQuery,
  CircularProgress,
} from '@mui/material'
import { Children, useRef, useState, useEffect } from 'react'

import {
  GetCharityPartnersLimit,
  GetCharityPartnersSortMethod,
} from 'utils/api/charityPartner.api'
import { ColumnMetaData } from 'global.types'
import { TableControls, TableHeaderCell } from 'components'
import UsersTableDataRow from './components/CharitiesTableDataRow'
import { spreadSx, styles, useDebounce, useGetCharityPartners } from 'utils'

export const columnMetaData: ColumnMetaData[] = [
  {
    hideBelowBreakpoint: undefined,
    width: 2,
  },
  {
    hideBelowBreakpoint: undefined,
    width: 2,
  },
  {
    hideBelowBreakpoint: undefined,
    width: 1,
  },
  {
    hideBelowBreakpoint: undefined,
    width: 1,
  },
  {
    hideBelowBreakpoint: undefined,
    width: 2,
  },
]

const ListCharitiesView = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const containerRef = useRef<HTMLDivElement>(null)
  const controlsRef = useRef<HTMLDivElement>(null)
  const headerRowRef = useRef<HTMLDivElement>(null)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState<GetCharityPartnersLimit>(25)
  const [sort, setSort] = useState<GetCharityPartnersSortMethod>(
    GetCharityPartnersSortMethod.DateNew,
  )
  const [query, setQuery] = useState<string | undefined>(undefined)
  const [dataAreaHeight, setDataAreaHeight] = useState(0)

  const debouncedQuery = useDebounce(query, 500)

  const { data, isLoading } = useGetCharityPartners({
    page,
    sort,
    limit: isMobile ? 10 : limit,
    q: debouncedQuery,
  })

  const updateDataAreaHeight = () => {
    setDataAreaHeight(
      (containerRef.current?.offsetHeight || 0) -
        (controlsRef.current?.offsetHeight || 0) -
        (headerRowRef.current?.offsetHeight || 0) -
        44, // accounts for padding and margins
    )
  }

  useEffect(() => {
    updateDataAreaHeight()
    window.addEventListener('resize', updateDataAreaHeight)
    return () => window.removeEventListener('resize', updateDataAreaHeight)
  }, [])

  return (
    <Paper
      ref={containerRef}
      sx={{
        px: 2,
        flexGrow: 1,
      }}
    >
      <TableControls
        totalPages={data?.totalPages ? parseInt(data.totalPages) : 0}
        page={page}
        limit={limit}
        query={query}
        ref={controlsRef}
        setPage={setPage}
        setLimit={setLimit}
        setQuery={setQuery}
      />
      <Paper
        ref={headerRowRef}
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          columnGap: 2,
          mr: 2,
          pr: '8px', // Fixes odd column miss-aligning
          background: (theme: Theme) =>
            theme.palette.mode === 'dark' ? 'none' : undefined,
        }}
      >
        <TableHeaderCell
          width={columnMetaData[0].width}
          hideBelowBreakpoint={columnMetaData[0].hideBelowBreakpoint}
          sort={sort}
          setSort={setSort}
          sortAsc={GetCharityPartnersSortMethod.NameZA}
          sortDes={GetCharityPartnersSortMethod.NameAZ}
        >
          Name
        </TableHeaderCell>

        <TableHeaderCell
          width={columnMetaData[1].width}
          hideBelowBreakpoint={columnMetaData[1].hideBelowBreakpoint}
        >
          Display Name
        </TableHeaderCell>

        <TableHeaderCell
          width={columnMetaData[2].width}
          hideBelowBreakpoint={columnMetaData[2].hideBelowBreakpoint}
        >
          Location
        </TableHeaderCell>

        <TableHeaderCell
          width={columnMetaData[3].width}
          hideBelowBreakpoint={columnMetaData[3].hideBelowBreakpoint}
        >
          Slug
        </TableHeaderCell>

        <TableHeaderCell
          width={columnMetaData[4].width}
          hideBelowBreakpoint={columnMetaData[4].hideBelowBreakpoint}
          sort={sort}
          setSort={setSort}
          sortAsc={GetCharityPartnersSortMethod.DateOld}
          sortDes={GetCharityPartnersSortMethod.DateNew}
          sx={{ mr: '45px' }}
        >
          Created
        </TableHeaderCell>
      </Paper>
      <Box
        sx={spreadSx([
          {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isLoading ? 'center' : undefined,
            alignItems: isLoading ? 'center' : undefined,
            pb: 2,
            mb: 2,
            height: `${dataAreaHeight}px`,
            overflow: 'scroll',
          },
          styles.scrollbar,
        ])}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          Children.toArray(
            data?.items.map((charity) => (
              <UsersTableDataRow charity={charity} />
            )),
          )
        )}
      </Box>
    </Paper>
  )
}

export default ListCharitiesView
