import { request } from 'utils'

export interface PromoCode {
  isPercent: boolean
  amount: number
  prefix?: string
  code?: string
  expiryDate?: string
  oneTimeUse: boolean
  isActive: boolean
  numOfCodes?: number
  referrer?: Referrer
  description?: string
  usage?: PromoCodeUsage[]
  v?: number
  _id?: string
}

export interface PromoCodeUsage {
  userId: string,
  priceAfterDiscount: number,
  _id: string
}

export enum GetPromoCodeSortMethod {
  DateNew = 'dateNew',
  DateOld = 'dateOld',
  CodeAZ = 'codeAZ',
  CodeZA = 'codeZA',
}

export type GetPromoCodeLimit = 10 | 25 | 50

export interface GetPromoCodesParams {
  page: number
  limit: GetPromoCodeLimit,
  q?: string,
  sort?: GetPromoCodeSortMethod
}

export interface PromoCodePayload {
  totalPages: string
  items: PromoCode[]
}

export interface Referrer {
  ref: string
  reftag: string
}

export enum PromoCodeEndpoint {
  PromoCode = '/promo_code',
  GenerateCodes = '/promo_code/generate_codes',
}

export const createPromoCode = (data: PromoCode) =>
  request({ url: PromoCodeEndpoint.GenerateCodes, method: 'POST', data })

export const getPromoCodes = (params: GetPromoCodesParams): Promise<PromoCodePayload> => {
  return request({ url: PromoCodeEndpoint.PromoCode, method: 'GET', params }).then((res) => res.data.payload)
}

export const updatePromoCode = (data: PromoCode): Promise<PromoCode> => {
  return request({ url: `${PromoCodeEndpoint.PromoCode}/${data._id}`, method: 'PATCH', data }).then((res) => res.data.payload)
}