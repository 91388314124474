import { Alert, Box, Paper, Typography } from '@mui/material'

import { Tabs } from 'components'
import { useBusinessReportContext } from 'utils'
import ReportFilterMenu from './components/ReportFilterMenu'
import ReportLoadingOverlay from './components/ReportLoadingOverlay'
import { ReportView } from 'utils/context/BusinessReportContext.types'

import PromoCodesByMonth from './components/reports/PromoCodesByMonth'
import HowDidYouHearAboutUs from './components/reports/HowDidYouHearAboutUs'
import CompletionAndRevenueRates from './components/reports/CompletionAndRevenueRates'
import QuestionnairesOverTime from './components/reports/QuestionnairesOverTime'
import Demographics from './components/reports/Demographics'
import RbcMonthlyReport from './components/reports/RbcMonthyReport'

interface ReportViewOption {
  label: string
  id: ReportView
  view: JSX.Element
}

const views: ReportViewOption[] = [
  {
    label: 'HDYHAU',
    id: ReportView.HowDidYouHerAboutUs,
    view: <HowDidYouHearAboutUs />,
  },
  {
    label: 'Completion & Revenue Rates',
    id: ReportView.CompletionAndRevenueRates,
    view: <CompletionAndRevenueRates />,
  },
  {
    label: 'Promo Codes By Month',
    id: ReportView.PromoCodesByMonth,
    view: <PromoCodesByMonth />,
  },
  {
    label: 'Questionnaires Over Time',
    id: ReportView.QuestionnairesOverTime,
    view: <QuestionnairesOverTime />,
  },
  {
    label: 'Demographics',
    id: ReportView.Demographics,
    view: <Demographics />,
  },
  {
    label: 'RBC Monthly Report',
    id: ReportView.RbcMonthlyReport,
    view: <RbcMonthlyReport />,
  },
]

const BusinessReportView = () => {
  const {
    interface: { pinFilters, filtersHeight, view, setView },

    data: { processedReportData },
  } = useBusinessReportContext()

  const CurrentReportView = views.find(({ id }) => id === view)?.view ?? <></>

  return (
    <>
      <ReportFilterMenu />
      <ReportLoadingOverlay />
      {processedReportData ? (
        <Paper
          sx={{
            px: 2,
            flexGrow: 1,
            mt: pinFilters ? `${filtersHeight}px` : '27px',
          }}
        >
          <Tabs
            value={view}
            variant="scrollable"
            onChange={(selectedView) => {
              setView(selectedView as ReportView)
            }}
            tabs={views.map(({ label, id: value }) => ({
              label,
              value,
            }))}
          />
          <Box
            sx={{
              mb: 2,
              rowGap: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {CurrentReportView}
          </Box>
        </Paper>
      ) : (
        <Paper
          sx={{
            px: 2,
            flexGrow: 1,
            mt: pinFilters ? `${filtersHeight}px` : '27px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert severity="warning" sx={{ m: '1rem' }}>
            <Typography sx={{ mx: '1rem' }}>
              No data to display, use the filters menu to load data
            </Typography>
          </Alert>
        </Paper>
      )}
    </>
  )
}

export default BusinessReportView
