import _startCase from 'lodash/startCase'

import { BarChart } from 'components'
import { round, useBusinessReportContext } from 'utils'
import RawDataTable from '../RawDataTable'

const formatLabel = (label: string) =>
  label === 'rbc' ? 'RBC' : _startCase(label)

const HowDidYouHearAboutUs = () => {
  const {
    data: { processedReportData },
  } = useBusinessReportContext()

  if (!processedReportData?.howDidYouHearAboutUs.data) return <></>

  const {
    howDidYouHearAboutUs: { data },
  } = processedReportData

  return (
    <>
      <BarChart
        isInteractive
        height="20rem"
        leftLabel="Customers who selected option"
        data={Object.entries(data).map(([key, value]) => ({
          index: formatLabel(key),
          Selections: value.unpaid + value.paid,
          Paid: value.paid,
        }))}
        legend={['Selections', 'Paid']}
        maxValue={round.nearest(
          Math.max(...Object.values(data).map((d) => d.unpaid + d.paid)),
          { nearest: 500 },
        )}
      />

      <BarChart
        isInteractive
        height="20rem"
        leftLabel="Conversion Percentage"
        data={Object.entries(data).map(([key, value]) => ({
          index: formatLabel(key),
          'Conversion rate':
            value.paid > 0 ? value.paid / (value.unpaid + value.paid) : 0,
        }))}
        maxValue={1}
        valueFormat="^>-.2%"
      />

      <RawDataTable
        columnHeaders={Object.keys(data).map((key) => formatLabel(key))}
        rowHeaders={['Paid', 'Unpaid', 'Total']}
        data={Object.values(data)}
        valueFormat={[
          (data) => data.paid,
          (data) => data.unpaid,
          (data) => data.unpaid + data.paid,
        ]}
      />
    </>
  )
}

export default HowDidYouHearAboutUs
