import { useMutation } from 'react-query'
import queryClient from 'utils/query/queryClient'
import { CharityPartner, CharityPartnerEndpoint, updateCharityPartner } from 'utils/api/charityPartner.api'

const useMutateCharityPartner = () => {
  const { mutateAsync, isLoading } =
    useMutation<CharityPartner, unknown, CharityPartner>(
      (data) => updateCharityPartner(data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(CharityPartnerEndpoint.GetById)
          queryClient.invalidateQueries(CharityPartnerEndpoint.CharityPartner)
        }
      }
    )

  return { mutateAsync, isLoading }
}

export default useMutateCharityPartner