import {
  Box,
  Paper,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material'
import moment from 'moment'
import { Range } from 'react-date-range'
import _upperCase from 'lodash/upperCase'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useMemo, useRef, useState } from 'react'

import { getCharityPartnersCsv } from 'utils/api/data.api'
import { DataControls, DataGridToolbar, Tabs } from 'components'
import { useFormData, useGetCharityPartnerGiftsJson } from 'utils'
import { CharityPartnersResponse, DataEndpoint } from 'utils/api/data.types'

import columnDefs from './utils/columnDefs'
import { Filters } from '../components/StatsFilter'
import useAllDataGridRows from './utils/useAllDataGridRows'
import getInitialFilterData from './utils/getInitialFilterData'
import useTotalledDataGridRows from './utils/useTotalledDataGridRows'
import CharityPartnerGiftsStats from './components/CharityPartnerGiftsStats'

enum DisplayOption {
  All = 'all',
  Totalled = 'totalled',
  Stats = 'stats',
}

const CharityPartnerGiftsView = () => {
  const { data, isLoading } = useGetCharityPartnerGiftsJson()

  const [paperHeight, setPaperHeight] = useState(0)
  const [filterUngenerated, setFilterUngenerated] = useState(false)
  const [filterUnpaid, setFilterUnpaid] = useState(false)
  const [displayOption, setDisplayOption] = useState<DisplayOption>(
    DisplayOption.All,
  )

  const [range, setRange] = useState<Range>({
    startDate: new Date('2020-01-02'),
    endDate: new Date(),
    key: 'selection',
  })

  const paperRef = useRef<HTMLDivElement>(null)

  const filteredData: CharityPartnersResponse[] = useMemo(() => {
    return data
      ? [...data]
          .filter((gift) => (filterUngenerated ? gift.hasGenerated : true))
          .filter((gift) => (filterUnpaid ? gift.hasPaid : true))
          .filter((gift) =>
            gift.mostRecentGenerationDate
              ? moment(gift.mostRecentGenerationDate).isBetween(
                  range.startDate,
                  range.endDate,
                  undefined,
                  '[]',
                )
              : true,
          )
      : []
  }, [data, filterUngenerated, filterUnpaid, range.endDate, range.startDate])

  useEffect(() => {
    setPaperHeight(paperRef.current?.offsetHeight || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperRef.current, data])

  useEffect(() => {
    updateFilters(getInitialFilterData(filteredData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData])

  const [filters, updateFilters] = useFormData<Filters>(
    getInitialFilterData(filteredData),
  )

  const advancedFilteredData: CharityPartnersResponse[] = useMemo(() => {
    return [...filteredData]
      .filter((gift) => !!gift.age && gift.age >= filters.minAge)
      .filter((gift) => !!gift.age && gift.age <= filters.maxAge)
      .filter(
        (gift) => !!gift.province && filters.provinces.includes(gift.province),
      )
      .filter(
        (gift) =>
          !!gift.hasPartner && filters.hasPartner.includes(gift.hasPartner),
      )
      .filter(
        (gift) =>
          !!gift.hasChildren && filters.hasChildren.includes(gift.hasChildren),
      )
      .filter((gift) => filters.charities.includes(_upperCase(gift.name)))
      .filter((gift) => !!gift.gender && filters.genders.includes(gift.gender))
  }, [filteredData, filters])

  const allDataGridRows = useAllDataGridRows(filteredData)
  const totalledDataGridRows = useTotalledDataGridRows(filteredData)

  return (
    <>
      <DataControls
        invalidationKey={[DataEndpoint.CharityPartners]}
        getCsvRequest={getCharityPartnersCsv}
        downloadTitle="charity_partner_gifts.csv"
        range={range}
        setRange={setRange}
      />
      <Paper
        ref={paperRef}
        style={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper sx={{ height: '3rem', px: 1, display: 'flex' }} elevation={0}>
          <Tabs
            value={displayOption}
            disabled={isLoading}
            onChange={(value) => {
              setDisplayOption(value as DisplayOption)
            }}
            tabs={[
              { label: 'All', value: DisplayOption.All },
              { label: 'Totalled', value: DisplayOption.Totalled },
              { label: 'Stats', value: DisplayOption.Stats },
            ]}
          />
          <Box sx={{ flexGrow: 1 }}>
            <FormGroup row sx={{ justifyContent: 'flex-end', mt: '0.2rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={filterUngenerated}
                    onChange={() => setFilterUngenerated(!filterUngenerated)}
                  />
                }
                label="Filter ungenerated"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={filterUnpaid}
                    onChange={() => setFilterUnpaid(!filterUnpaid)}
                  />
                }
                label="Filter unpaid"
              />
            </FormGroup>
          </Box>
        </Paper>
        <Box
          sx={{
            width: '100%',
            height:
              displayOption === DisplayOption.Stats
                ? 'unset'
                : `calc(${paperHeight}px - 3rem)`,
          }}
        >
          {displayOption === DisplayOption.All && (
            <DataGrid
              key={DisplayOption.All}
              rows={allDataGridRows}
              columns={columnDefs.allDataColumns}
              components={{ Toolbar: DataGridToolbar }}
              loading={isLoading}
            />
          )}

          {displayOption === DisplayOption.Totalled && (
            <DataGrid
              key={DisplayOption.Totalled}
              rows={totalledDataGridRows}
              columns={columnDefs.totalledDataColumns}
              components={{ Toolbar: DataGridToolbar }}
              loading={isLoading}
            />
          )}

          {displayOption === DisplayOption.Stats && (
            <CharityPartnerGiftsStats
              filters={filters}
              data={advancedFilteredData}
              updateFilters={updateFilters}
              initialFilterData={getInitialFilterData(filteredData)}
            />
          )}
        </Box>
      </Paper>
    </>
  )
}

export default CharityPartnerGiftsView
