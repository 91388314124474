import { BarChart } from 'components'
import calculateGraphData from '../utils/calculateGraphData'
import { DistributionCharitiesResponse } from 'utils/api/data.types'

const ChartsByProvince = ({
  filteredData,
}: {
  filteredData: DistributionCharitiesResponse[]
}) => {
  const averageDonationsByProvince =
    calculateGraphData.averageDonationsByProvince(filteredData)

  const countOfDonationsByProvince =
    calculateGraphData.countOfDonationsByProvince(filteredData)

  return (
    <>
      <BarChart
        height="20rem"
        leftLabel="Average Donated Amount"
        data={averageDonationsByProvince}
        valueFormat={(value) => `${(value * 100).toFixed(2)}%`}
      />
      <BarChart
        height="20rem"
        leftLabel="Donation Count"
        data={countOfDonationsByProvince}
      />
    </>
  )
}

export default ChartsByProvince
