import { useQuery } from 'react-query'

import { GetPromoCodesParams, PromoCodeEndpoint, getPromoCodes } from 'utils/api/promoCode.api'

const useGetPromoCodes = (params: GetPromoCodesParams) => useQuery({
  queryKey: [PromoCodeEndpoint.PromoCode, params],
  queryFn: () => getPromoCodes(params),
  retry: false,
  staleTime: 60000, // 1 minute
  refetchOnWindowFocus: false,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetPromoCodes