import _isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import PercentIcon from '@mui/icons-material/Percent'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { set } from 'date-fns'
import {
  Paper,
  useMediaQuery,
  Theme,
  Box,
  Alert,
  Button,
  Divider,
} from '@mui/material'

import {
  InputField,
  ToggleButtons,
  Text,
  InputSelect,
  DateTimePicker,
} from 'components'
import { CharityPartner } from 'utils/api/charityPartner.api'
import { CONSTANTS, checkAllPropertiesTrue, regex, useFormData } from 'utils'

export interface CharityPartnerPromoCodeData {
  createPromoCode: boolean
  code: string
  description: string
  isPercent: boolean
  amount: string
}

interface CharityFormProps {
  initialCharityData?: CharityPartner
  onSubmit: ({
    charityData,
    promoCodeData,
  }: {
    charityData: CharityPartner
    promoCodeData: CharityPartnerPromoCodeData
  }) => void
}

export const defaultInitialCharityData: CharityPartner = {
  name: '',
  displayName: '',
  isActive: true,
  logo: '',
  location: '',
  number: undefined,
  notifyDescription: undefined,
  shortDescription: '',
  longDescription: '',
  category: '',
  isPublicFoundation: false,
  contractStart: set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }),
  contact: {
    name: '',
    phone: '',
    email: '',
    mailingAddress: '',
  },
}

export const defaultPromoCodeData: CharityPartnerPromoCodeData = {
  createPromoCode: false,
  code: '',
  description: '',
  isPercent: true,
  amount: '',
}

const defaultValidationData = {
  name: false,
  displayName: false,
  logo: false,
  location: false,
  shortDescription: false,
  longDescription: false,
  contactEmail: false,
  contactName: false,
  contactPhone: false,
  contactAddress: false,
  promoCodeCode: false,
  promoCodeDescription: false,
  promoCodeAmount: false,
  category: false,
}

const CharityForm = (props: CharityFormProps) => {
  const { initialCharityData = defaultInitialCharityData, onSubmit } = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [displayValidation, setDisplayValidation] = useState(false)

  const [charityData, updateCharityData] =
    useFormData<CharityPartner>(initialCharityData)

  const [promoCodeData, updatePromoCodeData] =
    useFormData<CharityPartnerPromoCodeData>(defaultPromoCodeData)

  const [validation, updateValidation] = useFormData(defaultValidationData)

  const isExistingCharityPartner = !!initialCharityData._id

  useEffect(() => {
    updateValidation({
      name: !!charityData.name,
      displayName: !!charityData.displayName,
      logo: regex.URL.test(charityData.logo || ''),
      location: regex.CITY_PROVINCECODE.test(charityData.location || ''),
      shortDescription: !!charityData.shortDescription,
      longDescription: !!charityData.longDescription,
      contactEmail: regex.EMAIL.test(charityData.contact?.email || ''),
      contactName: !!charityData.contact?.name,
      contactPhone: !!charityData.contact?.phone,
      contactAddress: !!charityData.contact?.mailingAddress,
      category: !!charityData.category,
      promoCodeCode: !promoCodeData.createPromoCode || !!promoCodeData.code,
      promoCodeDescription:
        !promoCodeData.createPromoCode || !!promoCodeData.description,
      promoCodeAmount:
        !promoCodeData.createPromoCode ||
        (parseFloat(promoCodeData.amount) > 0 &&
          (!promoCodeData.isPercent ||
            parseFloat(promoCodeData.amount) <= 100)),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charityData, promoCodeData])

  const resetState = () => {
    updateValidation(defaultValidationData)
    updateCharityData(initialCharityData)
    updatePromoCodeData(defaultPromoCodeData)
    setDisplayValidation(false)
  }

  return (
    <Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            rowGap: 2,
            p: 2,
            flexDirection: 'column',
            width: isMobile ? undefined : '50%',
          }}
        >
          <DateTimePicker
            label="Contract Start"
            value={charityData.contractStart}
            onChange={(contractStart) => {
              updateCharityData({ contractStart })
            }}
          />
          <Divider />

          <Alert severity="info">
            This is the 'official' name of the charity
          </Alert>
          <InputField
            required
            fullWidth
            label="Name"
            value={charityData.name}
            onChange={(name) => {
              updateCharityData({ name })
            }}
            isValid={validation.name}
            displayValidation={displayValidation}
            transformation="uppercase"
          />

          <Divider />

          <Alert severity="info">
            This is the name that will be displayed to users in the app
          </Alert>
          <InputField
            required
            fullWidth
            label="Display Name"
            value={charityData.displayName}
            onChange={(displayName) => {
              updateCharityData({ displayName })
            }}
            isValid={validation.displayName}
            displayValidation={displayValidation}
          />

          <Divider />

          <Alert severity="info">
            Charity number cannot contain any spaces
          </Alert>
          <InputField
            fullWidth
            label="Charity Number"
            value={charityData.number}
            onChange={(number) => {
              updateCharityData({ number })
            }}
          />

          <Divider />

          <Alert severity="info">
            The city must match the pattern 'CITY, PROVINCE CODE', eg 'QUEBEC
            CITY, QC'
          </Alert>
          <InputField
            fullWidth
            required
            label="Location"
            value={charityData.location}
            onChange={(location) => {
              updateCharityData({ location })
            }}
            isValid={validation.location}
            displayValidation={displayValidation}
            transformation="uppercase"
          />

          <Divider />

          <Alert severity="info">
            This is displayed above the labels on the question where users are
            prompted to make a donation to the partner
          </Alert>
          <InputField
            fullWidth
            required
            label="Logo URL"
            value={charityData.logo}
            onChange={(logo) => {
              updateCharityData({ logo })
            }}
            isValid={validation.logo}
            displayValidation={displayValidation}
          />

          <Divider />

          <Alert severity="info">
            This is displayed as 'Label 2' on the question where users are
            prompted to make a donation to the partner
          </Alert>
          <InputField
            required
            fullWidth
            rows={2}
            label="Short Description"
            value={charityData.shortDescription}
            onChange={(shortDescription) => {
              updateCharityData({ shortDescription })
            }}
            isValid={validation.shortDescription}
            displayValidation={displayValidation}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            rowGap: 2,
            p: 2,
            flexDirection: 'column',
            width: isMobile ? undefined : '50%',
          }}
        >
          <Alert severity="info">
            This is displayed as the 'sublabel' on the question where users are
            prompted to make a donation to the partner
          </Alert>
          <InputField
            fullWidth
            required
            rows={3}
            label="Long Description"
            value={charityData.longDescription}
            onChange={(longDescription) => {
              updateCharityData({ longDescription })
            }}
            displayValidation={displayValidation}
            isValid={validation.longDescription}
          />
          <Divider />
          <Alert severity="info">
            This is displayed as an alternate label on the 'Notify Charity'
            question
          </Alert>
          <InputField
            fullWidth
            rows={2}
            label="Notify Description"
            value={charityData.notifyDescription}
            onChange={(notifyDescription) => {
              updateCharityData({
                notifyDescription,
              })
            }}
          />
          <Divider />
          <InputSelect
            required
            label="Category"
            value={charityData.category}
            isValid={validation.category}
            displayValidation={displayValidation}
            options={Object.values(CONSTANTS.charityCategories).map(
              (category) => ({ value: category, label: category }),
            )}
            onChange={(category) => {
              updateCharityData({
                category,
              })
            }}
          />
          <Divider />
          <Alert severity="info" sx={{ mb: 1 }}>
            This is the point of contact for the charity, and is used to notify
            the charity when a user opts-in to notifying the charity of their
            gift
          </Alert>
          <InputField
            fullWidth
            required
            label="Contact Name"
            value={charityData.contact?.name}
            onChange={(name) => {
              updateCharityData({
                contact: {
                  ...charityData.contact,
                  name,
                },
              })
            }}
            isValid={validation.name}
            displayValidation={displayValidation}
          />
          <InputField
            fullWidth
            required
            label="Contact Phone"
            value={charityData.contact?.phone}
            onChange={(phone) => {
              updateCharityData({
                contact: {
                  ...charityData.contact,
                  phone,
                },
              })
            }}
            displayValidation={displayValidation}
            isValid={validation.contactPhone}
          />
          <InputField
            fullWidth
            required
            label="Contact Email"
            value={charityData.contact?.email}
            onChange={(email) => {
              updateCharityData({
                contact: {
                  ...charityData.contact,
                  email,
                },
              })
            }}
            displayValidation={displayValidation}
            isValid={validation.contactEmail}
          />
          <InputField
            fullWidth
            required
            label="Contact Mailing Address"
            value={charityData.contact?.mailingAddress}
            onChange={(mailingAddress) => {
              updateCharityData({
                contact: {
                  ...charityData.contact,
                  mailingAddress,
                },
              })
            }}
            displayValidation={displayValidation}
            isValid={validation.contactAddress}
          />
          <Divider />
          <ToggleButtons
            fullWidth
            value={charityData.isPublicFoundation}
            onChange={(isPublicFoundation) => {
              updateCharityData({ isPublicFoundation })
            }}
            buttons={[
              { label: 'Non-Public Foundation', value: false },
              { label: 'Public Foundation', value: true },
            ]}
          />
          <Divider />
          {isExistingCharityPartner && (
            <InputField
              fullWidth
              disabled
              value={initialCharityData.slug}
              label="Slug"
            />
          )}
          {!isExistingCharityPartner && (
            <ToggleButtons
              fullWidth
              value={promoCodeData.createPromoCode}
              onChange={(createPromoCode) => {
                updatePromoCodeData({ createPromoCode })
              }}
              buttons={[
                { label: "Don't Create Promo Code", value: false },
                { label: 'Create Promo Code', value: true },
              ]}
            />
          )}
          {promoCodeData.createPromoCode && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 2,
                mt: 2,
              }}
            >
              <InputField
                fullWidth
                required
                label="Promo Code"
                value={promoCodeData.code}
                transformation="uppercase"
                inputValidation={regex.PROMO_CODE}
                onChange={(code) => {
                  updatePromoCodeData({ code })
                }}
                displayValidation={displayValidation}
                isValid={validation.promoCodeCode}
              />

              <Box
                sx={{
                  display: 'flex',
                  columnGap: 2,
                }}
              >
                <ToggleButtons
                  value={promoCodeData.isPercent}
                  onChange={(isPercent) => updatePromoCodeData({ isPercent })}
                  buttons={[
                    {
                      value: false,
                      label: <AttachMoneyIcon />,
                    },
                    {
                      value: true,
                      label: <PercentIcon />,
                    },
                  ]}
                />

                <InputField
                  required
                  label="Discount amount"
                  type="number"
                  isValid={validation.promoCodeAmount}
                  displayValidation={displayValidation}
                  value={promoCodeData.amount}
                  fullWidth
                  onChange={(amount) =>
                    updatePromoCodeData({
                      amount,
                    })
                  }
                  startAdornment={!promoCodeData.isPercent ? '$' : undefined}
                  endAdornment={promoCodeData.isPercent ? '%' : undefined}
                  shrinkLabel
                />

                <InputField
                  fullWidth
                  required
                  label="Description"
                  value={promoCodeData.description}
                  onChange={(description) => {
                    updatePromoCodeData({
                      description,
                    })
                  }}
                  isValid={validation.promoCodeDescription}
                  displayValidation={displayValidation}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          columnGap: 2,
          px: 2,
          pb: 2,
        }}
      >
        <Button
          variant="contained"
          disabled={_isEqual(charityData, initialCharityData)}
          onClick={() => {
            setDisplayValidation(true)
            if (checkAllPropertiesTrue(validation)) {
              onSubmit({ charityData, promoCodeData })
            }
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          onClick={resetState}
          disabled={_isEqual(charityData, initialCharityData)}
        >
          Reset
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {initialCharityData._id && !isMobile && (
          <Text variant="subtitle2" sx={{ alignSelf: 'center' }}>
            ID: {initialCharityData._id}
          </Text>
        )}
      </Box>
    </Paper>
  )
}

export default CharityForm
