import { useContext } from 'react'

import { UserContext } from 'utils'
import LatestGeneratedDocuments from './document/LatestGeneratedDocuments'
import DocumentGenerations from './document/DocumentGenerations'

const DocumentDetails = () => {
  const { data } = useContext(UserContext) || {}

  return data ? (
    <>
      <LatestGeneratedDocuments data={data} />
      <DocumentGenerations data={data} />
    </>
  ) : (
    <></>
  )
}

export default DocumentDetails
