import { TextField } from '@mui/material'
import { DateTimePicker as MuiXDateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

interface DateTimePickerProps {
  value: Date | undefined
  onChange: (value: Date | undefined) => void
  label: string
  disabled?: boolean
  fullWidth?: boolean
  isValid?: boolean
  displayValidation?: boolean
  required?: boolean
}

const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    value,
    onChange,
    label,
    disabled = false,
    fullWidth = false,
    isValid = true,
    displayValidation = false,
    required = false,
  } = props

  return (
    <MuiXDateTimePicker
      views={['day', 'hours', 'minutes', 'seconds']}
      disabled={disabled}
      disableOpenPicker
      renderInput={(props) => (
        <TextField
          {...props}
          error={displayValidation && !isValid}
          fullWidth={fullWidth}
          required={required}
        />
      )}
      label={label}
      value={value ? value : null}
      onChange={(newValue) => {
        onChange(newValue ? newValue : undefined)
      }}
    />
  )
}

export default DateTimePicker
