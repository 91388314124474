import moment from 'moment'
import { useState } from 'react'
import { Box } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { TableDataCell } from 'components'
import { dates, timestampFromMongoId } from 'utils'
import PromoCodeDetails from './PromoCodeDetails'
import { PromoCode } from 'utils/api/promoCode.api'
import { columnMetaData } from '../ListPromoCodesView'

const PromoCodeTableDataRow = ({ code: codeData }: { code: PromoCode }) => {
  const {
    _id,
    code,
    amount,
    usage,
    referrer,
    isPercent,
    oneTimeUse,
    expiryDate,
    description,
  } = codeData

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} key={_id}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          columnGap: 2,
          height: '3rem',
          borderTop: 'solid 1px #CCCCCC',
          mr: 2,
          ':hover': {
            cursor: 'pointer',
          },
        }}
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <KeyboardArrowDownIcon
          sx={isExpanded ? { transform: 'rotate(180deg)' } : {}}
        />
        <TableDataCell
          width={columnMetaData[0].width}
          hideBelowBreakpoint={columnMetaData[0].hideBelowBreakpoint}
        >
          {code}
        </TableDataCell>
        <TableDataCell
          width={columnMetaData[1].width}
          hideBelowBreakpoint={columnMetaData[1].hideBelowBreakpoint}
        >
          {isPercent ? `${amount}%` : `$${amount}`}
        </TableDataCell>
        <TableDataCell
          width={columnMetaData[2].width}
          hideBelowBreakpoint={columnMetaData[2].hideBelowBreakpoint}
        >
          {description}
        </TableDataCell>

        <TableDataCell
          width={columnMetaData[3].width}
          hideBelowBreakpoint={columnMetaData[3].hideBelowBreakpoint}
        >
          {usage ? usage.length.toString() : '0'}
        </TableDataCell>

        <TableDataCell
          width={columnMetaData[4].width}
          hideBelowBreakpoint={columnMetaData[4].hideBelowBreakpoint}
        >
          {oneTimeUse ? 'One Time' : 'Unlimited'}
        </TableDataCell>
        <TableDataCell
          width={columnMetaData[5].width}
          hideBelowBreakpoint={columnMetaData[5].hideBelowBreakpoint}
        >{`${moment(expiryDate)
          .utcOffset('-0800')
          .format(' MM/DD/YYYY, h:mm:ss a')} PST`}</TableDataCell>
        <TableDataCell
          width={columnMetaData[6].width}
          hideBelowBreakpoint={columnMetaData[6].hideBelowBreakpoint}
        >
          {referrer ? `${referrer?.ref}/${referrer?.reftag}` : undefined}
        </TableDataCell>
        <TableDataCell
          width={columnMetaData[7].width}
          hideBelowBreakpoint={columnMetaData[7].hideBelowBreakpoint}
        >
          {_id ? dates.format(timestampFromMongoId(_id)) : '-'}
        </TableDataCell>
      </Box>
      {isExpanded && <PromoCodeDetails codeData={codeData} />}
    </Box>
  )
}

export default PromoCodeTableDataRow
