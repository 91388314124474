import {
  Box,
  Theme,
  Alert,
  Dialog,
  Button,
  Divider,
  DialogTitle,
  useMediaQuery,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import { useState, Children } from 'react'

import { standardiseErrorMessage, useCreateLawyer } from 'utils'
import { Text } from 'components'
import { Lawyer } from 'utils/api/lawyer.types'

const Row = ({ title, value }: { title: string; value: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 2,
      }}
    >
      <Box
        sx={{
          width: '30%',
        }}
      >
        <Text>{title}:</Text>
      </Box>
      <Box
        sx={{
          width: '70%',
        }}
      >
        <Text>{value}</Text>
      </Box>
    </Box>
  )
}

const ConfirmationModal = ({
  show,
  setShow,
  lawyerRequestData,
}: {
  show: boolean
  setShow: (show: boolean) => void
  lawyerRequestData: Lawyer
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [isWorking, setIsWorking] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const { mutateAsync } = useCreateLawyer()

  const {
    lawyerFirstName,
    lawyerLastName,

    contactFirstName,
    contactLastName,
    firm,

    bio,
    city,
    email,
    imageUrl,
    worksRemote,
    province,

    highlights,
    flatFeeData,
  } = lawyerRequestData

  const handleCreate = () => {
    setIsWorking(true)

    mutateAsync(lawyerRequestData)
      .then((res) => {
        setError(undefined)
        setIsSuccessful(true)
      })
      .catch((res) => {
        setError(standardiseErrorMessage(res.response.data.errorMessage))
      })
      .finally(() => {
        setTimeout(() => {
          setIsWorking(false)
        }, 300)
      })
  }

  const resetState = () => {
    setIsWorking(false)
    setError(undefined)
    setIsSuccessful(false)
  }

  return (
    <Dialog
      open={show}
      PaperProps={{
        sx: {
          minWidth: isMobile ? '94vw' : '35rem',
          minHeight: isMobile ? '94vh' : '30rem',
          maxHeight: isMobile ? '100vh' : undefined,
          p: 2,
          m: isMobile ? 0 : '4rem',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {isWorking ? (
          <Text variant="h6">Please wait...</Text>
        ) : isSuccessful ? (
          <Text variant="h6">Success!</Text>
        ) : (
          <Text variant="h6">
            You are about to create a new lawyer referral
          </Text>
        )}
      </DialogTitle>
      {isWorking ? (
        <DialogContent
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 1,
              color: isSuccessful ? 'text.disabled' : undefined,
            }}
          >
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Divider />

            {lawyerFirstName && (
              <>
                <Row title="First Name" value={lawyerFirstName} />
                <Divider />
              </>
            )}
            {lawyerLastName && (
              <>
                <Row title="Last Name" value={lawyerLastName} />
                <Divider />
              </>
            )}

            {contactFirstName && (
              <>
                <Row title="Contact First Name" value={contactFirstName} />
                <Divider />
              </>
            )}
            {contactLastName && (
              <>
                <Row title="Contact Last Name" value={contactLastName} />
                <Divider />
              </>
            )}
            {firm && (
              <>
                <Row title="Firm" value={firm} />
                <Divider />
              </>
            )}

            {bio && (
              <>
                <Row title="Bio" value={bio} />
                <Divider />
              </>
            )}

            <Row title="City" value={city} />
            <Divider />

            <Row title="email" value={email} />
            <Divider />

            <Row title="Image URL" value={imageUrl} />
            <Divider />

            <Row title="Works Remotely" value={worksRemote ? 'Yes' : 'No'} />
            <Divider />

            <Row title="Province" value={province} />
            <Divider />

            {highlights && (
              <>
                {Children.toArray(
                  highlights.map((highlight, index) => (
                    <Row title={`Highlight ${index + 1}`} value={highlight} />
                  )),
                )}

                <Divider />
              </>
            )}

            {flatFeeData?.default && (
              <>
                <Row
                  title="Default Price"
                  value={`$${flatFeeData.default.price.toFixed(2).toString()}`}
                />
                {Children.toArray(
                  flatFeeData.default.highlights.map((highlight, index) => (
                    <Row title={`Highlight ${index + 1}`} value={highlight} />
                  )),
                )}
                <Divider />
              </>
            )}
            {flatFeeData?.spousalTrust && (
              <>
                <Row
                  title="Spousal Trust Price"
                  value={`$${flatFeeData.spousalTrust.price
                    .toFixed(2)
                    .toString()}`}
                />
                {Children.toArray(
                  flatFeeData.spousalTrust.highlights.map(
                    (highlight, index) => (
                      <Row title={`Highlight ${index + 1}`} value={highlight} />
                    ),
                  ),
                )}
                <Divider />
              </>
            )}
            {flatFeeData?.familyDisability && (
              <>
                <Row
                  title="Family Disability Price"
                  value={`$${flatFeeData.familyDisability.price
                    .toFixed(2)
                    .toString()}`}
                />
                {Children.toArray(
                  flatFeeData.familyDisability.highlights.map(
                    (highlight, index) => (
                      <Row title={`Highlight ${index + 1}`} value={highlight} />
                    ),
                  ),
                )}
                <Divider />
              </>
            )}
            {flatFeeData?.notRightForMe && (
              <>
                <Row
                  title="Not Right For Me Price"
                  value={`$${flatFeeData.notRightForMe.price
                    .toFixed(2)
                    .toString()}`}
                />
                {Children.toArray(
                  flatFeeData.notRightForMe.highlights.map(
                    (highlight, index) => (
                      <Row title={`Highlight ${index + 1}`} value={highlight} />
                    ),
                  ),
                )}
                <Divider />
              </>
            )}
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => {
            setShow(false)
            resetState()
          }}
          variant="outlined"
          disabled={isWorking}
        >
          Go Back
        </Button>
        <Button
          onClick={handleCreate}
          variant="contained"
          disabled={isWorking || isSuccessful}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
