import { useState } from 'react'

const useFormData = <T>(initialData: T): [T, (updatedData: object) => void] => {
  const [formData, setFormData] = useState<T>(initialData)

  const updateFormData = (newData: object) => {
    setFormData({ ...formData, ...newData })
  }

  return [formData, updateFormData]
}

export default useFormData