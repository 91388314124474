import _isEmpty from 'lodash/isEmpty'

const removeUndefinedValues = <T>(obj: T): T => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([value, index]) => index != null && !_isEmpty(value))
      .map(([k, v]) => [k, v === Object(v) ? removeUndefinedValues(v) : v])
  ) as T
}

export default removeUndefinedValues