import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Text } from 'components'
import errorImage from 'assets/images/404_error.png'

const PageNotFoundView = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        rowGap: 4,
        p: 4,
      }}
    >
      <Box
        component="img"
        src={errorImage}
        alt="404 tombstone"
        sx={{
          maxHeight: '25vh',
        }}
      />
      <Text variant="body" align="center">
        Oh... hello there. Didn't expect to see you! Well, this is awkward isn't
        it.
      </Text>
      <Text align="center">
        I don't have anything to show you here at this time, maybe go home and
        try again?
      </Text>
      <Button
        onClick={() => {
          navigate('/')
        }}
      >
        Go Home
      </Button>
    </Box>
  )
}

export default PageNotFoundView
