import { useMemo } from 'react'
import { Box } from '@mui/material'
import _capitalize from 'lodash/capitalize'

import { BarChart } from 'components'
import RawDataTable from '../RawDataTable'
import { format, round, useBusinessReportContext } from 'utils'

const Demographics = () => {
  const {
    data: { processedReportData: { demographics } = {} },
  } = useBusinessReportContext()

  const paidAccountsTotals = useMemo(
    () =>
      demographics
        ? {
            will: {
              individual:
                demographics.paidAccounts.will.nonRbc.noPartner +
                demographics.paidAccounts.will.rbc.noPartner,
              couples:
                demographics.paidAccounts.will.nonRbc.partner +
                demographics.paidAccounts.will.rbc.partner,
              total:
                demographics.paidAccounts.will.nonRbc.partner +
                demographics.paidAccounts.will.nonRbc.noPartner +
                demographics.paidAccounts.will.rbc.partner +
                demographics.paidAccounts.will.rbc.noPartner,
            },
            willPoa: {
              individual:
                demographics.paidAccounts.willPoa.nonRbc.noPartner +
                demographics.paidAccounts.willPoa.rbc.noPartner,
              couples:
                demographics.paidAccounts.willPoa.nonRbc.partner +
                demographics.paidAccounts.willPoa.rbc.partner,
              total:
                demographics.paidAccounts.willPoa.nonRbc.noPartner +
                demographics.paidAccounts.willPoa.nonRbc.partner +
                demographics.paidAccounts.willPoa.rbc.noPartner +
                demographics.paidAccounts.willPoa.rbc.partner,
            },
            all:
              demographics.paidAccounts.will.nonRbc.noPartner +
              demographics.paidAccounts.willPoa.nonRbc.noPartner +
              demographics.paidAccounts.will.nonRbc.noPartner +
              demographics.paidAccounts.willPoa.nonRbc.noPartner +
              demographics.paidAccounts.will.rbc.noPartner +
              demographics.paidAccounts.willPoa.rbc.noPartner +
              demographics.paidAccounts.will.rbc.noPartner +
              demographics.paidAccounts.willPoa.rbc.noPartner,
            allNonRbc:
              demographics.paidAccounts.will.nonRbc.noPartner +
              demographics.paidAccounts.willPoa.nonRbc.noPartner +
              demographics.paidAccounts.will.nonRbc.partner +
              demographics.paidAccounts.willPoa.nonRbc.partner,
            allRbc:
              demographics.paidAccounts.will.rbc.noPartner +
              demographics.paidAccounts.willPoa.rbc.noPartner +
              demographics.paidAccounts.will.rbc.partner +
              demographics.paidAccounts.willPoa.rbc.partner,
          }
        : undefined,
    [demographics],
  )

  if (!demographics || !paidAccountsTotals) return <></>

  const { paidAccounts, cartSize, printing, printingCartSize, questionnaires } =
    demographics

  return (
    <>
      <BarChart
        isInteractive
        height="20rem"
        leftLabel="Funnel"
        data={Object.entries(questionnaires.nonPartner).map(
          ([index, value]) => ({
            index: _capitalize(index),
            'Non-partner': value,
          }),
        )}
        legend={['Non-partner']}
      />

      <RawDataTable
        title="Breakdown of Paid Accounts"
        columnHeaders={[
          'Non-RBC & Partner',
          'Non-RBC & No Partner',
          'Total',
          'RBC & Partner',
          'RBC & No Partner',
          'Total',
          'Grand Total',
        ]}
        rowHeaders={['Will Only', 'Will & POA', 'Totals']}
        data={[
          // Will Only
          [
            // Non RBC + Partner
            format.number(paidAccounts.will.nonRbc.partner),
            // Non RBC + No Partner
            format.number(paidAccounts.will.nonRbc.noPartner),
            // Total Non RBC
            format.number(
              paidAccounts.will.nonRbc.partner +
                paidAccounts.will.nonRbc.noPartner,
            ),
            // RBC + Partner
            format.number(paidAccounts.will.rbc.partner),
            // RBC + No Partner
            format.number(paidAccounts.will.rbc.noPartner),
            // Total RBC
            format.number(
              paidAccounts.will.rbc.partner + paidAccounts.will.rbc.noPartner,
            ),
            // Total Will Only
            format.number(paidAccountsTotals.will.total),
          ],
          // Will & POA
          [
            // Non RBC + Partner
            format.number(paidAccounts.willPoa.nonRbc.partner),
            // Non RBC + No Partner
            format.number(paidAccounts.willPoa.nonRbc.noPartner),
            // Total Non RBC
            format.number(
              paidAccounts.willPoa.nonRbc.noPartner +
                paidAccounts.willPoa.nonRbc.noPartner,
            ),
            // RBC + Partner
            format.number(paidAccounts.willPoa.rbc.partner),
            // RBC + No Partner
            format.number(paidAccounts.willPoa.rbc.noPartner),
            // Total RBC
            format.number(
              paidAccounts.willPoa.rbc.noPartner +
                paidAccounts.willPoa.rbc.noPartner,
            ),
            // Total Will & POA
            format.number(paidAccountsTotals.willPoa.total),
          ],
          // Totals
          [
            // Non RBC + Partner
            format.number(
              paidAccounts.will.nonRbc.noPartner +
                paidAccounts.willPoa.nonRbc.noPartner,
            ),
            // Non RBC + No Partner
            format.number(
              paidAccounts.will.nonRbc.noPartner +
                paidAccounts.willPoa.nonRbc.noPartner,
            ),
            // Total Non RBC
            format.number(paidAccountsTotals.allNonRbc),
            // RBC + Partner
            format.number(
              paidAccounts.will.rbc.noPartner +
                paidAccounts.willPoa.rbc.noPartner,
            ),
            // RBC + No Partner
            format.number(
              paidAccounts.will.rbc.noPartner +
                paidAccounts.willPoa.rbc.noPartner,
            ),
            // Total RBC
            format.number(paidAccountsTotals.allRbc),
            // Grand Total
            format.number(paidAccountsTotals.all),
          ],
        ]}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          columnGap: '1rem',
          rowGap: '1rem',
          '& > div': {
            flexGrow: '1',
          },
        }}
      >
        <RawDataTable
          title="Average Cart Size (Documents)"
          rowHeaders={['Total Document Revenue', 'Per Paid Account']}
          columnCount={1}
          data={[
            [format.currency(cartSize.totalDocumentsRevenue)],
            [format.currency(cartSize.documentsRevenuePerPaidAccount)],
          ]}
        />

        <RawDataTable
          title="Average Cart Size (Printing)"
          rowHeaders={['Total Printing Revenue', 'Per Paid Account']}
          columnCount={1}
          data={[
            [format.currency(printingCartSize.totalPrintingRevenue)],
            [format.currency(printingCartSize.printingRevenuePerPaidAccount)],
          ]}
        />

        <RawDataTable
          title="Number of Wills Per Paid Account"
          rowHeaders={['Total Number of Wills', 'Per Paid Account']}
          columnCount={1}
          data={[
            [paidAccounts.totalWills],
            [round.fixed(paidAccounts.totalWills / paidAccounts.total, 3)],
          ]}
        />
      </Box>

      <RawDataTable
        title="Breakdown of Printing on Initial Purchase"
        rowHeaders={['Printing', 'No Printing', 'Total']}
        columnHeaders={['Partner', 'No Partner', 'Total', '% of Total']}
        data={[
          [
            format.number(printing.yesPrinting.partner),
            format.number(printing.yesPrinting.noPartner),
            format.number(printing.yesPrinting.total),
            format.percentage(
              (printing.yesPrinting.total /
                (printing.noPrinting.total + printing.yesPrinting.total)) *
                100,
            ),
          ],
          [
            format.number(printing.noPrinting.partner),
            format.number(printing.noPrinting.noPartner),
            format.number(printing.noPrinting.total),
            format.percentage(
              (printing.noPrinting.total /
                (printing.noPrinting.total + printing.yesPrinting.total)) *
                100,
            ),
          ],
          [
            format.number(
              printing.yesPrinting.partner + printing.noPrinting.partner,
            ),
            format.number(
              printing.yesPrinting.noPartner + printing.noPrinting.noPartner,
            ),
            format.number(
              printing.noPrinting.total + printing.yesPrinting.total,
            ),
            '',
          ],
        ]}
      />

      <RawDataTable
        title="Breakdown of Products Owned"
        rowHeaders={[
          'Individual Will',
          'Individual Will & POA',
          'Couples Will',
          'Couples Will & POA',
          // 'Total',
        ]}
        columnHeaders={['All', 'Non-RBC', 'RBC']}
        data={[
          // Individual Will
          [
            // All
            format.percentage(
              ((paidAccounts.will.nonRbc.noPartner +
                paidAccounts.will.rbc.noPartner) /
                paidAccounts.total) *
                100,
            ),
            // Non RBC
            format.percentage(
              (paidAccounts.will.nonRbc.noPartner /
                paidAccountsTotals.allNonRbc) *
                100,
            ),
            // RBC
            format.percentage(
              (paidAccounts.will.rbc.noPartner / paidAccountsTotals.allRbc) *
                100,
            ),
          ],
          // Individual Will & POA
          [
            // All
            format.percentage(
              ((paidAccounts.willPoa.nonRbc.noPartner +
                paidAccounts.willPoa.rbc.noPartner) /
                paidAccounts.total) *
                100,
            ),
            // Non RBC
            format.percentage(
              (paidAccounts.willPoa.nonRbc.noPartner /
                paidAccountsTotals.allNonRbc) *
                100,
            ),
            // RBC
            format.percentage(
              (paidAccounts.willPoa.rbc.noPartner / paidAccountsTotals.allRbc) *
                100,
            ),
          ],
          // Couples Will
          [
            // All
            format.percentage(
              ((paidAccounts.will.nonRbc.partner +
                paidAccounts.will.rbc.partner) /
                paidAccounts.total) *
                100,
            ),
            // Non RBC
            format.percentage(
              (paidAccounts.will.nonRbc.partner /
                paidAccountsTotals.allNonRbc) *
                100,
            ),
            // RBC
            format.percentage(
              (paidAccounts.will.rbc.partner / paidAccountsTotals.allRbc) * 100,
            ),
          ],
          // Couples Will & POA
          [
            // All
            format.percentage(
              ((paidAccounts.willPoa.nonRbc.partner +
                paidAccounts.willPoa.rbc.partner) /
                paidAccounts.total) *
                100,
            ),
            // Non RBC
            format.percentage(
              (paidAccounts.willPoa.nonRbc.partner /
                paidAccountsTotals.allNonRbc) *
                100,
            ),
            // RBC
            format.percentage(
              (paidAccounts.willPoa.rbc.partner / paidAccountsTotals.allRbc) *
                100,
            ),
          ],
          // Total
          // [
          //   // All
          //   format.percentage(
          //     ((paidAccounts.will.nonRbc.noPartner +
          //       paidAccounts.will.rbc.noPartner) /
          //       paidAccounts.total) *
          //       100 +
          //       ((paidAccounts.willPoa.nonRbc.noPartner +
          //         paidAccounts.willPoa.rbc.noPartner) /
          //         paidAccounts.total) *
          //         100 +
          //       ((paidAccounts.will.nonRbc.partner +
          //         paidAccounts.will.rbc.partner) /
          //         paidAccounts.total) *
          //         100 +
          //       ((paidAccounts.willPoa.nonRbc.partner +
          //         paidAccounts.willPoa.rbc.partner) /
          //         paidAccounts.total) *
          //         100,
          //   ),

          //   // Non RBC
          //   format.percentage(
          //     (paidAccounts.will.nonRbc.noPartner /
          //       paidAccountsTotals.allNonRbc) *
          //       100 +
          //       (paidAccounts.willPoa.nonRbc.noPartner /
          //         paidAccountsTotals.allNonRbc) *
          //         100 +
          //       (paidAccounts.will.nonRbc.partner /
          //         paidAccountsTotals.allNonRbc) *
          //         100 +
          //       (paidAccounts.willPoa.nonRbc.partner /
          //         paidAccountsTotals.allNonRbc) *
          //         100,
          //   ),

          //   // RBC
          //   format.percentage(
          //     (paidAccounts.will.rbc.noPartner / paidAccountsTotals.allRbc) *
          //       100 +
          //       (paidAccounts.willPoa.rbc.noPartner /
          //         paidAccountsTotals.allRbc) *
          //         100 +
          //       (paidAccounts.will.rbc.partner / paidAccountsTotals.allRbc) *
          //         100 +
          //       (paidAccounts.willPoa.rbc.partner / paidAccountsTotals.allRbc) *
          //         100,
          //   ),
          // ],
        ]}
      />
    </>
  )
}

export default Demographics
