import { Children } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { Divider, Box } from '@mui/material'

import { Text, TitledWrapper } from 'components'
import SocialDocumentListItem from './SocialDocumentListItem'
import StandardDocumentListItem from './StandardDocumentListItem'
import { UserDetails } from 'utils/api/user.types'

const LatestGeneratedDocuments = ({ data }: { data: UserDetails }) => {
  return (
    <TitledWrapper title="Latest Generated Documents">
      <Box
        sx={{
          py: 2,
        }}
      >
        {Children.toArray(
          data.documents.map((document, index) => (
            <>
              <StandardDocumentListItem document={document} />
              {(index + 1 !== data.documents.length ||
                data.socialDocuments) && <Divider />}
            </>
          )),
        )}
        {Children.toArray(
          data.socialDocuments.map((document, index) => (
            <>
              <SocialDocumentListItem document={document} />
              {index + 1 !== data.socialDocuments.length && <Divider />}
            </>
          )),
        )}

        {_isEmpty(data.socialDocuments) && _isEmpty(data.documents) && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              py: 2,
              color: 'text.disabled',
            }}
          >
            <Text variant="subtitle2" align="center">
              No documents generated
            </Text>
          </Box>
        )}
      </Box>
    </TitledWrapper>
  )
}

export default LatestGeneratedDocuments
