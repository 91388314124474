import { Checkbox, FormControlLabel, Paper } from '@mui/material'
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import { useEffect, useState } from 'react'

import { useBusinessReportContext } from 'utils'
import { PromoCodesByMonthData } from 'utils/workers/processBusinessDataWorkers.types'

const getColumns = (data?: PromoCodesByMonthData): MUIDataTableColumnDef[] => {
  if (!data) return []

  const fixedColumnStyles = {
    position: 'sticky',
    left: '0px',
    zIndex: 200,
    background:
      'linear-gradient(90deg, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%)',
    fontWeight: 'bold',
  }

  const fixedColumnRightStyles = {
    ...fixedColumnStyles,
    left: undefined,
    right: '0px',
    background:
      'linear-gradient(270deg, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%)',
  }

  const baseStyles = {
    whiteSpace: 'nowrap',
  }

  const monthColumnStyles = {
    ...baseStyles,
    textAlign: 'right',
    width: '10rem',
  }

  const monthColumns: MUIDataTableColumnDef[] = data.months.map((month) => ({
    name: month,
    label: month,
    options: {
      searchable: false,
      setCellProps: () => ({ style: monthColumnStyles }),
      setCellHeaderProps: () => ({
        style: { ...monthColumnStyles, paddingRight: 0 },
      }),
    },
  }))

  return [
    {
      name: 'promoCode',
      label: 'Promo Code',
      options: {
        searchable: true,
        setCellProps: () => ({
          style: {
            ...baseStyles,
            ...fixedColumnStyles,
          },
        }),
        setCellHeaderProps: ({ index }) => ({
          style: {
            ...baseStyles,
            paddingRight: 0,
            ...(index === 0 ? fixedColumnStyles : {}),
          },
        }),
      },
    },
    ...monthColumns,
    {
      name: 'total',
      label: 'Total',
      options: {
        searchable: false,
        setCellProps: () => ({
          style: {
            ...monthColumnStyles,
            ...fixedColumnRightStyles,
          },
        }),
        setCellHeaderProps: ({ index }) => ({
          style: {
            ...monthColumnStyles,
            ...(index === monthColumns.length + 1
              ? fixedColumnRightStyles
              : {}),
          },
        }),
      },
    },
  ]
}

const getRows = (data?: PromoCodesByMonthData) => {
  if (!data) return []
  const { codes, months } = data

  return Object.entries(codes).map(
    ([promoCode, { monthly: monthlyUsage, total }]) => ({
      promoCode,
      ...Object.assign(
        {},
        ...months.map((month) => ({
          [month]: monthlyUsage[month] ?? 0,
        })),
      ),
      total,
    }),
  )
}

const PromoCodesByMonth = () => {
  const {
    data: { processedReportData: { promoCodesByMonth } = {} },
  } = useBusinessReportContext()

  const [excludeGeneratedCodes, setExcludeGeneratedCodes] = useState(false)
  const [dataSubset, setDataSubset] = useState(promoCodesByMonth)

  useEffect(() => {
    if (!promoCodesByMonth) return

    if (excludeGeneratedCodes) {
      const newSubset = {
        ...promoCodesByMonth,
        codes: Object.entries(promoCodesByMonth.codes)
          .filter(([code]) => code.length !== 12)
          .reduce((acc, [code, value]) => ({ ...acc, [code]: value }), {}),
      }

      setDataSubset(newSubset)
    } else {
      setDataSubset(promoCodesByMonth)
    }
  }, [excludeGeneratedCodes, promoCodesByMonth])

  const columns = getColumns(dataSubset)
  const rows = getRows(dataSubset)

  return (
    <Paper>
      <MUIDataTable
        columns={columns}
        data={rows}
        title={
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={excludeGeneratedCodes}
                  onChange={() => {
                    setExcludeGeneratedCodes((prev) => !prev)
                  }}
                />
              }
              label="Exclude 12 character length codes"
            />
          </>
        }
        options={{
          filter: false,
          selectableRows: 'none',
        }}
      />
    </Paper>
  )
}

export default PromoCodesByMonth
