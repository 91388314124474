import _cloneDeep from 'lodash/cloneDeep'
import { GridRowsProp } from '@mui/x-data-grid'

import { format } from 'utils'
import { CharitableGiftsResponse } from 'utils/api/data.types'

const useAllDataGridRows = ({
  data
}: {
  data?: CharitableGiftsResponse[]
}): GridRowsProp => {
  if (data) {
    const clonedData = _cloneDeep(data)
    return clonedData
      .map((gift, index) => ({
        id: index,
        ...gift,
        amount: gift.isPercent
          ? `${gift.amount.toFixed(2)}%`
          : `${format.currency(gift.amount)}`,
      }))
  } else {
    return []
  }
}

export default useAllDataGridRows
