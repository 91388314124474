import { useNavigate } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'
import ReadMoreIcon from '@mui/icons-material/ReadMore'

import { TableDataCell } from 'components'
import { dates, timestampFromMongoId } from 'utils'
import { columnMetaData } from '../ListCharitiesView'
import { CharityPartner } from 'utils/api/charityPartner.api'

const CharitiesTableDataRow = ({ charity }: { charity: CharityPartner }) => {
  const navigate = useNavigate()

  const { _id, name, displayName, location, slug } = charity

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: 'solid 1px #CCCCCC',
      }}
      key={_id}
    >
      <TableDataCell
        width={columnMetaData[0].width}
        hideBelowBreakpoint={columnMetaData[0].hideBelowBreakpoint}
      >
        {name}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[1].width}
        hideBelowBreakpoint={columnMetaData[1].hideBelowBreakpoint}
      >
        {displayName}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[2].width}
        hideBelowBreakpoint={columnMetaData[2].hideBelowBreakpoint}
      >
        {location}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[3].width}
        hideBelowBreakpoint={columnMetaData[3].hideBelowBreakpoint}
      >
        {slug}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[4].width}
        hideBelowBreakpoint={columnMetaData[4].hideBelowBreakpoint}
      >
        {dates.format(timestampFromMongoId(_id || ''))}
      </TableDataCell>
      <IconButton
        aria-label="Details"
        onClick={() => {
          navigate(`/charity/details/${_id}`)
        }}
      >
        <ReadMoreIcon />
      </IconButton>
    </Box>
  )
}

export default CharitiesTableDataRow
