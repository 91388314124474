import { useNavigate } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'
import ReadMoreIcon from '@mui/icons-material/ReadMore'

import { TableDataCell } from 'components'
import { User } from 'utils/api/user.types'
import { columnMetaData } from '../ListUsersView'
import { dates } from 'utils'

const UsersTableDataRow = ({ user }: { user: User }) => {
  const navigate = useNavigate()

  const { _id, email, createdAt, isPartner, documents, payments } = user

  const [latestDocumentGenerated] =
    documents
      ?.sort((a, b) =>
        a.created < b.created ? -1 : a.created > b.created ? 1 : 0,
      )
      .slice(-1) || []

  const [corePayment] =
    payments
      ?.filter((payment) => payment.productId !== 0)
      .sort((a, b) =>
        a.created < b.created ? -1 : a.created > b.created ? 1 : 0,
      )
      .slice(-1) || []

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: 'solid 1px #CCCCCC',
      }}
      key={_id}
    >
      <TableDataCell
        width={columnMetaData[0].width}
        hideBelowBreakpoint={columnMetaData[0].hideBelowBreakpoint}
      >
        {email}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[1].width}
        hideBelowBreakpoint={columnMetaData[1].hideBelowBreakpoint}
      >
        {_id}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[2].width}
        hideBelowBreakpoint={columnMetaData[2].hideBelowBreakpoint}
      >
        {isPartner ? 'Partner' : 'Non-partner'}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[3].width}
        hideBelowBreakpoint={columnMetaData[3].hideBelowBreakpoint}
      >
        {latestDocumentGenerated
          ? dates.format(latestDocumentGenerated.created)
          : undefined}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[4].width}
        hideBelowBreakpoint={columnMetaData[4].hideBelowBreakpoint}
      >
        {corePayment ? dates.format(corePayment.created) : undefined}
      </TableDataCell>
      <TableDataCell
        width={columnMetaData[5].width}
        hideBelowBreakpoint={columnMetaData[5].hideBelowBreakpoint}
      >
        {dates.format(createdAt)}
      </TableDataCell>
      <IconButton
        aria-label="Details"
        onClick={() => {
          navigate(`/users/details/${_id}`)
        }}
      >
        <ReadMoreIcon />
      </IconButton>
    </Box>
  )
}

export default UsersTableDataRow
