import axios from 'axios'
import { Method, ResponseType } from 'axios'

import getEnv, { Environment } from 'utils/helpers/getEnv'

export interface Request {
  url: string
  data?: object
  params?: any
  method?: Method
  responseType?: ResponseType
  timeout?: number
  paramsSerializer?: (params: any) => string
  abortSignal?: AbortSignal
}

const baseURL =
  getEnv() === Environment.Production
    ? 'https://app.epiloguewills.com/server/api'
    : getEnv() === Environment.Staging
    ? 'https://app-staging.epiloguewills.com/server/api'
    : '/api'

const axiosInstance = axios.create({
  baseURL,
  timeout: 60000,
  withCredentials: true,
})

export interface ApiResponse<T> {
  payload: T
}

export const request = <
  PayloadType = any,
  ResponseWrapper = ApiResponse<PayloadType>,
>({
  url,
  data,
  params,
  method,
  timeout,
  paramsSerializer,
  abortSignal: signal,
  responseType = 'json',
}: Request) =>
  axiosInstance.request<
    ResponseWrapper extends never ? PayloadType : ResponseWrapper
  >({
    url,
    data,
    signal,
    method,
    params,
    timeout,
    responseType,
    paramsSerializer,
  })
