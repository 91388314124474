import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

interface SelectProps {
  value: string | number
  label: string
}

interface InputSelectProps {
  required?: boolean
  fullWidth?: boolean
  label: string
  value: string | number
  onChange?: (value: string | number) => void
  isValid?: boolean
  displayValidation?: boolean
  options: SelectProps[]
  disabled?: boolean
}

const InputSelect = (props: InputSelectProps) => {
  const {
    fullWidth = false,
    label,
    value,
    onChange,
    isValid = true,
    displayValidation = true,
    options,
    disabled,
    required,
  } = props

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel
        required={required}
        disabled={disabled}
        id={`${label.replace(' ', '-')}-label`}
      >
        {label}
      </InputLabel>
      <Select
        required={required}
        disabled={disabled}
        labelId={`${label.replace(' ', '-')}-label`}
        id={`${label.replace(' ', '-')}-select`}
        value={value}
        label={label}
        onChange={(event) => {
          if (onChange) onChange(event.target.value)
        }}
        error={!isValid && displayValidation}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default InputSelect
