import { EmbedImage } from './charityEmbedGeneratorView.types'

const embedImages: EmbedImage[] = [
  {
    'name': 'Epilogue Standard',
    'url': 'https://epiloguewills.com/wp-content/uploads/2021/07/finalstacked-docs.png'
  },
  {
    'name': 'Animals - Pets',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/animals-pets.jpeg'
  },
  {
    'name': 'Animals - Wildlife',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/animals-wildlife-1.jpeg'
  },
  {
    'name': 'Arts & Culture - Art',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/arts-culture-art.jpeg'
  },
  {
    'name': 'Arts & Culture - Music',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/arts-culture-music.jpeg'
  },
  {
    'name': 'Care Facility',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/care-facility.jpeg'
  },
  {
    'name': 'Elementary School',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/elementary-school.jpeg'
  },
  {
    'name': 'Environment - With People',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/environment.jpeg'
  },
  {
    'name': 'Environment - Without People',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/envronment-sans-people.jpeg'
  },
  {
    'name': 'Hospital',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/hospital.jpeg'
  },
  {
    'name': 'Indigenous',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/indigenous.jpeg'
  },
  {
    'name': 'Medical Research',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/medical-research.jpeg'
  },
  {
    'name': 'Military - Active',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/military-active.jpeg'
  },
  {
    'name': 'Military - Veteran',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/military-veteran.jpeg'
  },
  {
    'name': 'Poverty - International',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/poverty-international-2.jpeg'
  },
  {
    'name': 'Poverty - Local',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/poverty-local.jpeg'
  },
  {
    'name': 'Religion - Christianity',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/religion-christianity.jpeg'
  },
  {
    'name': 'Religion - Islam',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/religion-islam.jpeg'
  },
  {
    'name': 'Religion - Judaism',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/religion-judaism.jpeg'
  },
  {
    'name': 'Sports & Recreation - Team',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/sports-and-rec.jpeg'
  },
  {
    'name': 'Sports & Recreation - Solo',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/sports-and-rec-solo-e1648746294374.jpeg'
  },
  {
    'name': 'University',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/university.jpeg'
  },
  {
    'name': 'Youth',
    'url': 'https://epiloguewills.com/wp-content/uploads/2022/03/youth.jpeg'
  }
]

export default embedImages