import { GridRowsProp } from '@mui/x-data-grid'

import getTotalledData from './getTotalledData'
import { CharitableGiftsResponse } from 'utils/api/data.types'

const useTotalledDataGridRows = ({
  data,
}: {
  data?: CharitableGiftsResponse[]
}): GridRowsProp => {
  if (data) {
    const summedData = getTotalledData({ data })

    return summedData.map((gift, index) => {
      return {
        id: index,
        ...gift,
        totalDonations: gift.dollarCount + gift.percentCount
      }
    })
  } else {
    return []
  }
}

export default useTotalledDataGridRows