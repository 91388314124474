import { useState } from 'react'

import {
  defaultPromoCodeData,
  defaultInitialCharityData,
} from 'components/CharityForm/CharityForm'
import { CharityForm } from 'components'
import ConfirmationModal from './ConfirmationModal'
import { CharityPartner } from 'utils/api/charityPartner.api'

export interface CharityPartnerPromoCodeData {
  createPromoCode: boolean
  code: string
  description: string
  isPercent: boolean
  amount: string
}

const CreateCharityPartnerView = () => {
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false)

  const [charityPartnerData, setCharityPartnerData] = useState<CharityPartner>(
    defaultInitialCharityData,
  )

  const [promoCodeData, setPromoCodeData] =
    useState<CharityPartnerPromoCodeData>(defaultPromoCodeData)

  return (
    <>
      <ConfirmationModal
        show={displayConfirmationModal}
        setShow={setDisplayConfirmationModal}
        partnerData={charityPartnerData}
        promoCodeData={promoCodeData}
      />
      <CharityForm
        onSubmit={(data: {
          charityData: CharityPartner
          promoCodeData: CharityPartnerPromoCodeData
        }) => {
          const { charityData, promoCodeData } = data

          setCharityPartnerData(charityData)
          setPromoCodeData(promoCodeData)
          setDisplayConfirmationModal(true)
        }}
      />
    </>
  )
}

export default CreateCharityPartnerView
