import { LineChart } from 'components'
import { format } from 'utils'
import { CharitableGiftsResponse } from 'utils/api/data.types'

import calculateGraphData from '../utils/calculateGraphData'

const ChartsByAge = ({
  filteredData,
}: {
  filteredData: CharitableGiftsResponse[]
}) => {
  const totalDonationsByAge =
    calculateGraphData.totalDonationsByAge(filteredData)

  const averageDonationsByAge =
    calculateGraphData.averageDonationsByAge(filteredData)

  const countOfDonationsByAge =
    calculateGraphData.countOfDonationsByAge(filteredData)

  return (
    <>
      <LineChart
        height="20rem"
        leftLabel="Total Donated Amount"
        bottomLabel="Age"
        data={[totalDonationsByAge]}
        yFormat={(value) => format.currency(value as number)}
      />
      <LineChart
        height="20rem"
        leftLabel="Average Donated Amount"
        bottomLabel="Age"
        data={[averageDonationsByAge]}
        yFormat={(value) => format.currency(value as number)}
      />
      <LineChart
        height="20rem"
        leftLabel="Donation Count"
        bottomLabel="Age"
        data={[countOfDonationsByAge]}
      />
    </>
  )
}

export default ChartsByAge
