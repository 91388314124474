import {
  Box,
  Paper,
  Theme,
  Button,
  useMediaQuery,
  CircularProgress,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { Tabs } from 'components'
import UserOptions from './components/UserOptions'
import Questionnaire from './components/Questionnaire'
import PaymentDetails from './components/PaymentDetails'
import DocumentDetails from './components/DocumentDetails'
import BasicUserDetails from './components/BasicUserDetails'
import QuestionnaireDetails from './components/QuestionnaireDetails'
import { useGetUserDetails, UserContext, spreadSx, styles } from 'utils'
import BonusAnswerStore from './components/BonusAnswerStore'

enum DetailsDisplay {
  Basic = 'basic',
  Payments = 'payments',
  Documents = 'documents',
  Options = 'options',
}

enum QuestionnaireTypes {
  Will = 'will',
  Social = 'social',
  Details = 'details',
  Bonus = 'bonus',
}

const UserDetailsView = () => {
  const { userId } = useParams()
  const navigate = useNavigate()

  const { data, isLoading, isError } = useGetUserDetails(userId || '')

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const [currentDetailsDisplay, setCurrentDetailsDisplay] =
    useState<DetailsDisplay>(DetailsDisplay.Basic)
  const [currentQuestionnaireType, setCurrentQuestionnaireType] =
    useState<QuestionnaireTypes>(QuestionnaireTypes.Will)

  useEffect(() => {
    if (isError) {
      navigate('/users/view')
    }
  }, [isError, navigate])

  return (
    <>
      {!isLoading && data ? (
        <UserContext.Provider value={{ data }}>
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{ alignSelf: 'flex-start', mb: 1 }}
            onClick={() => {
              navigate('/users/view')
            }}
          >
            Back to users
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              columnGap: 2,
              rowGap: 1,
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? undefined : '50%',
                p: 2,
                rowGap: 2,
              }}
            >
              <Tabs
                variant={isMobile ? 'scrollable' : 'fullWidth'}
                value={currentDetailsDisplay}
                onChange={(value) =>
                  setCurrentDetailsDisplay(value as DetailsDisplay)
                }
                tabs={[
                  {
                    label: 'Basic User Details',
                    value: DetailsDisplay.Basic,
                  },
                  {
                    label: 'Document Details',
                    value: DetailsDisplay.Documents,
                  },
                  {
                    label: 'Payment Details',
                    value: DetailsDisplay.Payments,
                  },
                  {
                    label: 'Options',
                    value: DetailsDisplay.Options,
                  },
                ]}
              />
              <Box
                sx={spreadSx([
                  {
                    height: 'calc(100vh - 15rem)',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 2,
                    pr: 2,
                    pt: 1,
                  },
                  styles.scrollbar,
                ])}
              >
                {currentDetailsDisplay === DetailsDisplay.Basic && (
                  <BasicUserDetails />
                )}
                {currentDetailsDisplay === DetailsDisplay.Documents && (
                  <DocumentDetails />
                )}
                {currentDetailsDisplay === DetailsDisplay.Payments && (
                  <PaymentDetails />
                )}
                {currentDetailsDisplay === DetailsDisplay.Options && (
                  <UserOptions />
                )}
              </Box>
            </Paper>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? undefined : '50%',
                p: 2,
                rowGap: 1,
              }}
            >
              <Tabs
                value={currentQuestionnaireType}
                variant={isMobile ? 'scrollable' : undefined}
                onChange={(value) =>
                  setCurrentQuestionnaireType(value as QuestionnaireTypes)
                }
                tabs={[
                  {
                    label: 'Will World',
                    value: QuestionnaireTypes.Will,
                  },
                  {
                    label: 'Social World',
                    value: QuestionnaireTypes.Social,
                  },
                  {
                    label: 'Bonus Docs',
                    value: QuestionnaireTypes.Bonus,
                  },
                  {
                    label: 'Details',
                    value: QuestionnaireTypes.Details,
                  },
                ]}
              />

              {currentQuestionnaireType === QuestionnaireTypes.Will && (
                <Questionnaire type="will" />
              )}
              {currentQuestionnaireType === QuestionnaireTypes.Social && (
                <Questionnaire type="social" />
              )}
              {currentQuestionnaireType === QuestionnaireTypes.Bonus && (
                <BonusAnswerStore />
              )}
              {currentQuestionnaireType === QuestionnaireTypes.Details && (
                <QuestionnaireDetails />
              )}
            </Paper>
          </Box>
        </UserContext.Provider>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            pt: '45vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default UserDetailsView
