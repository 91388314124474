import { ReactText } from 'react'
import { Box, SxProps } from '@mui/material'
import _startCase from 'lodash/startCase'

import { Text } from 'components'
import { dictionary } from 'utils'
import {
  CharitableGiftDetails,
  Child,
  GiftDetails,
  Pet,
  RemoteDistribution,
  CorpTrusteeDetails,
} from 'utils/api/user.types'

const formatNeededAnswerStoreValues = [
  'charitableGiftDetails',
  'charityPartnerGiftDetails',
  'charitableGiftDetails',
  'children',
  'stepChildren',
  'giftDetails',
  'hasCommonName',
  'pets',
  'remoteDistribution',
  'product',
  'corpTrusteeDetails',
  'hasCheckedCorpExecFeeAgreement',
]

const SecondaryText = ({
  children,
  sx,
}: {
  children: ReactText | ReactText[]
  sx?: SxProps
}) => {
  return <Text sx={{ color: 'text.secondary', ...sx }}>{children}</Text>
}

const SmallSecondaryText = ({
  children,
  sx,
}: {
  children: ReactText | ReactText[]
  sx?: SxProps
}) => {
  return (
    <Text sx={{ color: 'text.secondary', fontSize: '0.8rem', ...sx }}>
      {children}
    </Text>
  )
}

const FormattedChildren = ({ value }: { value: Child[] }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      {value.map((child) => (
        <Box
          key={child.id}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr',
            '& > *': {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <SecondaryText>{child.name}</SecondaryText>
          <SmallSecondaryText>Born {child.dob}</SmallSecondaryText>
        </Box>
      ))}
    </Box>
  )
}

const FormattedPets = ({ value }: { value: Pet[] }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      {value.map((pet) => (
        <Box
          key={pet.id}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr',
            '& > *': {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <SecondaryText>{pet.name}</SecondaryText>
          <SmallSecondaryText>{pet.type}</SmallSecondaryText>
        </Box>
      ))}
    </Box>
  )
}

const FormattedCharitableGiftDetails = ({
  value,
}: {
  value: CharitableGiftDetails[]
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      {value.map((gift) => (
        <Box
          key={gift.id}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
          }}
        >
          <SecondaryText sx={{ gridColumn: '1 / span 2' }}>
            {gift.name} {gift.number ? `(${gift.number})` : ''}
          </SecondaryText>

          <SmallSecondaryText>
            {`${gift.isPercent ? '' : '$'}
              ${gift.amount}
              ${gift.isPercent ? '%' : ''}`}
          </SmallSecondaryText>

          <SmallSecondaryText>
            {gift.location ? gift.location : 'No location'}
          </SmallSecondaryText>
        </Box>
      ))}
    </Box>
  )
}

const FormattedCharityPartnerGiftDetails = ({
  value,
}: {
  value: CharitableGiftDetails
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      <Box
        key={value.id}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr',
        }}
      >
        <SecondaryText sx={{ gridColumn: '1 / span 2' }}>
          {value.name} {value.number ? `(${value.number})` : ''}
        </SecondaryText>

        <SmallSecondaryText>
          {`${value.isPercent ? '' : '$'}
              ${value.amount}
              ${value.isPercent ? '%' : ''}`}
        </SmallSecondaryText>

        <SmallSecondaryText>
          {value.location ? value.location : 'No location'}
        </SmallSecondaryText>
      </Box>
    </Box>
  )
}

const FormattedSpecificGiftDetails = ({ value }: { value: GiftDetails[] }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      {value.map((gift) => (
        <Box
          key={gift.id}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
          }}
        >
          <SecondaryText sx={{ gridColumn: '1 / span 2' }}>
            {gift.description}
          </SecondaryText>

          <SmallSecondaryText>{gift.recipient}</SmallSecondaryText>

          <SmallSecondaryText>{gift.relationship}</SmallSecondaryText>
        </Box>
      ))}
    </Box>
  )
}

const FormattedRemoteDistribution = ({
  value,
}: {
  value: RemoteDistribution[]
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      {value.map((distribution) => (
        <Box
          key={distribution.id}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
          }}
        >
          <SecondaryText sx={{ gridColumn: '1 / span 2' }}>
            {distribution.name}{' '}
            {distribution.location ? `of ${distribution.location}` : ''}{' '}
            {distribution.number ? `(${distribution.number})` : ''}
          </SecondaryText>

          <SmallSecondaryText>
            {distribution.portions} portions ({distribution.percentage}%)
          </SmallSecondaryText>

          <SmallSecondaryText>
            {distribution.predeceasedSplit
              ? _startCase(distribution.predeceasedSplit)
              : ''}
          </SmallSecondaryText>
        </Box>
      ))}
    </Box>
  )
}

const FormattedCorpTrustDetails = ({
  value,
}: {
  value: CorpTrusteeDetails
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 4fr',
          gridTemplateRows: '1fr',
        }}
      >
        <SecondaryText sx={{ gridColumn: '1 / span 2' }}>
          {`${value.legalName} (${value.role})`}
        </SecondaryText>

        <SmallSecondaryText>{`Ref: ${value.ref}`}</SmallSecondaryText>

        <SmallSecondaryText>{`Fee agreement signed: ${value.agreeDate}`}</SmallSecondaryText>
      </Box>
    </Box>
  )
}

const FormattedAnswerStoreValue = ({
  storeKey,
  value,
}: {
  storeKey: string
  value: any
}) => {
  if (!formatNeededAnswerStoreValues.includes(storeKey)) {
    return <SecondaryText>{value}</SecondaryText>
  } else {
    switch (storeKey) {
      case 'product':
        return <SecondaryText>{dictionary.doctype(value)}</SecondaryText>
      case 'hasCommonName':
      case 'hasCheckedCorpExecFeeAgreement':
        return <SecondaryText>{value.toString()}</SecondaryText>
      case 'pets':
        return <FormattedPets value={value} />
      case 'children':
      case 'stepChildren':
        return <FormattedChildren value={value} />
      case 'charitableGiftDetails':
        return <FormattedCharitableGiftDetails value={value} />
      case 'charityPartnerGiftDetails':
        return <FormattedCharityPartnerGiftDetails value={value} />
      case 'giftDetails':
        return <FormattedSpecificGiftDetails value={value} />
      case 'remoteDistribution':
        return <FormattedRemoteDistribution value={value} />
      case 'corpTrusteeDetails':
        return <FormattedCorpTrustDetails value={value} />
      default:
        return <SecondaryText>TO BE FORMATTED</SecondaryText>
    }
  }
}

export default FormattedAnswerStoreValue
