import { GridRowsProp } from '@mui/x-data-grid'
import { DistributionCharitiesResponse } from 'utils/api/data.types'

const useGridRows = (
  data?: DistributionCharitiesResponse[],
): GridRowsProp => {
  if (data) {
    return data.map((gift, index) => ({
      id: index,
      ...gift,
      portions: `${gift.portions}/${gift.totalPortions}`,
      portionsAsPercentage: gift.portions / gift.totalPortions
    }))
  } else {
    return []
  }
}

export default useGridRows