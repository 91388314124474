import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import {
  DataEndpoint,
  BusinessReportRequest,
  BusinessReportResponse,
} from 'utils/api/data.types'
import { getBusinessReportData } from 'utils/api/data.api'
import { useState } from 'react'

interface GetBusinessReportDataProps {
  params: BusinessReportRequest
  setDataLoadingError?: (error: string | undefined) => void
  setShowLoadingOverlay?: (show: boolean) => void
  setProcessingStatus?: (status: string | undefined) => void
}

const presetState = async ({
  setDataLoadingError,
  setShowLoadingOverlay,
  setProcessingStatus,
}: Omit<GetBusinessReportDataProps, 'params'>) => {
  setDataLoadingError?.(undefined)
  setShowLoadingOverlay?.(true)
  setProcessingStatus?.(undefined)
}

const sortDataParams = (params: BusinessReportRequest) =>
  (Object.keys(params) as Array<keyof typeof params>)
    .sort()
    .reduce((r: any, k) => ({ ...r, [k]: params[k] }), {})

const useGetBusinessReportData = ({
  params,
  setDataLoadingError,
  setShowLoadingOverlay,
  setProcessingStatus,
}: GetBusinessReportDataProps) => {
  const [hasInitialData, setHasInitialData] = useState(false)

  const query = useQuery<BusinessReportResponse[], AxiosError>({
    queryKey: [DataEndpoint.BusinessReport, sortDataParams(params)],
    queryFn: async ({ signal }) => {
      setHasInitialData(true)

      await presetState({
        setDataLoadingError,
        setShowLoadingOverlay,
        setProcessingStatus,
      })

      try {
        const {
          data: { payload },
        } = await getBusinessReportData(params, signal)

        return Promise.resolve(payload)
      } catch (error) {
        if (signal?.aborted) {
          setDataLoadingError?.('Failed to load report data (Aborted)')
          return Promise.reject('Aborted loading data')
        }

        setDataLoadingError?.(
          (error as AxiosError<{ errorMessage?: string }>).response?.data
            .errorMessage || 'Failed to load report data (Unknown reason)',
        )
        return Promise.reject(error)
      }
    },

    retry: false,
    staleTime: 3600000, // 1 hour
    refetchOnWindowFocus: false,

    refetchOnMount: false,
    enabled: hasInitialData,

    onError: (error) => {
      const typedError = error as AxiosError<{ errorMessage: string }>
      // eslint-disable-next-line no-console
      console.error(
        `Failed to get probate data: ${typedError.response?.status}`,
        typedError.response?.data,
      )
    },
  })

  return { ...query, hasInitialData }
}

export default useGetBusinessReportData
