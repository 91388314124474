import { request } from 'utils'
import {
  UserEndpoint,
  GetUsersParams,
  UsersSearchResponse,
  UserDetails,
  SendResetPasswordResponse,
  UpdateUserRequest,
  GetMeResponse,
} from './user.types'

export const login = ({
  email,
  password,
}: {
  email: string
  password: string
}) =>
  request({
    url: UserEndpoint.Login,
    data: { email, password },
    method: 'POST',
  })

export const logout = () =>
  request({ url: UserEndpoint.Logout, method: 'DELETE' })

export const getMe = (): Promise<GetMeResponse> => {
  return request({ url: UserEndpoint.Me, method: 'GET' }).then(
    (res) => res.data.payload,
  )
}

export const getAuth = (): Promise<boolean> =>
  request({ method: 'GET', url: UserEndpoint.ID })
    .then((res) => !!res.data.payload.userId)
    .catch(() => false)

export const getUsers = (
  params: GetUsersParams,
): Promise<UsersSearchResponse> => {
  return request({ url: UserEndpoint.User, method: 'GET', params }).then(
    (res) => res.data.payload,
  )
}

export const getUserDetails = (userId: string): Promise<UserDetails> => {
  return request({ url: `${UserEndpoint.User}/${userId}`, method: 'GET' }).then(
    (res) => res.data.payload,
  )
}

export const sendResetPassword = (email: string) => {
  return request<SendResetPasswordResponse, {}>({
    url: UserEndpoint.ForgotPassword,
    method: 'POST',
    data: { email },
  }).then((res) => res.data)
}

export const updateUserDetails = (data: UpdateUserRequest, userId: string) => {
  return request({
    url: `${UserEndpoint.User}/${userId}`,
    method: 'PATCH',
    data,
  })
}
