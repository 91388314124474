import {
  Backdrop,
  Box,
  Button,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { queryClient, useBusinessReportContext } from 'utils'
import { DataEndpoint } from 'utils/api/data.types'

const ReportLoadingOverlay = () => {
  const {
    data: {
      isLoadingData,
      dataLoadingError,
      isProcessingData,
      processDataError,
      processingStatus,
      processingProgress,
    },
    interface: { showLoadingOverlay, setShowLoadingOverlay },
  } = useBusinessReportContext()

  const isInErrorState = !!dataLoadingError || !!processDataError

  const step =
    isLoadingData || dataLoadingError
      ? 0
      : isProcessingData || processDataError
      ? 2
      : 3

  return (
    <>
      <Backdrop
        open={showLoadingOverlay}
        style={{ zIndex: 2000 }}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            width: '60rem',
            borderRadius: '0.5rem',
            p: '1rem',
            py: '4rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            rowGap: '4rem',
            mx: '1rem',
          }}
        >
          <Stepper sx={{ width: '100%' }} activeStep={step} alternativeLabel>
            <Step>
              <StepLabel error={!!dataLoadingError}>Retrieving Data</StepLabel>
            </Step>
            <Step>
              <StepLabel error={!!processDataError}>Processing Data</StepLabel>
            </Step>
            <Step>
              <StepLabel>Complete</StepLabel>
            </Step>
          </Stepper>

          <Box sx={{ width: '50%', borderRadius: '1rem' }}>
            <LinearProgress
              color={isInErrorState ? 'error' : undefined}
              variant={isLoadingData ? 'indeterminate' : 'determinate'}
              value={
                isLoadingData
                  ? undefined
                  : isProcessingData
                  ? processingProgress
                  : 100
              }
            />

            <Typography
              align="center"
              sx={{ mt: '1rem' }}
              variant="subtitle2"
              color={isInErrorState ? 'error' : undefined}
            >
              {isInErrorState
                ? dataLoadingError ?? processDataError
                : processingStatus ?? 'Retrieving documents from the server'}
            </Typography>
          </Box>

          <Box>
            <Button
              variant="contained"
              disabled={isProcessingData}
              onClick={() => {
                if (isLoadingData) {
                  queryClient.cancelQueries({
                    queryKey: [DataEndpoint.BusinessReport],
                  })
                } else {
                  setShowLoadingOverlay(false)
                }
              }}
            >
              {isLoadingData ? 'Cancel' : 'Close'}
            </Button>
          </Box>
        </Box>
      </Backdrop>
    </>
  )
}

export default ReportLoadingOverlay
