import { Paper } from '@mui/material'

import { Text } from 'components'

const SingleStat = ({ title, value }: { title: string; value: string }) => {
  return (
    <Paper elevation={5} sx={{ p: 2 }}>
      <Text component="div" variant="h6">
        {title}
      </Text>
      <Text component="div">{value}</Text>
    </Paper>
  )
}

export default SingleStat
