import {
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import TitledWrapper from 'components/TitledWrapper/TitledWrapper'

interface InputListProps {
  onChange: (values: (string | undefined)[]) => void
  invalidIndices?: number[]
  displayValidation?: boolean
  maxItems?: number
  minItems?: number
  invalidIfEmpty?: boolean
  label?: string
  startingItems?: number
  values: (string | undefined)[]
}

const InputList = (props: InputListProps) => {
  const {
    onChange,
    invalidIndices = [],
    displayValidation = true,
    maxItems,
    minItems,
    invalidIfEmpty = false,
    label,
    values,
  } = props

  const removeValue = (indexToRemove: number) => {
    const valuesClone = [...values]
    valuesClone.splice(indexToRemove, 1)
    onChange(valuesClone)
  }

  const updateValue = (indexToUpdate: number, newValue: string) => {
    const valuesClone = [...values]
    valuesClone[indexToUpdate] = newValue === '' ? undefined : newValue
    onChange(valuesClone)
  }

  const hideControls = minItems && maxItems && minItems === maxItems

  return (
    <TitledWrapper
      title={label}
      sx={{ display: 'flex', flexDirection: 'column', py: 1 }}
    >
      <List>
        {values.map((_, index) => (
          <ListItem
            dense
            key={index}
            secondaryAction={
              !hideControls ? (
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    removeValue(index)
                  }}
                  disabled={minItems ? values.length <= minItems : false}
                >
                  <CloseIcon />
                </IconButton>
              ) : undefined
            }
          >
            <ListItemText sx={{ mr: !hideControls ? 2 : 0 }}>
              <TextField
                margin="none"
                fullWidth
                value={values[index] || ''}
                onChange={(event) => {
                  updateValue(index, event.target.value)
                }}
                error={
                  displayValidation &&
                  (invalidIndices.includes(index) ||
                    (invalidIfEmpty && values[index] === undefined))
                }
                inputProps={{
                  sx: {
                    py: 1,
                    px: 2,
                  },
                }}
              />
            </ListItemText>
          </ListItem>
        ))}
      </List>
      {!hideControls && (
        <IconButton
          aria-label="add another row"
          sx={{
            alignSelf: 'center',
            mr: 2.1,
            mb: 1,
            border: 'solid 1px #CCCCCC',
          }}
          onClick={() => {
            onChange([...values, undefined])
          }}
          disabled={maxItems ? values.length >= maxItems : false}
        >
          <AddIcon />
        </IconButton>
      )}
    </TitledWrapper>
  )
}

export default InputList
