import { Button } from '@mui/material'
import { useContext, useState } from 'react'
import { UserContext } from 'utils'

import PasswordResetEmailConfirmationModal from './options/PasswordResetEmailConfirmationModal'

const UserOptions = () => {
  const [showEmailConfirmationModal, setShowEmailConfirmationModal] =
    useState(false)

  const { data } = useContext(UserContext) || {}

  return data ? (
    <>
      <PasswordResetEmailConfirmationModal
        show={showEmailConfirmationModal}
        setShow={setShowEmailConfirmationModal}
        email={data.email}
      />

      <Button
        variant="contained"
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => {
          setShowEmailConfirmationModal(true)
        }}
      >
        Send Password Reset Email
      </Button>
    </>
  ) : (
    <></>
  )
}

export default UserOptions
