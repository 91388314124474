import { useState } from 'react'

import { Province } from 'global.types'
import { LawyerForm } from 'components'
import { Lawyer } from 'utils/api/lawyer.types'
import ConfirmationModal from './ConfirmationModal'

const CreateLawyerView = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [lawyerRequestData, setLawyerRequestData] = useState<Lawyer>({
    _id: undefined,
    bio: undefined,
    city: '',
    email: '',
    imageUrl: '',
    worksRemote: true,
    active: true,
    province: Province.Alberta,
    lawyerFirstName: undefined,
    lawyerLastName: undefined,
    contactFirstName: undefined,
    contactLastName: undefined,
    firm: undefined,
    highlights: undefined,
    flatFeeData: undefined,
  })

  const onSubmit = (data: Lawyer) => {
    setLawyerRequestData(data)
    setShowConfirmationModal(true)
  }

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        setShow={setShowConfirmationModal}
        lawyerRequestData={lawyerRequestData}
      />
      <LawyerForm onSubmit={onSubmit} />
    </>
  )
}

export default CreateLawyerView
