import {
  Box,
  Paper,
  Theme,
  Select,
  MenuItem,
  TextField,
  Pagination,
  InputLabel,
  FormControl,
  useMediaQuery,
  Button,
} from '@mui/material'
import { forwardRef, useState } from 'react'

import { GetPromoCodeLimit } from 'utils/api/promoCode.api'
import { InputField } from 'components'

interface TableControlsProps {
  page: number
  setPage: Function
  setLimit: Function
  setQuery: Function
  limit: GetPromoCodeLimit
  query?: string
  totalPages: number
}

const TableControls = forwardRef<HTMLDivElement, TableControlsProps>(
  ({ totalPages, page, limit, query, setPage, setLimit, setQuery }, ref) => {
    const [goToPage, setGoToPage] = useState<string | undefined>(undefined)
    const [displayValidation, setDisplayValidation] = useState(false)

    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('md'),
    )

    return (
      <Paper
        ref={ref}
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          columnGap: 2,
          rowGap: 2,
          py: 2,
          background: (theme: Theme) =>
            theme.palette.mode === 'dark' ? 'none' : undefined,
        }}
      >
        <Box sx={{ flexGrow: 1, width: isMobile ? '100%' : undefined }}>
          <TextField
            fullWidth={isMobile}
            label="Search"
            value={query || ''}
            onChange={(event) => {
              const value = event.target.value
              setPage(1)
              setQuery(value || undefined)
            }}
          />
        </Box>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, value) => {
            setPage(value)
          }}
        />
        <FormControl
          sx={{ display: isMobile ? 'none' : undefined, minWidth: '4.5rem' }}
        >
          <InputLabel id="limit-select-label">Limit</InputLabel>
          <Select
            labelId="limit-select-label"
            value={limit}
            label="Limit"
            onChange={(event) => {
              if (typeof event.target.value === 'number') {
                setPage(1)
                setLimit(event.target.value)
              }
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: 1,
            borderLeft: isMobile ? undefined : 'solid 1px #CCCCCC',
            pl: isMobile ? 0 : 2,
            width: '10rem',
          }}
        >
          <InputField
            min={1}
            max={totalPages}
            fullWidth={isMobile}
            type="number"
            label="Go To"
            onChange={setGoToPage}
            value={goToPage}
            displayValidation={displayValidation}
            isValid={
              !goToPage ||
              (parseInt(goToPage) <= totalPages && parseInt(goToPage) > 0)
            }
          />
          <Button
            onClick={() => {
              if (
                goToPage &&
                parseInt(goToPage) <= totalPages &&
                parseInt(goToPage) > 0
              ) {
                setDisplayValidation(false)
                setGoToPage(undefined)
                setPage(parseInt(goToPage))
              } else {
                setDisplayValidation(true)
              }
            }}
          >
            Go
          </Button>
        </Box>
      </Paper>
    )
  },
)

export default TableControls
