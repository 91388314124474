import moment from 'moment'
import _get from 'lodash/get'

import {
  PromoCodesByMonthData,
  CompletionAndRevenueData,
  HowDidYouHearAboutUsData,
  QuestionnairesOverTimeData,
  WorkerParams,
  DemographicsData,
  RbcMonthlyReport,
} from './processBusinessDataWorkers.types'
import formatDate from './utils/formatDate'
import getAgeRange from './utils/getAgeRange'

const workerCatch = <T>(
  func: (param: WorkerParams) => T,
): ((params: WorkerParams) => T) => {
  return (params: WorkerParams) => {
    try {
      return func(params)
    } catch (error) {
      console.error(error)
      throw new Error(`An error occurred while processing ${func.name}`)
    }
  }
}

export const howDidYouHearAboutUs = workerCatch(
  ({ reportData }: WorkerParams): HowDidYouHearAboutUsData => {
    const workingData = reportData.filter(
      (d) => d.user?.howDidYouHearAboutUs !== undefined,
    )

    const options = [
      ...new Set(
        workingData.map(
          (item) => item.user?.howDidYouHearAboutUs ?? 'noAnswer',
        ),
      ),
    ]

    const data = Object.assign(
      {},
      ...options.map((option) => ({
        [option]: {
          paid: 0,
          unpaid: 0,
        },
      })),
    )

    for (const user of workingData) {
      const option = user.user?.howDidYouHearAboutUs ?? 'noAnswer'

      if (user.meta?.hasPaid) {
        data[option].paid += 1
      } else {
        data[option].unpaid += 1
      }
    }

    return {
      options,
      data,
    }
  },
)

export const completionAndRevenueRates = workerCatch(
  ({ reportData }: WorkerParams): CompletionAndRevenueData => {
    // This is currently only the first transaction of the customer, not including add ons purchased later
    const workingData = reportData

    const data: CompletionAndRevenueData = {
      '18-25': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      '26-35': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      '36-45': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      '46-55': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      '56-65': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      '66-75': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      '76-85': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      '85+': {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
      total: {
        partnerCount: 0,
        nonPartnerCount: 0,
        generated: 0,
        paid: 0,
        totalRevenue: 0,
      },
    }

    for (const user of workingData) {
      if (!user.user || !user.meta || !user.meta.ageAtAccountCreation) continue

      const {
        meta: { hasPaid, ageAtAccountCreation, hasGenerated },
        user: { isPartner, payments },
      } = user

      const [firstPayment] = payments

      const { key } =
        getAgeRange<keyof CompletionAndRevenueData>(ageAtAccountCreation)

      data[key].partnerCount += isPartner ? 1 : 0
      data[key].nonPartnerCount += !isPartner ? 1 : 0
      data[key].generated += hasGenerated ? 1 : 0
      data[key].paid += hasPaid ? 1 : 0
      data[key].totalRevenue += firstPayment?.price ?? 0

      data.total.partnerCount += isPartner ? 1 : 0
      data.total.nonPartnerCount += !isPartner ? 1 : 0
      data.total.generated += hasGenerated ? 1 : 0
      data.total.paid += hasPaid ? 1 : 0
      data.total.totalRevenue += firstPayment?.price ?? 0
    }

    return data
  },
)

export const promoCodesByMonth = workerCatch(
  ({ reportData }: WorkerParams): PromoCodesByMonthData => {
    const data: PromoCodesByMonthData = {
      codes: {},
      months: [],
    }
    const payments = reportData.flatMap((data) =>
      data.user?.payments ? data.user?.payments : [],
    )

    // Process promo codes
    for (const payment of payments) {
      if (payment.promoCodeUsed) {
        const {
          created,
          promoCodeUsed: {
            code,
            isPercent,
            expiryDate,
            amount,
            description,
            referrer,
          },
        } = payment

        const processedMonth = moment(created).format('MMM-YY')

        if (data.codes[code]) {
          // Code exists
          data.codes[code].total += 1

          if (data.codes[code].monthly[processedMonth]) {
            // Month exists
            data.codes[code].monthly[processedMonth] += 1
          } else {
            // Month doesn't exist
            data.codes[code].monthly[processedMonth] = 1
          }
        } else {
          // First time code seen
          data.codes[code] = {
            monthly: {
              [processedMonth]: 1,
            },
            total: 1,
            meta: {
              isPercent,
              expiryDate,
              amount,
              description,
              referrer,
            },
          }
        }
      }
    }

    // Process months
    const months = [
      ...new Set(
        Object.values(data.codes).flatMap((currentCode) =>
          Object.keys(currentCode.monthly),
        ),
      ),
    ]

    months.sort((a, b) => {
      return moment(a, 'MMM-YY').isBefore(moment(b, 'MMM-YY')) ? -1 : 1
    })

    data.months = months

    return data
  },
)

export const questionnairesOverTime = workerCatch(
  ({ reportData }: WorkerParams): QuestionnairesOverTimeData => {
    const keys = [
      ...new Set(
        Object.values(reportData)
          .flatMap((userData) => [
            userData.meta?.localizedTimes.questionnaireCreatedAt.date,
            userData.meta?.localizedTimes.accountCreatedAt.date,
            userData.meta?.localizedTimes.firstGeneration.date,
            userData.meta?.localizedTimes.firstPayment.date,
          ])
          .map((date) => (!!date ? formatDate.yearMonth(date) : '')),
      ),
    ].filter((key) => typeof key === 'string' && key !== '') as string[]

    keys.sort((a, b) => {
      return moment(a, 'YYYY-MM').isBefore(moment(b, 'YYYY-MM')) ? -1 : 1
    })

    const data: QuestionnairesOverTimeData = Object.assign(
      {},
      ...keys.map((key) => ({
        [key]: {
          partner: {
            questionnaires: 0,
            accounts: 0,
            generated: 0,
            paid: 0,
          },
          nonPartner: {
            questionnaires: 0,
            accounts: 0,
            generated: 0,
            paid: 0,
          },
        },
      })),
    )

    for (const user of reportData) {
      const questionnaireCreatedDate = _get(
        user,
        'meta.localizedTimes.questionnaireCreatedAt.date',
        undefined,
      ) as string
      const accountCreatedDate = _get(
        user,
        'meta.localizedTimes.accountCreatedAt.date',
      ) as string | null
      const firstGenerationDate = _get(
        user,
        'meta.localizedTimes.firstGeneration.date',
      ) as string | null
      const firstPaymentDate = _get(
        user,
        'meta.localizedTimes.firstPayment.date',
      ) as string | null
      const isPartner = _get(user, 'user.isPartner', false)

      const keys = {
        questionnaire: formatDate.yearMonth(questionnaireCreatedDate),
        account: formatDate.yearMonth(accountCreatedDate),
        generation: formatDate.yearMonth(firstGenerationDate),
        payment: formatDate.yearMonth(firstPaymentDate),
      }

      if (keys.questionnaire) {
        data[keys.questionnaire][
          isPartner ? 'partner' : 'nonPartner'
        ].questionnaires += 1
      }

      if (keys.account) {
        data[keys.account][isPartner ? 'partner' : 'nonPartner'].accounts += 1
      }

      if (keys.generation) {
        data[keys.generation][
          isPartner ? 'partner' : 'nonPartner'
        ].generated += 1
      }

      if (keys.payment) {
        data[keys.payment][isPartner ? 'partner' : 'nonPartner'].paid += 1
      }
    }

    return data
  },
)

export const demographics = workerCatch(
  ({ reportData }: WorkerParams): DemographicsData => {
    const data: DemographicsData = {
      questionnaires: {
        nonPartner: {
          questionnaires: 0,
          accounts: 0,
          generated: 0,
          paid: 0,
        },
        partner: {
          questionnaires: 0,
          accounts: 0,
          generated: 0,
          paid: 0,
        },
        total: 0,
      },
      paidAccounts: {
        will: {
          rbc: {
            partner: 0,
            noPartner: 0,
          },
          nonRbc: {
            partner: 0,
            noPartner: 0,
          },
        },
        willPoa: {
          rbc: {
            partner: 0,
            noPartner: 0,
          },
          nonRbc: {
            partner: 0,
            noPartner: 0,
          },
        },
        total: 0,
        totalWills: 0,
      },
      cartSize: {
        totalDocumentsRevenue: 0,
        documentsRevenuePerPaidAccount: 0,
      },
      printing: {
        yesPrinting: {
          partner: 0,
          noPartner: 0,
          total: 0,
        },
        noPrinting: {
          partner: 0,
          noPartner: 0,
          total: 0,
        },
        breakdown: {
          initial: {
            individual: 0,
            couples: 0,
          },
          followUp: 0,
        },
      },
      printingCartSize: {
        totalPrintingRevenue: 0,
        printingRevenuePerPaidAccount: 0,
      },
      packageSelections: {
        individualWill: {
          rbc: 0,
          nonRbc: 0,
        },
        individualWillPoa: {
          rbc: 0,
          nonRbc: 0,
        },
        coupleWill: {
          rbc: 0,
          nonRbc: 0,
        },
        coupleWillPoa: {
          rbc: 0,
          nonRbc: 0,
        },
      },
    }

    for (const report of reportData) {
      const isPartner = _get(report, 'user.isPartner', false)
      const isRbc = report.questionnaire.referrer.ref === 'rbc'
      const hasPaid = !!report.meta?.localizedTimes.firstPayment.date
      const hasGenerated = !!report.meta?.localizedTimes.firstGeneration.date
      const hasCreatedAccount =
        !!report.meta?.localizedTimes.accountCreatedAt.date
      const packageSelected = (report.questionnaire.answerStore?.product ??
        'none') as 'will' | 'willPoa' | 'none'
      const payments = report.user?.payments ?? []
      const initialPayment =
        payments[0] && payments[0].chargeId !== 'paid by partner'
          ? payments[0]
          : undefined
      const willForPartner =
        report.questionnaire.answerStore?.includePartner === 'yes'

      const rbcKey = isRbc ? 'rbc' : 'nonRbc'
      const isPartnerKey = isPartner ? 'partner' : 'nonPartner'
      const paidForPartnerKey = willForPartner ? 'partner' : 'noPartner'

      // Overall Stats
      data.questionnaires.total += 1
      data.questionnaires[isPartnerKey].questionnaires += 1
      data.questionnaires[isPartnerKey].accounts += hasCreatedAccount ? 1 : 0
      data.questionnaires[isPartnerKey].generated += hasGenerated ? 1 : 0
      data.questionnaires[isPartnerKey].paid += hasPaid ? 1 : 0

      // Breakdown of paid accounts
      if (hasPaid && !isPartner && packageSelected !== 'none') {
        data.paidAccounts[packageSelected][rbcKey][paidForPartnerKey] += 1
        data.paidAccounts.total += 1
        data.paidAccounts.totalWills += willForPartner ? 2 : 1
      }

      // Cart size
      if (initialPayment) {
        const { addOns, price, discount } = initialPayment
        const [addOn] = addOns

        data.cartSize.totalDocumentsRevenue +=
          price ?? 0 - (discount ?? 0) - (addOn?.price ?? 0)
      }

      // Printing
      if (
        initialPayment &&
        initialPayment.addOns[0] &&
        initialPayment.addOns[0].type === 'printing'
      ) {
        // paid and got printing
        data.printing.yesPrinting[paidForPartnerKey] += 1
        data.printing.yesPrinting.total += 1
      } else if (initialPayment) {
        // paid and skipped printing
        data.printing.noPrinting[paidForPartnerKey] += 1
        data.printing.noPrinting.total += 1
      }

      // Printing breakdown
      if (payments.length > 0) {
        const [initialPayment, ...additionalPayments] = payments

        if (
          initialPayment.addOns[0] &&
          initialPayment.addOns[0].type === 'printing' &&
          initialPayment.chargeId !== 'paid by partner'
        ) {
          const [addOn] = initialPayment.addOns

          if (addOn.partnerPaidFor) {
            data.printing.breakdown.initial.couples += 1
          } else {
            data.printing.breakdown.initial.individual += 1
          }
        }

        for (const payment of additionalPayments) {
          const { addOns } = payment
          for (const addOn of addOns) {
            data.printing.breakdown.followUp +=
              addOn.type === 'printing' ? 1 : 0
          }
        }
      }

      // Printing cart size
      if (payments.length > 0) {
        const addOns = payments
          .map((payment) => payment.addOns)
          .flat()
          .filter((addOn) => addOn.type === 'printing')
          .reduce((acc, addOn) => acc + (addOn.price ?? 0), 0)

        data.printingCartSize.totalPrintingRevenue += addOns
      }

      // Package Selections
      if (initialPayment) {
        const packageKey = `${willForPartner ? 'couple' : 'individual'}${
          packageSelected === 'willPoa' ? 'WillPoa' : 'Will'
        }` as keyof DemographicsData['packageSelections']

        data.packageSelections[packageKey][rbcKey] += 1
      }
    }

    // Total based calculations
    data.cartSize.documentsRevenuePerPaidAccount =
      data.cartSize.totalDocumentsRevenue / data.paidAccounts.total

    data.printingCartSize.printingRevenuePerPaidAccount =
      data.printingCartSize.totalPrintingRevenue / data.paidAccounts.total

    return data
  },
)

export const rbcMonthlyReport = workerCatch(
  ({ reportData }: WorkerParams): RbcMonthlyReport => {
    const preFiltered = [
      'EMPLOYEE-1011422',
      'EMPLOYEE-10211222',
      'WEALTH-13011822',
      'BANKING-13011822',
      'AMPLI-MARCH-22',
      'INSURANCE-04050322',
    ]

    const isPrefiltered = ({
      ref,
      reftag,
    }: {
      ref?: string
      reftag?: string
    }) => {
      if (!ref || !reftag) return false
      if (ref.toUpperCase() !== 'RBC') return false
      return preFiltered.includes(ref.toUpperCase())
    }

    const filteredReportData = reportData.filter(
      (report) =>
        !moment(report.questionnaire.createdAt).isBefore('2022-01-01'),
    )

    const dividedByRef = {
      'EMPLOYEE-1011422': filteredReportData.filter(
        (report) =>
          report.questionnaire.referrer.ref?.toUpperCase() === 'RBC' &&
          report.questionnaire.referrer.reftag?.toUpperCase() ===
            'EMPLOYEE-1011422',
      ),
      'EMPLOYEE-10211222': filteredReportData.filter(
        (report) =>
          report.questionnaire.referrer.ref?.toUpperCase() === 'RBC' &&
          report.questionnaire.referrer.reftag?.toUpperCase() ===
            'EMPLOYEE-10211222',
      ),
      'WEALTH-13011822': filteredReportData.filter(
        (report) =>
          report.questionnaire.referrer.ref?.toUpperCase() === 'RBC' &&
          report.questionnaire.referrer.reftag?.toUpperCase() ===
            'WEALTH-13011822',
      ),
      'BANKING-13011822': filteredReportData.filter(
        (report) =>
          report.questionnaire.referrer.ref?.toUpperCase() === 'RBC' &&
          report.questionnaire.referrer.reftag?.toUpperCase() ===
            'BANKING-13011822',
      ),
      'AMPLI-MARCH-22': filteredReportData.filter(
        (report) =>
          report.questionnaire.referrer.ref?.toUpperCase() === 'RBC' &&
          report.questionnaire.referrer.reftag?.toUpperCase() ===
            'AMPLI-MARCH-22',
      ),
      'INSURANCE-04050322': filteredReportData.filter(
        (report) =>
          report.questionnaire.referrer.ref?.toUpperCase() === 'RBC' &&
          report.questionnaire.referrer.reftag?.toUpperCase() ===
            'INSURANCE-04050322',
      ),
      'RBC-EMPLOYEE-': filteredReportData.filter(
        (report) =>
          !isPrefiltered(report.questionnaire.referrer) &&
          report.user?.payments[0]?.promoCodeUsed?.code
            .toUpperCase()
            .startsWith('RBC-EMPLOYEE'),
      ),
      'RBC-': filteredReportData.filter(
        (report) =>
          !isPrefiltered(report.questionnaire.referrer) &&
          !report.user?.payments[0]?.promoCodeUsed?.code
            .toUpperCase()
            .startsWith('RBC-EMPLOYEE') &&
          report.user?.payments[0]?.promoCodeUsed?.code
            .toUpperCase()
            .startsWith('RBC'),
      ),
    }

    // This one includes all data, including users we find by promo code
    // const allRbcData = Object.values(dividedByRef).flat()

    const allRbcData = filteredReportData.filter(
      (report) => report.questionnaire.referrer.ref?.toUpperCase() === 'RBC',
    )

    const emptyMetric = {
      accounts: 0,
      generated: 0,
      paid: 0,
    }

    const data: RbcMonthlyReport = {
      byRef: {},
      byGender: {
        male: {
          ...emptyMetric,
        },
        female: {
          ...emptyMetric,
        },
        'no answer': {
          ...emptyMetric,
        },
      },
      byPartner: {
        partner: {
          ...emptyMetric,
        },
        'no partner': {
          ...emptyMetric,
        },
      },
      byChildren: {
        children: {
          ...emptyMetric,
        },
        'no children': {
          ...emptyMetric,
        },
      },
      byAge: {
        '18-25': { ...emptyMetric },
        '26-35': { ...emptyMetric },
        '36-45': { ...emptyMetric },
        '46-55': { ...emptyMetric },
        '56-65': { ...emptyMetric },
        '66-75': { ...emptyMetric },
        '76-85': { ...emptyMetric },
        '86+': { ...emptyMetric },
        total: { ...emptyMetric },
      },
      byMonth: {},
    }

    const ageRanges = [
      {
        min: 18,
        max: 25,
      },
      {
        min: 26,
        max: 35,
      },
      {
        min: 36,
        max: 45,
      },
      {
        min: 46,
        max: 55,
      },
      {
        min: 56,
        max: 65,
      },
      {
        min: 66,
        max: 75,
      },
      {
        min: 76,
        max: 85,
      },
      {
        min: 86,
        max: 999,
      },
    ]

    // By Ref
    for (const [ref, reports] of Object.entries(dividedByRef)) {
      const metrics = {
        questionnaires: 0,
        accounts: 0,
        generated: 0,
        paid: 0,
        revenue: 0,
      }

      for (const report of reports) {
        const hasPaid = !!report.meta?.hasPaid
        const hasGenerated = !!report.meta?.hasGenerated
        const hasCreatedAccount = !!report.meta?.hasCreatedAccount

        metrics.questionnaires += 1
        metrics.accounts += hasCreatedAccount ? 1 : 0
        metrics.generated += hasGenerated ? 1 : 0
        metrics.paid += hasPaid ? 1 : 0
        metrics.revenue +=
          (report.user?.payments[0]?.price ?? 0) -
          (report.user?.payments[0]?.discount ?? 0)
      }

      data.byRef[ref] = metrics
    }

    for (const report of allRbcData) {
      const hasPaid = !!report.meta?.hasPaid
      const hasGenerated = !!report.meta?.hasGenerated
      const hasCreatedAccount = !!report.meta?.hasCreatedAccount

      const genderKey =
        report.questionnaire.answerStore?.gender === 'male'
          ? 'male'
          : report.questionnaire.answerStore?.gender === 'female'
          ? 'female'
          : 'no answer'
      const partnerKey =
        report.questionnaire.answerStore?.hasPartner === 'yes'
          ? 'partner'
          : 'no partner'
      const childrenKey =
        report.questionnaire.answerStore?.hasChildren === 'yes'
          ? 'children'
          : 'no children'
      const ageRangeKey = report.meta?.ageAtAccountCreation
        ? getAgeRange<
            | '18-25'
            | '26-35'
            | '36-45'
            | '46-55'
            | '56-65'
            | '66-75'
            | '76-85'
            | '86+'
          >(report.meta?.ageAtAccountCreation, ageRanges).key
        : undefined
      const monthKey = moment(report.questionnaire.createdAt).format('MMM-YY')

      // By Gender
      data.byGender[genderKey].accounts += hasCreatedAccount ? 1 : 0
      data.byGender[genderKey].generated += hasGenerated ? 1 : 0
      data.byGender[genderKey].paid += hasPaid ? 1 : 0

      // By Partner
      data.byPartner[partnerKey].accounts += hasCreatedAccount ? 1 : 0
      data.byPartner[partnerKey].generated += hasGenerated ? 1 : 0
      data.byPartner[partnerKey].paid += hasPaid ? 1 : 0

      // By Children
      data.byChildren[childrenKey].accounts += hasCreatedAccount ? 1 : 0
      data.byChildren[childrenKey].generated += hasGenerated ? 1 : 0
      data.byChildren[childrenKey].paid += hasPaid ? 1 : 0

      // By Age
      if (ageRangeKey) {
        data.byAge[ageRangeKey].accounts += hasCreatedAccount ? 1 : 0
        data.byAge[ageRangeKey].generated += hasGenerated ? 1 : 0
        data.byAge[ageRangeKey].paid += hasPaid ? 1 : 0

        data.byAge.total.accounts += hasCreatedAccount ? 1 : 0
        data.byAge.total.generated += hasGenerated ? 1 : 0
        data.byAge.total.paid += hasPaid ? 1 : 0
      }

      // By month
      if (!data.byMonth[monthKey]) {
        // New month data
        data.byMonth[monthKey] = {
          questionnaires: 1,
          accounts: hasCreatedAccount ? 1 : 0,
          generated: hasGenerated ? 1 : 0,
          paid: hasPaid ? 1 : 0,
          revenue:
            (report.user?.payments[0]?.price ?? 0) -
            (report.user?.payments[0]?.discount ?? 0),
        }
      } else {
        // Month data already exists
        data.byMonth[monthKey].questionnaires += 1
        data.byMonth[monthKey].accounts += hasCreatedAccount ? 1 : 0
        data.byMonth[monthKey].generated += hasGenerated ? 1 : 0
        data.byMonth[monthKey].paid += hasPaid ? 1 : 0
        data.byMonth[monthKey].revenue +=
          (report.user?.payments[0]?.price ?? 0) -
          (report.user?.payments[0]?.discount ?? 0)
      }
    }

    return data
  },
)
