import { useState, useEffect } from 'react'

/*
 *
 * To determine the correct value for the 'targetKey' argument,
 * visit: https://keycode.info/ and use the key's event.key value
 *
 */

const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = useState(false)

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === targetKey) {
      return setKeyPressed(true)
    }
  }
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === targetKey) {
      return setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return keyPressed
}

export default useKeyPress