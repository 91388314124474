import { Box, Checkbox, FormGroup, FormControlLabel } from '@mui/material'

interface BasicFiltersProps {
  filterUngenerated: boolean
  setFilterUngenerated: (filterUngenerated: boolean) => void
  filterUnpaid: boolean
  setFilterUnpaid: (filterUngenerated: boolean) => void
}

const BasicFilters = ({
  filterUngenerated,
  setFilterUngenerated,
  filterUnpaid,
  setFilterUnpaid,
}: BasicFiltersProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormGroup row sx={{ justifyContent: 'flex-end', mt: '0.2rem' }}>
        <FormControlLabel
          control={
            <Checkbox
              value={filterUngenerated}
              onChange={() => setFilterUngenerated(!filterUngenerated)}
            />
          }
          label="Filter ungenerated"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={filterUnpaid}
              onChange={() => setFilterUnpaid(!filterUnpaid)}
            />
          }
          label="Filter unpaid"
        />
      </FormGroup>
    </Box>
  )
}

export default BasicFilters
