import { Children } from 'react'
import { Paper, Typography, Box, SxProps, alpha } from '@mui/material'

type DisplayData = number | string
type ValueFormatter<DataType> = (value: DataType) => DisplayData
type Alignment = 'left' | 'right' | 'center'

interface CellProps {
  value: DisplayData
  sx?: SxProps
  align?: Alignment
}

interface RawDataTableBaseProps<DataType> {
  title?: string
  data: DataType[]
  rowHeaders: string[]
  columnHeaders?: string[]
  columnCount?: number
  valueFormat?: ValueFormatter<DataType>[]
  columnAlignment?: Alignment[]
}

interface RawDataTableWithColumnHeaders<DataType>
  extends RawDataTableBaseProps<DataType> {
  columnHeaders: string[]
  columnCount?: never
}

interface RawDataTableColumnCount<DataType>
  extends RawDataTableBaseProps<DataType> {
  columnHeaders?: never
  columnCount: number
}

type RawDataTableProps<DataType> =
  | RawDataTableWithColumnHeaders<DataType>
  | RawDataTableColumnCount<DataType>

const formatData = <DataType,>(
  data: DataType,
  valueFormat?: ValueFormatter<DataType>,
): DisplayData => {
  if (typeof valueFormat === 'function') {
    return valueFormat(data)
  }

  if (typeof data === 'object') {
    return JSON.stringify(data)
  }

  return data as unknown as DisplayData
}

const Cell = ({ value, sx, align = 'right' }: CellProps) => (
  <Typography align={align} sx={{ ...sx }}>
    {value}
  </Typography>
)

const HeaderCell = ({ sx, align = 'left', ...props }: CellProps) => (
  <Cell sx={{ fontWeight: 'bold', ...sx }} align={align} {...props} />
)

const ColumnHeaderCell = ({ align = 'right', ...props }: CellProps) => (
  <HeaderCell
    sx={{
      borderBottom: `solid 1px ${alpha('#000000', 0.5)}`,
      mr: '0.5rem',
    }}
    align={align}
    {...props}
  />
)

const RawDataTable = <DataType,>(props: RawDataTableProps<DataType>) => {
  const {
    data,
    title,
    rowHeaders,
    valueFormat,
    columnCount,
    columnHeaders,
    columnAlignment,
  } = props
  const rowCount = rowHeaders.length
  const dataColumnCount = columnHeaders?.length ?? columnCount ?? 0

  return (
    <Paper elevation={5}>
      {title && (
        <Typography sx={{ fontWeight: 'bold', m: '1rem' }}>{title}</Typography>
      )}
      <Box
        sx={{
          m: '1rem',
          display: 'grid',
          gridTemplateRows: `repeat(${rowCount}, 1fr)`,
          gridTemplateColumns: `3fr repeat(${dataColumnCount}, 2fr)`,
          '& > div, & > p': {
            px: '0.25rem',
            py: '0.25rem',
          },
          [`& > p:not(:nth-of-type(-n+${dataColumnCount}))`]: {
            borderBottom: `solid 1px ${alpha('#CCCCCC', 0.4)}`,
            pr: '0.75rem',
          },
        }}
      >
        <div />
        {Children.toArray(
          (columnHeaders ?? Array(columnCount).fill(null)).map(
            (header, columnIndex) =>
              header ? (
                <ColumnHeaderCell
                  value={header}
                  align={
                    columnAlignment ? columnAlignment[columnIndex] : undefined
                  }
                />
              ) : (
                <p />
              ),
          ),
        )}

        {Children.toArray(
          rowHeaders.map((header, rowIndex) => {
            return [
              <HeaderCell value={header} />,

              ...Array(dataColumnCount)
                .fill(null)
                .map((_, columnIndex) => {
                  const dataRow = data[rowIndex]

                  if (dataRow instanceof Array) {
                    return (
                      <Cell
                        value={formatData(
                          dataRow[columnIndex],
                          valueFormat?.[rowIndex],
                        )}
                        align={
                          columnAlignment
                            ? columnAlignment[columnIndex]
                            : undefined
                        }
                      />
                    )
                  } else {
                    return (
                      <Cell
                        value={formatData(
                          data[columnIndex],
                          valueFormat?.[rowIndex],
                        )}
                        align={
                          columnAlignment
                            ? columnAlignment[columnIndex]
                            : undefined
                        }
                      />
                    )
                  }
                })
                .flat(),
            ]
          }),
        )}
      </Box>
    </Paper>
  )
}

export default RawDataTable
