import { SFC, SVGProps } from 'react'
import { SvgIconComponent } from '@mui/icons-material'

import AddBoxIcon from '@mui/icons-material/AddBox'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SsidChartIcon from '@mui/icons-material/SsidChart'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'

import {
  // Charity views
  CharityEmbedGeneratorView,
  CreateCharityPartnerView,
  ListCharitiesView,

  // Promo code views
  CreatePromoCodeView,
  ListPromoCodesView,

  // Users views
  ListUsersView,

  // Lawyer views
  CreateLawyerView,

  // Analytics and Reports views
  CharitableGiftsView,
  CharityPartnerGiftsView,
  DistributionCharitiesGiftsView,
  BusinessReportView,
  CorporateExecutorReportView,
  RbcReportView,
} from 'views'

import { Role } from 'utils/api/role.api'

export interface View {
  title: string
  description: string
  path: string
  Icon: SvgIconComponent | SFC<SVGProps<SVGSVGElement>>
  roles: Role[]
  showOnHomePage: boolean
  View: () => JSX.Element
}

export interface Section {
  name: string
  children: View[]
}

const views: Section[] = [
  {
    name: 'Charity Partner',
    children: [
      {
        title: 'Charity Embed',
        description: 'Generate embed codes for charity partners',
        path: '/charity/embed',
        Icon: IntegrationInstructionsIcon,
        roles: [Role.Admin],
        showOnHomePage: false,
        View: CharityEmbedGeneratorView,
      },
      {
        title: 'Create Charity Partner',
        description: 'Create a new charity partner',
        path: '/charity/create',
        Icon: AddBoxIcon,
        roles: [Role.Admin],
        showOnHomePage: true,
        View: CreateCharityPartnerView,
      },
      {
        title: 'View & Search Charity Partners',
        description: 'View and search all charity partners',
        path: '/charity/view',
        Icon: ListAltIcon,
        roles: [Role.Admin],
        showOnHomePage: true,
        View: ListCharitiesView,
      },
    ],
  },
  {
    name: 'Promo Codes',
    children: [
      {
        title: 'Create Promo Code',
        description: 'Create or generate promo codes',
        path: '/promo-codes/create',
        Icon: AddBoxIcon,
        roles: [Role.Admin],
        showOnHomePage: true,
        View: CreatePromoCodeView,
      },
      {
        title: 'View & Search Promo Codes',
        description: 'View and search all existing promo codes',
        path: '/promo-codes/view',
        Icon: ListAltIcon,
        roles: [Role.Admin],
        showOnHomePage: true,
        View: ListPromoCodesView,
      },
    ],
  },
  {
    name: 'Users',
    children: [
      {
        title: 'View & Search Users',
        description: 'View and search all users',
        path: '/users/view',
        Icon: ListAltIcon,
        roles: [Role.Admin],
        showOnHomePage: true,
        View: ListUsersView,
      },
    ],
  },
  {
    name: 'Lawyer Referrals',
    children: [
      {
        title: 'Create Lawyer Referral',
        description: 'Create new lawyer referral for drop-offs',
        path: '/lawyer/create',
        Icon: AddBoxIcon,
        roles: [Role.Admin],
        showOnHomePage: true,
        View: CreateLawyerView,
      },
    ],
  },
  {
    name: 'Analytics & Reports',
    children: [
      {
        title: 'Business Reports',
        description: 'Reports on business operations',
        path: '/analytics-reports/business-reports',
        Icon: SsidChartIcon,
        roles: [Role.Admin],
        showOnHomePage: false,
        View: BusinessReportView,
      },
      {
        title: 'RBC Report',
        description: 'Reports on RBC Referred Users',
        path: '/analytics-reports/rbc-report',
        Icon: SsidChartIcon,
        roles: [Role.Admin],
        showOnHomePage: true,
        View: RbcReportView,
      },
      {
        title: 'Charitable Gifts',
        description: 'Gifts left to charities in Wills',
        path: '/analytics-reports/charitable-gifts',
        Icon: SsidChartIcon,
        roles: [Role.Admin],
        showOnHomePage: false,
        View: CharitableGiftsView,
      },
      {
        title: 'Charity Partner Gifts',
        description: 'Gifts left to charity partners in Wills',
        path: '/analytics-reports/charity-partner',
        Icon: SsidChartIcon,
        roles: [Role.Admin],
        showOnHomePage: false,
        View: CharityPartnerGiftsView,
      },
      {
        title: 'Distribution Charity Gifts',
        description:
          'Gifts left to charity partners in distribution section of Wills',
        path: '/analytics-reports/charity-distribution',
        Icon: SsidChartIcon,
        roles: [Role.Admin],
        showOnHomePage: false,
        View: DistributionCharitiesGiftsView,
      },
      {
        title: 'Corporate Executor',
        description: 'Reports on corporate executor appointments',
        path: '/analytics-reports/corporate-executor',
        Icon: SsidChartIcon,
        roles: [Role.Admin],
        showOnHomePage: false,
        View: CorporateExecutorReportView,
      },
    ],
  },
]

export default views
