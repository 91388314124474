import { Children } from 'react'
import { Tabs as MuiTabs, Tab } from '@mui/material'
import { LargeNumberLike } from 'crypto'

interface TabProps {
  label: string
  value: string | LargeNumberLike
}

interface TabsProps {
  value: string | number
  onChange: (value: string | number) => void
  tabs: TabProps[]
  indicatorColor?: 'primary' | 'secondary'
  variant?: 'fullWidth' | 'scrollable' | 'standard'
  textColor?: 'inherit' | 'primary' | 'secondary'
  disabled?: boolean
}

const Tabs = ({
  value,
  onChange,
  tabs,
  indicatorColor = 'primary',
  variant = 'standard',
  textColor = 'primary',
  disabled = false,
}: TabsProps) => {
  return (
    <MuiTabs
      indicatorColor={indicatorColor}
      variant={variant}
      textColor={textColor}
      value={value}
      onChange={(_e, value) => {
        onChange(value)
      }}
    >
      {Children.toArray(
        tabs.map((tab) => (
          <Tab
            key={tab.value.toString()}
            label={tab.label}
            value={tab.value}
            disabled={disabled}
          />
        )),
      )}
    </MuiTabs>
  )
}

export default Tabs
