import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'

const DataGridToolbar = () => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end', pr: 2, pt: 1 }}>
      <GridToolbarExport
        csvOptions={{ fileName: 'AdminPanelCharityDataExport' }}
      />
    </GridToolbarContainer>
  )
}

export default DataGridToolbar
