import { ReactChild, ReactFragment, ReactPortal } from 'react'
import { Box, InputLabel, SxProps, Theme } from '@mui/material'

import { spreadSx } from 'utils'

const TitledWrapper = ({
  title,
  children,
  sx,
}: {
  title?: string
  children:
    | (ReactChild | ReactFragment | ReactPortal)[]
    | ReactChild
    | ReactFragment
    | ReactPortal
  sx?: SxProps
}) => {
  return (
    <Box
      sx={spreadSx([
        {
          border: 'solid 1px #CCCCCC',
          borderRadius: 1,
          px: 2,
          position: 'relative',
        },
        sx,
      ])}
    >
      {title && (
        <InputLabel
          sx={{
            background: (theme: Theme) =>
              theme.palette.mode === 'dark' ? '#1E1E1E' : '#FFFFFF',
            position: 'absolute',
            top: '-10px',
            left: '10px',
            fontSize: '0.8rem',
            px: 0.5,
          }}
        >
          {title}
        </InputLabel>
      )}
      {children}
    </Box>
  )
}

export default TitledWrapper
