import _cloneDeep from 'lodash/cloneDeep'

import { Lawyer } from 'utils/api/lawyer.types'

const cleanLawyerData = (data: Lawyer): Lawyer => {
  const clonedData = _cloneDeep(data)

  Object.keys(clonedData).forEach(untypedKey => {
    const key = untypedKey as keyof Lawyer

    if (clonedData[key] === undefined) {
      delete clonedData[key]
    }
  })

  if (clonedData.highlights && clonedData.highlights?.every(element => element === undefined)) delete clonedData.highlights

  if (clonedData.flatFeeData?.default && !clonedData.flatFeeData?.default.price)
    delete clonedData.flatFeeData

  if (clonedData.flatFeeData?.familyDisability &&
    !clonedData.flatFeeData?.familyDisability?.price) delete clonedData.flatFeeData.familyDisability

  if (clonedData.flatFeeData?.notRightForMe &&
    !clonedData.flatFeeData?.notRightForMe?.price) delete clonedData.flatFeeData.notRightForMe

  if (clonedData.flatFeeData?.spousalTrust &&
    !clonedData.flatFeeData?.spousalTrust?.price) delete clonedData.flatFeeData.spousalTrust


  return clonedData
}

export default cleanLawyerData