import { ReactText } from 'react'
import { Typography, SxProps, Theme } from '@mui/material'
import { spreadSx } from 'utils'

type Presets = 'tablehead'

const presets: { [key in Presets]: SxProps<Theme> } = {
  tablehead: { fontWeight: '500', fontSize: '0.875rem' },
}
interface TextProps {
  sx?: SxProps
  gutterBottom?: boolean
  children: ReactText | ReactText[]
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify'
  variant?:
    | 'inherit'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body'
    | 'subtitle1'
    | 'subtitle2'
  component?: 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  preset?: Presets
}

const Text = ({
  sx,
  preset,
  children,
  gutterBottom,
  align = 'left',
  variant = 'body',
  component = 'span',
}: TextProps) => {
  return (
    <Typography
      align={align}
      gutterBottom={gutterBottom}
      variant={variant === 'body' ? 'body1' : variant}
      component={component}
      sx={spreadSx([preset ? presets[preset] : undefined, sx])}
    >
      {children}
    </Typography>
  )
}

export default Text
