import { useQuery } from 'react-query'

import { getMe } from 'utils/api/user.api'
import { UserEndpoint } from 'utils/api/user.types'

const useGetMe = () => useQuery({
  queryKey: UserEndpoint.Me,
  queryFn: () => getMe(),
  retry: false,
  staleTime: 60000, // 1 minute
  refetchOnWindowFocus: true,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetMe