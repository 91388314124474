import { Backdrop, CircularProgress, Typography } from '@mui/material'

interface LoadingOverlayProps {
  statusMessage?: string
  disableShrink?: boolean
}

const LoadingOverlay = (props: LoadingOverlayProps) => {
  const { statusMessage, disableShrink = false } = props

  return (
    <Backdrop
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        color: '#ffffff',
      }}
      open
    >
      <CircularProgress
        disableShrink={disableShrink}
        sx={{ color: 'inherit' }}
      />

      {statusMessage && <Typography>{statusMessage}</Typography>}
    </Backdrop>
  )
}

export default LoadingOverlay
