import { useContext } from 'react'
import { Box } from '@mui/material'
import _capitalize from 'lodash/capitalize'

import { Text } from 'components'
import { UserContext, dates } from 'utils'

const QuestionnaireDetails = () => {
  const { data } = useContext(UserContext) || {}

  return data ? (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridTemplateRows: '1fr',
        rowGap: 1,
        mx: 2,
        '& > *': {
          borderBottom: 'solid 1px #CCCCCC',
          pb: 1,
        },
      }}
    >
      <Text>ID:</Text>
      <Text sx={{ color: 'text.secondary' }}>{data.questionnaire._id}</Text>

      <Text>Token:</Text>
      <Text sx={{ color: 'text.secondary' }}>{data.questionnaire.token}</Text>

      <Text>Created At:</Text>
      <Text sx={{ color: 'text.secondary' }}>
        {dates.format(data.questionnaire.createdAt)}
      </Text>

      <Text>Answer Store Updated Since Generate:</Text>
      <Text sx={{ color: 'text.secondary' }}>
        {data.questionnaire.answerStoreUpdatedSinceGenerate ? 'Yes' : 'No'}
      </Text>

      <Text>Social Answer Store Updated Since Generate:</Text>
      <Text sx={{ color: 'text.secondary' }}>
        {data.questionnaire.socialAnswerStoreUpdatedSinceGenerate
          ? 'Yes'
          : 'No'}
      </Text>

      <Text>Questionnaires Started:</Text>
      <Text sx={{ color: 'text.secondary' }}>
        {data.questionnaire.questionnairesStarted
          .map((title) => _capitalize(title))
          .join(', ')}
      </Text>
    </Box>
  ) : (
    <></>
  )
}

export default QuestionnaireDetails
