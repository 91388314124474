import {
  Box,
  Paper,
  Theme,
  Button,
  SxProps,
  Divider,
  useMediaQuery,
} from '@mui/material'
import _isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import PercentIcon from '@mui/icons-material/Percent'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import {
  regex,
  dates,
  useFormData,
  useKeyPress,
  removeUndefinedValues,
  checkAllPropertiesTrue,
} from 'utils'
import { PromoCode, Referrer } from 'utils/api/promoCode.api'
import {
  Tabs,
  InputField,
  ToggleButtons,
  DateTimePicker,
  Text,
} from 'components'

interface FormMetaData {
  type: 'generate' | 'manual'
  useExpiry: boolean
  lastExpiry: string
  useRef: boolean
  refData: Referrer
}

interface PromoCodeFormProps {
  initialPromoCodeData?: PromoCode
  onSubmit: (data: PromoCode) => void
  key?: string
  sx?: SxProps
}

const PromoCodeForm = (props: PromoCodeFormProps) => {
  const {
    initialPromoCodeData = {
      isPercent: true,
      amount: 0,
      prefix: undefined,
      code: undefined,
      expiryDate: undefined,
      oneTimeUse: false,
      isActive: true,
      numOfCodes: undefined,
      referrer: undefined,
      description: undefined,
    },
    onSubmit,
    sx,
  } = props

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const enterKeyPress = useKeyPress('Enter')
  const isExistingPromoCode = !!initialPromoCodeData._id

  const rowStyle: SxProps = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    columnGap: 2,
    rowGap: 2,
  }

  const columnStyle: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    width: isMobile ? '100%' : '50%',
  }

  const initialFormMetaData: FormMetaData = isExistingPromoCode
    ? {
        type: 'manual',
        useExpiry: true,
        lastExpiry: initialPromoCodeData.expiryDate || dates.getNowPstString(),
        useRef: !!initialPromoCodeData.referrer?.ref,
        refData: {
          ref: initialPromoCodeData.referrer?.ref || '',
          reftag: initialPromoCodeData.referrer?.reftag || '',
        },
      }
    : {
        type: 'manual',
        useExpiry: false,
        lastExpiry: dates.getNowPstString(),
        useRef: false,
        refData: { ref: '', reftag: '' },
      }

  const initialValidationData = {
    amount: false,
    code: false,
    expiryDate: false,
    numOfCodes: false,
    ref: false,
    reftag: false,
    description: false,
  }

  const [displayValidation, setDisplayValidation] = useState(false)
  const [isValid, setIsValid] = useState(initialValidationData)

  const [formData, updateFormData] =
    useFormData<PromoCode>(initialPromoCodeData)
  const [formMetaData, updateFormMetaData] =
    useFormData<FormMetaData>(initialFormMetaData)

  const resetState = () => {
    updateFormData(initialPromoCodeData)
    updateFormMetaData(initialFormMetaData)
    setIsValid(initialValidationData)
    setDisplayValidation(false)
  }

  useEffect(() => {
    if (enterKeyPress) {
      if (checkAllPropertiesTrue(formData)) {
        onSubmit(removeUndefinedValues(formData))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterKeyPress])

  useEffect(() => {
    if (formMetaData.useRef) {
      updateFormData({ referrer: formMetaData.refData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMetaData.refData, formMetaData.useRef])

  useEffect(() => {
    const amount =
      (formData.isPercent && formData.amount > 0 && formData.amount <= 100) ||
      (!formData.isPercent && formData.amount > 0)

    const code = formMetaData.type === 'generate' || !!formData.code

    const expiryDate =
      formData.expiryDate === undefined ||
      dates.isDateInFuture(formData.expiryDate)

    const numOfCodes = !!(
      formMetaData.type === 'manual' ||
      (formMetaData.useRef && formData.numOfCodes === 1) ||
      (!formMetaData.useRef && formData.numOfCodes && formData.numOfCodes >= 1)
    )

    const ref = !formMetaData.useRef || !!formData.referrer?.ref

    const reftag = !formMetaData.useRef || !!formData.referrer?.reftag

    const description = !formMetaData.useRef || !!formData.description

    setIsValid({
      amount,
      code,
      expiryDate,
      numOfCodes,
      ref,
      reftag,
      description,
    })
  }, [formData, formMetaData])

  return (
    <Paper
      sx={{
        flexGrow: 1,
        p: 2,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          columnGap: 2,
        }}
      >
        <Box sx={columnStyle}>
          {!isExistingPromoCode && (
            <Tabs
              value={formMetaData.type}
              onChange={(type) => {
                if (type === 'manual') {
                  updateFormData({ numOfCodes: undefined })
                } else {
                  updateFormData({ code: undefined })
                }
                updateFormMetaData({ type })
              }}
              tabs={[
                {
                  label: 'Manually Create',
                  value: 'manual',
                },
                {
                  label: 'Generate',
                  value: 'generate',
                },
              ]}
            />
          )}

          {formMetaData.type === 'manual' && (
            <InputField
              required={formMetaData.type === 'manual'}
              isValid={isValid.code}
              displayValidation={displayValidation}
              label="Promo Code"
              value={formData.code}
              onChange={(code) => updateFormData({ code })}
              transformation="uppercase"
              inputValidation={regex.PROMO_CODE}
            />
          )}

          {formMetaData.type === 'generate' && (
            <Box sx={rowStyle}>
              <InputField
                required={formMetaData.type === 'generate'}
                fullWidth
                isValid={isValid.numOfCodes}
                displayValidation={displayValidation}
                type="number"
                label="Number of Codes to Generate"
                value={formData.numOfCodes?.toString()}
                onChange={(numOfCodes) =>
                  updateFormData({
                    numOfCodes: numOfCodes ? parseInt(numOfCodes) : undefined,
                  })
                }
              />
              <InputField
                fullWidth
                label="Prefix"
                value={formData.prefix}
                onChange={(prefix) => updateFormData({ prefix })}
              />
            </Box>
          )}

          {isMobile && <Divider />}

          <Box sx={{ ...rowStyle, flexDirection: 'row' }}>
            <ToggleButtons
              value={formData.isPercent}
              onChange={(isPercent) => updateFormData({ isPercent })}
              buttons={[
                {
                  value: false,
                  label: <AttachMoneyIcon />,
                },
                {
                  value: true,
                  label: <PercentIcon />,
                },
              ]}
            />
            <InputField
              required
              label="Discount amount"
              type="number"
              isValid={isValid.amount}
              displayValidation={displayValidation}
              value={formData.amount.toString()}
              fullWidth
              onChange={(amount) =>
                updateFormData({
                  amount: amount ? parseFloat(amount) : 0,
                })
              }
              startAdornment={!formData.isPercent ? '$' : undefined}
              endAdornment={formData.isPercent ? '%' : undefined}
              shrinkLabel
            />
          </Box>

          {isMobile && <Divider />}

          <InputField
            required={formMetaData.useRef}
            isValid={isValid.description}
            displayValidation={displayValidation}
            label="Description"
            value={formData.description}
            onChange={(description) => updateFormData({ description })}
          />

          {isMobile && <Divider />}
        </Box>

        <Box
          sx={{
            ...columnStyle,
            mt: isMobile ? 2 : isExistingPromoCode ? 0 : 8,
          }}
        >
          <ToggleButtons
            fullWidth
            value={formData.oneTimeUse}
            onChange={(oneTimeUse) => updateFormData({ oneTimeUse })}
            buttons={[
              {
                value: false,
                label: 'Unlimited Uses',
              },
              {
                value: true,
                label: 'One Time Use',
              },
            ]}
          />

          {isMobile && <Divider />}

          <Box sx={rowStyle}>
            <ToggleButtons
              fullWidth={isMobile}
              value={formMetaData.useExpiry}
              onChange={(useExpiry) => {
                updateFormMetaData({ useExpiry })
                if (!useExpiry) {
                  updateFormData({ expiryDate: undefined })
                } else {
                  updateFormData({ expiryDate: formMetaData.lastExpiry })
                }
              }}
              buttons={[
                {
                  value: false,
                  label: 'No Expiry',
                },
                {
                  value: true,
                  label: 'Expiry',
                },
              ]}
            />
            <DateTimePicker
              required={formMetaData.useExpiry}
              fullWidth
              value={
                formData.expiryDate
                  ? dates.stringToDate(formData.expiryDate)
                  : dates.stringToDate(formMetaData.lastExpiry)
              }
              onChange={(expiryDate) => {
                if (expiryDate) {
                  updateFormMetaData({
                    lastExpiry: dates.dateToString(expiryDate),
                  })
                  updateFormData({ expiryDate: dates.dateToString(expiryDate) })
                } else {
                  updateFormData({ expiryDate: undefined })
                }
              }}
              label="Expiry (PST)"
              disabled={!formMetaData.useExpiry}
              isValid={isValid.expiryDate}
              displayValidation={displayValidation}
            />
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                updateFormMetaData({ lastExpiry: dates.getNowPstString() })
                updateFormData({ expiryDate: dates.getNowPstString() })
              }}
              disabled={!formMetaData.useExpiry}
            >
              Set to Now (PST)
            </Button>
          </Box>

          {isMobile && <Divider />}

          <Box sx={rowStyle}>
            <ToggleButtons
              fullWidth={isMobile}
              value={formMetaData.useRef}
              onChange={(useRef) => {
                updateFormMetaData({ useRef })
                if (!useRef) {
                  updateFormData({ referrer: undefined })
                } else {
                  updateFormData({ referer: formMetaData.refData })
                }
              }}
              buttons={[
                {
                  value: false,
                  label: 'No Reftags',
                },
                {
                  value: true,
                  label: 'Use Reftags',
                },
              ]}
            />
            <InputField
              required={formMetaData.useRef}
              fullWidth
              label="Ref"
              value={formMetaData.refData.ref}
              onChange={(ref) => {
                updateFormMetaData({
                  refData: { ...formMetaData.refData, ref },
                })
              }}
              disabled={!formMetaData.useRef}
              isValid={isValid.ref}
              displayValidation={displayValidation}
            />
            <InputField
              required={formMetaData.useRef}
              fullWidth
              label="Reftag"
              value={formMetaData.refData.reftag}
              onChange={(reftag) => {
                updateFormMetaData({
                  refData: { ...formMetaData.refData, reftag },
                })
              }}
              disabled={!formMetaData.useRef}
              isValid={isValid.reftag}
              displayValidation={displayValidation}
            />
          </Box>

          {isMobile && <Divider />}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: 2,
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          disabled={_isEqual(formData, initialPromoCodeData)}
          onClick={() => {
            setDisplayValidation(true)
            if (checkAllPropertiesTrue(isValid)) {
              onSubmit(removeUndefinedValues(formData))
            }
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          onClick={resetState}
          disabled={_isEqual(formData, initialPromoCodeData)}
        >
          Reset
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {initialPromoCodeData._id && !isMobile && (
          <Text variant="subtitle2" sx={{ alignSelf: 'center' }}>
            ID: {initialPromoCodeData._id}
          </Text>
        )}
      </Box>
    </Paper>
  )
}

export default PromoCodeForm
