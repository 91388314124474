import moment from 'moment'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Box, Paper } from '@mui/material'
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker'
import { Parser } from '@json2csv/plainjs'

import { Range } from 'react-date-range'
import { useGetBusinessReportData } from 'utils'
import { ProcessedData } from './rbcReportWorker'
import DateRangePicker from 'components/DateRangePicker/_DateRangePicker'
import { LoadingOverlay } from 'components'

const createWorker = createWorkerFactory(() => import('./rbcReportWorker'))

const Row = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <Paper
    elevation={8}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      rowGap: '1rem',
      columnGap: '1rem',
      justifyContent: 'space-evenly',
      p: 1,
    }}
  >
    {children}
  </Paper>
)

const RbcReportView = () => {
  const reportWorker = useWorker(createWorker)

  const [range, setRange] = useState<Range>({
    startDate: moment().date(0).startOf('month').toDate(),
    endDate: moment().date(0).endOf('month').toDate(),
    key: 'selection',
  })
  const [processedData, setProcessedData] = useState<ProcessedData | null>(null)

  const { data: rawUserData, refetch: fetchFreshUserData } =
    useGetBusinessReportData({
      params: { start: '2020-01-01', end: moment().format('YYYY-MM-DD') },
    })

  useEffect(() => {
    fetchFreshUserData()
  }, [fetchFreshUserData])

  useEffect(() => {
    setProcessedData(null)

    const process = async () => {
      const workerData = await reportWorker.processData(
        range,
        rawUserData ?? [],
      )

      setProcessedData(workerData)
    }

    process()
  }, [range, rawUserData, reportWorker])

  useEffect(() => {
    console.log('processedData', processedData)
  }, [processedData])

  const isLoading = useMemo(
    () => processedData === null || !rawUserData || rawUserData.length === 0,
    [processedData, rawUserData],
  )

  return isLoading ? (
    <LoadingOverlay
      disableShrink
      statusMessage={
        !rawUserData || rawUserData.length === 0
          ? 'Loading data'
          : 'Processing data'
      }
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
      }}
    >
      <Box>
        <DateRangePicker
          label="Reporting Period"
          range={range}
          setRange={setRange}
          sx={{
            width: { xs: undefined, md: 430 },
          }}
        />
      </Box>
      <Row>Data</Row>
    </Box>
  )
}

export default RbcReportView
