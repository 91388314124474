import {
  Box,
  List,
  Theme,
  Drawer,
  Switch,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useMediaQuery,
  ListItemButton,
  CircularProgress,
  FormControlLabel,
  Divider as MuiDivider,
} from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'

import views from 'config/views'
import { hasRoles, ThemeContext, useRoles } from 'utils'

const Divider = () => <MuiDivider sx={{ my: 1 }} />

interface MenuItemProps {
  icon: object
  title: string
  link: string
  setShowDrawer: Function
}

const MenuItem = ({ icon, title, link, setShowDrawer }: MenuItemProps) => {
  const navigate = useNavigate()

  return (
    <ListItem disablePadding key={link}>
      <ListItemButton
        onClick={() => {
          setShowDrawer(false)
          navigate(link)
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  )
}

interface MainMenuProps {
  show: boolean
  setShow: Function
  topMenuBarHeight?: number
}

const desktopDrawerWidth = 290

const MainMenu = ({ show, setShow, topMenuBarHeight }: MainMenuProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { data: userRoles, isLoading: isLoadingRoles } = useRoles()
  const { toggleColorMode, mode: colourMode } = useContext(ThemeContext) || {}

  return (
    <Drawer
      open={show}
      sx={{
        width: isMobile ? '100%' : desktopDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : desktopDrawerWidth,
          boxSizing: 'border-box',
        },
      }}
      onBackdropClick={() => {
        setShow(false)
      }}
    >
      <Box
        sx={{
          mt: `${topMenuBarHeight}px`,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {isLoadingRoles ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
            }}
          >
            <CircularProgress sx={{ alignSelf: 'center' }} />
          </Box>
        ) : (
          <List>
            <MenuItem
              title="Home"
              icon={<HomeIcon />}
              link="/"
              setShowDrawer={setShow}
            />
            <Divider />

            {React.Children.toArray(
              views.map((section) => {
                const childRoles = section.children.flatMap(
                  (child) => child.roles,
                )

                return hasRoles({
                  userRoles: userRoles ?? [],
                  requiredRoles: childRoles,
                }) ? (
                  <>
                    <ListSubheader
                      sx={{
                        background: 'none',
                      }}
                    >
                      {section.name}
                    </ListSubheader>
                    {React.Children.toArray(
                      section.children
                        .filter((child) =>
                          hasRoles({
                            userRoles: userRoles ?? [],
                            requiredRoles: child.roles,
                          }),
                        )
                        .map((child) => {
                          const { title, Icon, path } = child
                          return (
                            <MenuItem
                              title={title}
                              icon={<Icon />}
                              link={path}
                              setShowDrawer={setShow}
                            />
                          )
                        }),
                    )}
                  </>
                ) : (
                  <></>
                )
              }),
            )}
          </List>
        )}

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <FormControlLabel
            sx={{ alignSelf: 'flex-end' }}
            control={
              <Switch
                sx={{ m: 1 }}
                checked={colourMode === 'dark'}
                onChange={() => {
                  if (toggleColorMode) toggleColorMode()
                }}
              />
            }
            label={colourMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
          />
        </Box>
      </Box>
    </Drawer>
  )
}

export default MainMenu
