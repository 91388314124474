import { useQuery } from 'react-query'

import { queryClient } from 'utils'
import { getAuth } from 'utils/api/user.api'
import { UserEndpoint } from 'utils/api/user.types'

const useAuth = () => useQuery({
  queryKey: UserEndpoint.ID,
  queryFn: getAuth,
  retry: false,
  refetchOnWindowFocus: true,
  onError: () => {
    queryClient.invalidateQueries()
    window.location.href = '/login'
  },
})

export default useAuth