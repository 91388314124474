import { Box, Theme } from '@mui/material'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import views from 'config/views'
import { Role } from 'utils/api/role.api'
import { isOnlyUser, useRoles, hasRoles } from 'utils'
import {
  HomeView,
  LoginView,
  PageNotFoundView,
  UserDetailsView,
  CharityPartnerDetailsView,
} from 'views'
import { LoadingOverlay, MainMenu, TopMenuBar } from 'components'

const viewMargin = 10

const PrivateView = ({
  role,
  children,
}: {
  role: Role | Role[]
  children: ReactElement
}) => {
  const { data: userRoles } = useRoles()

  const ComponentToRender = useCallback(() => {
    if (userRoles) {
      if (isOnlyUser(userRoles)) {
        // Roles have loaded, but user only has 'user' permission so they shouldn't be here at all
        return <Navigate to={'/login'} />
      } else if (hasRoles({ userRoles, requiredRoles: role })) {
        // Roles have loaded and user has permission to view route
        return children
      } else {
        // Roles have loaded and user does not have permission to view route
        return <Navigate to={'/'} />
      }
    } else {
      // Roles are loading
      return <LoadingOverlay statusMessage="Verifying user role" />
    }
  }, [children, role, userRoles])

  return <ComponentToRender />
}

const App = () => {
  const [isMainMenuDisplayed, setIsMainMenuDisplayed] = useState(false)
  const topMenuBarRef = useRef<HTMLDivElement>(null)
  const [topMenuBarHeight, setTopMenuBarHeight] = useState(
    topMenuBarRef.current?.offsetHeight,
  )

  useEffect(() => {
    const onWindowResize = () => {
      setTopMenuBarHeight(topMenuBarRef.current?.offsetHeight)
    }
    window.addEventListener('resize', onWindowResize)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  useEffect(() => {
    setTopMenuBarHeight(topMenuBarRef.current?.offsetHeight)
  }, [topMenuBarRef])

  return (
    <Box
      sx={{
        backgroundColor: (theme: Theme) => theme.palette.background.default,
      }}
    >
      <MainMenu
        show={isMainMenuDisplayed}
        setShow={setIsMainMenuDisplayed}
        topMenuBarHeight={topMenuBarHeight}
      />
      <Box>
        <TopMenuBar
          ref={topMenuBarRef}
          setIsMainMenuDisplayed={setIsMainMenuDisplayed}
          isMainMenuDisplayed={isMainMenuDisplayed}
        />
        <Box
          sx={{
            p: `${viewMargin}px`,
            pt: `${(topMenuBarHeight || 0) + viewMargin}px`,
            minHeight: `calc(100vh - ${
              (topMenuBarHeight || 0) + viewMargin * 2
            }px)`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Routes>
            {/* Home */}
            <Route
              path="/"
              element={
                <PrivateView role={Role.Admin}>
                  <HomeView />
                </PrivateView>
              }
            />

            {views.flatMap((parent) =>
              parent.children.map((view) => {
                const { path, View, roles } = view
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <PrivateView role={roles}>
                        <View />
                      </PrivateView>
                    }
                  />
                )
              }),
            )}

            {/* Non-standard routes */}
            <Route
              path="/users/details/:userId"
              element={
                <PrivateView role={Role.Admin}>
                  <UserDetailsView />
                </PrivateView>
              }
            />
            <Route
              path="/charity/details/:id"
              element={
                <PrivateView role={Role.Admin}>
                  <CharityPartnerDetailsView />
                </PrivateView>
              }
            />

            {/* Other */}
            <Route path="/login" element={<LoginView />} />
            <Route path="*" element={<PageNotFoundView />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  )
}

export default App
