import { useQuery } from 'react-query'

import { getUsers } from 'utils/api/user.api'
import { GetUsersParams, UserEndpoint } from 'utils/api/user.types'

const useGetUsers = (params: GetUsersParams) => useQuery({
  queryKey: [UserEndpoint.User, params],
  queryFn: () => getUsers(params),
  retry: false,
  staleTime: 60000, // 1 minute
  refetchOnWindowFocus: false,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetUsers