import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Paper, useMediaQuery, Theme, TextField, Button } from '@mui/material'

import { login } from 'utils/api/user.api'
import { getRoles } from 'utils/api/role.api'
import { LoadingOverlay, Text } from 'components'
import { UserEndpoint } from 'utils/api/user.types'
import { isOnlyUser, queryClient, useAuth, useKeyPress } from 'utils'

const LoginView = () => {
  const navigate = useNavigate()
  const { data: isAuth, isLoading: isLoadingAuth } = useAuth()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const enterKeyPress = useKeyPress('Enter')

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [working, setWorking] = useState(false)
  const [displayValidation, setDisplayValidation] = useState(false)

  useEffect(() => {
    if (enterKeyPress) {
      onLoginClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterKeyPress])

  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [isAuth, navigate])

  const onLoginClick = () => {
    setDisplayValidation(true)

    if (email !== '' && password !== '') {
      setWorking(true)
      setDisplayValidation(false)

      login({ email, password })
        .then(getRoles)
        .then((userRoles) => {
          if (isOnlyUser(userRoles)) {
            // if the user only has user privilege, deny access
            setError('Access denied')
            setPassword('')
            setWorking(false)
          } else {
            // user has additional roles, allow access
            queryClient.invalidateQueries(UserEndpoint.ID)
            navigate('/')
          }
        })
        .catch((e) => {
          setError('Incorrect username or password')
          setPassword('')
          setWorking(false)
        })
    } else {
      setError('')
    }
  }

  return isLoadingAuth ? (
    <LoadingOverlay statusMessage="Checking auth status" />
  ) : (
    <Paper
      elevation={3}
      sx={{
        width: isMobile ? undefined : '35rem',
        mt: isMobile ? '8rem' : '10rem',
        mx: isMobile ? 1 : 'auto',
        display: 'flex',
        flexDirection: 'column',
        p: isMobile ? 1 : 2,
        rowGap: 2,
      }}
    >
      <Text variant="h6" align="center">
        Log in using your Epilogue account
      </Text>
      {error && (
        <Text align="center" sx={{ color: 'error.main' }}>
          {error}
        </Text>
      )}
      <TextField
        label="Email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={displayValidation && email === ''}
        disabled={working}
      />
      <TextField
        label="Password"
        type="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={displayValidation && password === ''}
        disabled={working}
      />
      <Button variant="contained" onClick={onLoginClick} disabled={working}>
        Log In
      </Button>
    </Paper>
  )
}

export default LoginView
