import { Paper } from '@mui/material'
import { useContext } from 'react'
import { ValueFormat } from '@nivo/core'
import { ResponsiveBar, BarDatum, BarLegendProps } from '@nivo/bar'

import { ThemeContext } from 'utils'

interface BarChartProps {
  height: string
  maxValue?: number | 'auto'
  leftLabel: string
  data: BarChartData[]
  valueFormat?: ValueFormat<number>
  isInteractive?: boolean
  legend?: string[] | { [key: string]: { color: string } }
  legendVariant?: 'top' | 'rightCorner'
  groupMode?: 'stacked' | 'grouped'
  layout?: 'horizontal' | 'vertical'
}

export interface BarChartData {
  index: string
  [key: string]: number | string
}

const baseLegendProps = {
  dataFrom: 'indexes',
  justify: false,
  translateX: 0,
  itemWidth: 100,
  itemHeight: 20,
  symbolSize: 20,
} as BarLegendProps

const legendProps: { [key: string]: BarLegendProps } = {
  top: {
    ...baseLegendProps,
    direction: 'row',
    anchor: 'top',
    translateY: -30,
    itemsSpacing: 50,
    itemDirection: 'left-to-right',
  },
  rightCorner: {
    ...baseLegendProps,
    direction: 'column',
    anchor: 'top-right',
    itemsSpacing: 10,
    translateY: 0,
    itemDirection: 'right-to-left',
  },
}

const BarChart = ({
  data,
  height,
  maxValue = 'auto',
  leftLabel,
  valueFormat,
  isInteractive = false,
  legend,
  groupMode = 'grouped',
  legendVariant = 'top',
  layout = 'vertical',
}: BarChartProps) => {
  const { mode: colourMode } = useContext(ThemeContext) || {}

  const baseColors = ['#E37645', '#3f396e']

  const keys = [
    ...new Set(
      data.map((d) => Object.keys(d).filter((key) => key !== 'index')).flat(),
    ),
  ]

  return (
    <Paper
      elevation={5}
      sx={{
        height,
      }}
    >
      <ResponsiveBar
        tooltipLabel={({ id }) => `${id}`}
        data={data as unknown[] as BarDatum[]}
        keys={keys}
        indexBy="index"
        groupMode={groupMode}
        margin={{ top: 50, right: 50, bottom: 50, left: 110 }}
        padding={0.1}
        valueScale={{ type: 'linear' }}
        colors={({ data, id }) =>
          !legend || Array.isArray(legend) || !legend[id]?.color
            ? baseColors[Object.keys(data).indexOf(`${id}`)]
            : legend[id]?.color
        }
        isInteractive={isInteractive}
        maxValue={maxValue}
        animate={true}
        axisTop={null}
        axisRight={null}
        layout={layout}
        legends={
          legend
            ? [
                {
                  ...legendProps[legendVariant],
                  data: keys.map((key, i) => ({
                    id: key,
                    label: key,
                    color: Array.isArray(legend)
                      ? baseColors[i]
                      : legend[key].color,
                  })),
                },
              ]
            : undefined
        }
        valueFormat={valueFormat}
        theme={{
          textColor: '#FFFFFF',
          legends: {
            text: {
              fill: colourMode === 'dark' ? '#FFFFFF' : '#000000',
            },
          },
          axis: {
            legend: {
              text: {
                fill: colourMode === 'dark' ? '#FFFFFF' : '#000000',
              },
            },
            ticks: {
              text: {
                fill: colourMode === 'dark' ? '#FFFFFF' : '#000000',
              },
            },
          },
          tooltip: {
            basic: {
              color: '#000000',
            },
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: leftLabel,
          legendPosition: 'middle',
          legendOffset: -85,
          format: valueFormat,
        }}
      />
    </Paper>
  )
}

export default BarChart
