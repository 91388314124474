import { GridRowsProp } from '@mui/x-data-grid'

import { format } from 'utils'
import { CharityPartnersResponse } from 'utils/api/data.types'

const useAllDataGridRows = (
  data?: CharityPartnersResponse[],
): GridRowsProp => {
  if (data) {
    return [...data]
      .map((gift, index) => ({
        id: index,
        ...gift,
        amount: gift.isPercent
          ? `${gift.amount}%`
          : format.currency(gift.amount),
      }))
  } else {
    return []
  }
}

export default useAllDataGridRows