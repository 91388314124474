import {
  Box,
  Card,
  Alert,
  Theme,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  CardActions,
  CardContent,
  FormControl,
  useMediaQuery,
  Paper,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

import { copyText, useDebounce } from 'utils'
import { Text } from 'components'

import {
  CtaStyles,
  EmbedObject,
  PreviewViews,
  ImageArrangements,
  MobileImageArrangements,
} from './charityEmbedGeneratorView.types'
import charityEmbedImages from './charityEmbedImages'

const defaultEmbedImageUrl = charityEmbedImages[0].url

const embedJavascriptSnippet =
  '<script>window.addEventListener("message",(event)=>{const{data}=event;if(data?.source==="epilogue_embed"){if(data.type==="resize"){document.querySelector("#epi_iframe").height=data.value}}})</script>'

const embedIframeSnippet = (embedUrl: string): string =>
  `<iframe name="epi-chairity-embed" id="epi_iframe" width="100%" src="${embedUrl}"></iframe>`

const CharityEmbedGeneratorView = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const inputWrapperStyles = {
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? '100%' : '50%',
    rowGap: 2,
    justifyContent: 'space-between',
  }

  const [imageSelectOption, setImageSelectOption] =
    useState(defaultEmbedImageUrl)

  const [embedData, setEmbedData] = useState<EmbedObject>({
    ctaColour: '#E37645',
    ctaTextColour: '#FFFFFF',
    bgColour: '#FAF5EC',
    smallHeading: 'Dolore magna aliqua',
    heading: 'Lorem ipsum dolor sit amet',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    ctaDestination: '#',
    ctaText: 'Start My Will',
    imageUrl: defaultEmbedImageUrl,
    smallHeadingFont: 'Roboto',
    headerFont: 'Mulish',
    bodyFont: 'Frank Ruhl Libre',
    imageArrangement: ImageArrangements.Right,
    mobileImageArrangement: MobileImageArrangements.Top,
    ctaStyle: CtaStyles.Rounded,
    textColour: '#000000',
    ctaHoverColour: '#E6865B',
    headerWeight: '400',
    bodyWeight: '400',
    smallHeadingWeight: '500',
  })

  const debouncedEmbedData = useDebounce<EmbedObject>(embedData, 500)

  const generateEncodedUrl = useCallback(() => {
    let newUrl = 'https://epiloguewills.com/charity/embed/v1/embed.html?'
    for (const key of Object.keys(debouncedEmbedData)) {
      newUrl += `${key}=${encodeURIComponent(
        debouncedEmbedData[key as keyof EmbedObject],
      )}&`
    }

    return newUrl.slice(0, -1)
  }, [debouncedEmbedData])

  const [encodedUrl, setEncodedUrl] = useState(generateEncodedUrl)

  const [iframeHeight, setIframeHeight] = useState(0)
  const [iframeWidth, setIframeWidth] = useState('100%')
  const [previewView, setPreviewView] = useState<PreviewViews>(
    PreviewViews.Desktop,
  )

  useEffect(() => {
    // We adjust the iframe width here and at the bottom to
    // force the resize event in the iframe code to fire,
    // fixing a weird display issue
    setIframeWidth(
      previewView === PreviewViews.Desktop || isMobile ? '99%' : '349px',
    )

    setEncodedUrl(generateEncodedUrl)

    // See comment above
    setIframeWidth(
      previewView === PreviewViews.Desktop || isMobile
        ? PreviewViews.Desktop
        : PreviewViews.Mobile,
    )
  }, [debouncedEmbedData, generateEncodedUrl, isMobile, previewView])

  const onMessageReceivedFromIframe = (event: MessageEvent) => {
    const { data } = event
    if (data?.source === 'epilogue_embed') {
      if (data.type === 'resize') {
        setIframeHeight(data.value)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe)
    return () =>
      window.removeEventListener('message', onMessageReceivedFromIframe)
  }, [])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: 2,
        }}
      >
        <Alert severity="info" sx={{ flexGrow: 1 }}>
          Preview will update when you stop typing. Generated URL and embed is
          below the form.
        </Alert>
        <Button
          variant="outlined"
          onClick={() =>
            setPreviewView(
              previewView === PreviewViews.Desktop
                ? PreviewViews.Mobile
                : PreviewViews.Desktop,
            )
          }
          sx={{ display: isMobile ? 'none' : 'block' }}
        >
          {previewView === PreviewViews.Desktop
            ? 'Switch to mobile preview'
            : 'Switch to desktop preview'}
        </Button>
      </Box>
      <Box
        sx={{
          my: 2,
          textAlign: 'center',
          maxWidth: '100rem',
          mx: 'auto',
        }}
      >
        <Box
          component="iframe"
          title="epi-embed"
          width={iframeWidth}
          height={iframeHeight}
          sx={{ border: 'none' }}
          src={encodedUrl}
        />
      </Box>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          columnGap: 2,
          rowGap: 2,
          p: 2,
        }}
      >
        <Box sx={inputWrapperStyles}>
          <TextField
            label="Small Heading"
            variant="outlined"
            value={embedData.smallHeading}
            onChange={(e) => {
              setEmbedData({ ...embedData, smallHeading: e.target.value })
            }}
          />
          <TextField
            label="Heading"
            variant="outlined"
            multiline
            rows={2}
            required
            value={embedData.heading}
            onChange={(e) => {
              setEmbedData({ ...embedData, heading: e.target.value })
            }}
          />
          <TextField
            label="Body"
            variant="outlined"
            multiline
            rows={3}
            required
            value={embedData.body}
            onChange={(e) => {
              setEmbedData({ ...embedData, body: e.target.value })
            }}
          />
          <TextField
            label="CTA Destination (url)"
            variant="outlined"
            required
            value={embedData.ctaDestination}
            onChange={(e) => {
              setEmbedData({ ...embedData, ctaDestination: e.target.value })
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="image-url-select-label">Image</InputLabel>
            <Select
              labelId="image-url-select-label"
              label="Image"
              value={imageSelectOption}
              onChange={(e) => {
                if (e.target.value === 'custom') {
                  setImageSelectOption(e.target.value)
                  setEmbedData({ ...embedData, imageUrl: '' })
                } else {
                  setImageSelectOption(e.target.value)
                  setEmbedData({ ...embedData, imageUrl: e.target.value })
                }
              }}
            >
              {React.Children.toArray(
                [
                  ...charityEmbedImages,
                  { name: 'Custom URL', url: 'custom' },
                ].map((image) => (
                  <MenuItem value={image.url}>{image.name}</MenuItem>
                )),
              )}
            </Select>
          </FormControl>
          {imageSelectOption === 'custom' && (
            <TextField
              label="Custom Image Url"
              variant="outlined"
              value={embedData.imageUrl}
              onChange={(e) => {
                setEmbedData({ ...embedData, imageUrl: e.target.value })
              }}
            />
          )}
          <TextField
            label="Small Heading Font (Google font)"
            variant="outlined"
            value={embedData.smallHeadingFont}
            onChange={(e) => {
              setEmbedData({ ...embedData, smallHeadingFont: e.target.value })
            }}
          />
        </Box>
        <Box sx={inputWrapperStyles}>
          <TextField
            label="Small Heading Weight"
            variant="outlined"
            value={embedData.smallHeadingWeight}
            onChange={(e) => {
              setEmbedData({ ...embedData, smallHeadingWeight: e.target.value })
            }}
          />
          <TextField
            label="Heading Font (Google font)"
            variant="outlined"
            value={embedData.headerFont}
            onChange={(e) => {
              setEmbedData({ ...embedData, headerFont: e.target.value })
            }}
          />
          <TextField
            label="Heading Weight"
            variant="outlined"
            value={embedData.headerWeight}
            onChange={(e) => {
              setEmbedData({ ...embedData, headerWeight: e.target.value })
            }}
          />
          <TextField
            label="Body Font (Google font)"
            variant="outlined"
            value={embedData.bodyFont}
            onChange={(e) => {
              setEmbedData({ ...embedData, bodyFont: e.target.value })
            }}
          />
          <TextField
            label="Body Weight"
            variant="outlined"
            value={embedData.bodyWeight}
            onChange={(e) => {
              setEmbedData({ ...embedData, bodyWeight: e.target.value })
            }}
          />
          <TextField
            label="Text Colour (hexcode)"
            variant="outlined"
            value={embedData.textColour}
            onChange={(e) => {
              setEmbedData({ ...embedData, textColour: e.target.value })
            }}
          />
          <TextField
            label="CTA Background Colour (hexcode)"
            variant="outlined"
            value={embedData.ctaColour}
            onChange={(e) => {
              setEmbedData({ ...embedData, ctaColour: e.target.value })
            }}
          />
        </Box>
        <Box sx={inputWrapperStyles}>
          <TextField
            label="CTA Hover Background Colour (hexcode)"
            variant="outlined"
            value={embedData.ctaHoverColour}
            onChange={(e) => {
              setEmbedData({ ...embedData, ctaHoverColour: e.target.value })
            }}
          />
          <TextField
            label="CTA Text Colour (hexcode)"
            variant="outlined"
            value={embedData.ctaTextColour}
            onChange={(e) => {
              setEmbedData({ ...embedData, ctaTextColour: e.target.value })
            }}
          />
          <TextField
            label="Embed Background Colour (hexcode)"
            variant="outlined"
            value={embedData.bgColour}
            onChange={(e) => {
              setEmbedData({ ...embedData, bgColour: e.target.value })
            }}
          />
          <TextField
            label="CTA Text"
            variant="outlined"
            value={embedData.ctaText}
            onChange={(e) => {
              setEmbedData({ ...embedData, ctaText: e.target.value })
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="image-arrangement-select-label">
              Image Arrangement
            </InputLabel>
            <Select
              labelId="image-arrangement-select-label"
              label="Image Arrangement"
              value={embedData.imageArrangement}
              onChange={(e) => {
                setEmbedData({
                  ...embedData,
                  imageArrangement: e.target.value as ImageArrangements,
                })
              }}
            >
              <MenuItem value={ImageArrangements.Right}>Right</MenuItem>
              <MenuItem value={ImageArrangements.Left}>Left</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="mobile-image-arrangement-select-label">
              Mobile Image Arrangement
            </InputLabel>
            <Select
              labelId="mobile-image-arrangement-select-label"
              label="Mobile Image Arrangement"
              value={embedData.mobileImageArrangement}
              onChange={(e) => {
                setEmbedData({
                  ...embedData,
                  mobileImageArrangement: e.target
                    .value as MobileImageArrangements,
                })
              }}
            >
              <MenuItem value={MobileImageArrangements.Top}>Top</MenuItem>
              <MenuItem value={MobileImageArrangements.Bottom}>Bottom</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="cta-style-select-label">CTA Style</InputLabel>
            <Select
              labelId="cta-style-select-label"
              label="CTA style"
              value={embedData.ctaStyle}
              onChange={(e) => {
                setEmbedData({
                  ...embedData,
                  ctaStyle: e.target.value as CtaStyles,
                })
              }}
            >
              <MenuItem value={CtaStyles.Rounded}>Rounded</MenuItem>
              <MenuItem value={CtaStyles.Square}>Square</MenuItem>
              <MenuItem value={CtaStyles.Link}>Link</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Paper>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          wordBreak: 'break-all',
          columnGap: 2,
          rowGap: 2,
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Card
          sx={{
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 1,
              flexGrow: 1,
            }}
          >
            <Text sx={{ color: 'text.secondary' }}>URL:</Text>
            <code>{encodedUrl}</code>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => {
                copyText(encodedUrl)
              }}
            >
              Copy
            </Button>
            <Button
              size="small"
              onClick={() => {
                window.open(encodedUrl, '_blank')
              }}
            >
              Open in new tab
            </Button>
          </CardActions>
        </Card>
        <Card
          sx={{
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 1,
              flexGrow: 1,
            }}
          >
            <Text sx={{ color: 'text.secondary' }}>Full Embed:</Text>
            <code>
              {embedJavascriptSnippet}
              <br />
              <br />
              {embedIframeSnippet(encodedUrl)}
            </code>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => {
                copyText(
                  `${embedJavascriptSnippet}\n\n${embedIframeSnippet(
                    encodedUrl,
                  )}`,
                )
              }}
            >
              Copy
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  )
}

export default CharityEmbedGeneratorView
