import {
  AdditionalOption,
  DayOfWeek,
  Gender,
  MaritalStatus,
  Province,
  StandardAnswer,
} from 'global.types'

export enum DataEndpoint {
  CharitableGifts = '/data/charitable_gifts/',
  CharityPartners = '/data/charity_partners/',
  DistributionCharities = '/data/distribution_charities/',
  BusinessReport = '/data/business_report/',
  CorporateExecutor = '/data/corporate_executor/',
}

export enum DataFormat {
  JSON = 'json',
  CSV = 'csv',
}

export type ReportProvince =
  | Province
  | AdditionalOption.Other
  | AdditionalOption.NoAnswer

export const reportProvinces = [
  ...Object.values(Province),
  AdditionalOption.Other,
  AdditionalOption.NoAnswer,
] as ReportProvince[]

export type ReportStandardAnswer =
  | Omit<StandardAnswer, 'unsure'>
  | AdditionalOption.NoAnswer

export const reportStandardAnswers = [
  'no',
  'yes',
  AdditionalOption.NoAnswer,
] as ReportStandardAnswer[]

export type ReportGenders = Gender | AdditionalOption.NoAnswer

export const reportGenders = [
  ...Object.values(Gender),
  AdditionalOption.NoAnswer,
] as ReportGenders[]

export interface DataRequestParams {
  format: DataFormat
}

export interface CharitableGiftsResponse {
  name: string
  amount: number
  number?: string
  isPercent: boolean
  hasGenerated: boolean
  hasPaid: boolean
  coreProductPaymentDate?: string
  mostRecentGenerationDate?: string
  province?: string
  age?: number
  hasPartner?: string
  hasChildren?: string
  gender?: string
}

export interface CharityPartnersResponse {
  name: string
  amount: number
  number: string
  isPercent: boolean
  province: string
  gender: string
  age: number
  hasChildren: string
  hasPartner: string
  hasGenerated: boolean
  hasPaid: boolean
  mostRecentGenerationDate: Date
  coreProductPaymentDate: Date
}

export interface DistributionCharitiesResponse {
  totalPortions: number
  name: string
  portions: number
  number?: string
  hasGenerated: boolean
  hasPaid: boolean
  hasChildren: string
  mostRecentGenerationDate?: Date
  coreProductPaymentDate?: Date
  province?: string
  gender?: string
  age?: number
  hasPartner?: string
  allToPartner?: string
}

export interface BusinessReportRequest {
  start: string
  end: string
  provinces?: ReportProvince[]
  hasPartner?: ReportStandardAnswer[]
  hasChildren?: ReportStandardAnswer[]
  hasPets?: ReportStandardAnswer[]
  genders?: ReportGenders[]
  minAge?: number
  maxAge?: number
  excludeMissingAges?: boolean
  excludeLargeDiscounts?: boolean
}

export interface CorporateExecutorResponse {
  firstName: string
  lastName: string
  email: string
  city: string
  province: Province
  postalCode?: string
  hasPartner: StandardAnswer
  maritalStatus: MaritalStatus
  hasChildren: StandardAnswer
  gender: Gender
  dateOfBirth: string
  ownsCanadianRealEstate?: StandardAnswer
  ownsForeignProperty: StandardAnswer
  hasRrspAccount: boolean | null
  hasRrifAccount: boolean | null
  hasTfsaAccount: boolean | null
  hasLifeInsurance?: StandardAnswer
  hasResps: StandardAnswer
  isRbcClient: StandardAnswer
  corpExecType:
    | 'alt-trustee'
    | 'co-trustee'
    | 'trustee'
    | 'non-partner-alt-trustee'
  isPartner: boolean
  hasGenerated: boolean
  hasPaid: boolean
  ref?: string
  reftag?: string
}

export interface BusinessReportResponse {
  questionnaire: Questionnaire
  meta?: Meta
  user?: User
}

interface Meta {
  timezone: string | null
  age: number | null
  ageAtAccountCreation: number | null
  hasGenerated: boolean
  hasPaid: boolean
  hasStartedQuestionnaire: boolean
  hasCreatedAccount: boolean
  largeDiscount: boolean
  localizedTimes: LocalizedTimes
}

interface LocalizedTimes {
  questionnaireCreatedAt: LocalizedTime
  accountCreatedAt: LocalizedTime
  firstPayment: LocalizedTime
  firstGeneration: LocalizedTime
}

export interface LocalizedTime {
  date: Date | null
  dayOfWeek: DayOfWeek | null
}

export interface Questionnaire {
  _id: string
  answerStoreUpdatedSinceGenerate: boolean
  accountCreated: boolean
  createdAt: string
  answerStore?: QuestionnaireAnswers
  abTest: string
  questionnairesStarted: string[]
  answerCache?: QuestionnaireAnswers
  referrer: Referrer
  referrerInitiated: boolean
  socialAnswerStoreUpdatedSinceGenerate: boolean
  socialAnswerStore: SocialAnswerStore
  toggles?: Toggles
  questionnaireFirstCompleted?: string
}

export interface QuestionnaireAnswers {
  hasPartner?: string
  hasChildren?: string
  province?: string
  familyDisability?: string
  childrenOtherRelationship?: string
  firstName?: string
  lastName?: string
  email?: string
  password?: string
  includePartner?: string
  product?: string
  hasPartnerSpecific?: string
  dateOfBirth?: string
  cityTown?: string
  gender?: string
  hasPets?: string
  partnerFirstName?: string
  partnerLastName?: string
  partnerReference?: string
  partnerGender?: string
  remoteDistribution?: RemoteDistribution[]
  inheritanceAge?: string
  specificGifts?: string
  partnerAsTrustee?: string
  coTrusteeName?: string
  coTrusteeRelationship?: string
  partnerAsPropertyPoa?: string
  partnerAsPersonalPoa?: string
  partnerCoPersonalPoaName?: string
  partnerCoPersonalPoaRelationship?: string
  dnr?: string
  middleName?: string
  partnerEmail?: string
  partnerMiddleName?: string
  hasStepChildren?: string
  children?: Children[]
  stepChildren?: Children[]
  minorChildren?: string
  altTrusteeRelationship?: string
  altTrusteeName?: string
  altPartnerPropertyPoaName?: string
  altPartnerPropertyPoaRelationship?: string
  altPartnerPersonalPoaName?: string
  altPartnerPersonalPoaRelationship?: string
  guardianName?: string
  guardianRelationship?: string
  partnerCoPropertyPoaName?: string
  partnerCoPropertyPoaRelationship?: string
  allAssetsToPartner?: string
  charitableGifts?: string
  pets?: Pet[]
  petGuardianName?: string
  petGuardianRelationship?: string
  hasCommonName?: boolean
  hasResps?: string
  hasForeignProperty?: string
  investmentAccounts?: string[]
  hasLifeInsurance?: string
  canadianRealEstate?: string
  corpCoTrusteeInfo?: string
  corpAltTrusteeInfo?: string
  foreignPropertyLawyer?: boolean
  stepChildrenDistribution?: string
  dealWithRealEstate?: string
  acceptsFamilyWill?: string
  predeceasedBackup?: string
  noPartnerSpecific?: string
  nonPartnerTrusteeName?: string
  nonPartnerTrusteeRelationship?: string
  altNonPartnerTrusteeName?: string
  altNonPartnerTrusteeRelationship?: string
  nonPartnerPropertyPoaName?: string
  nonPartnerPropertyPoaRelationship?: string
  altNonPartnerPropertyPoaName?: string
  altNonPartnerPropertyPoaRelationship?: string
  nonPartnerPersonalPoaName?: string
  nonPartnerPersonalPoaRelationship?: string
  altNonPartnerPersonalPoaName?: string
  altNonPartnerPersonalPoaRelationship?: string
  corpTrusteeInfo?: string
  nonPartnerCorpAltTrusteeInfo?: string
  petGuardianAmount?: string
  commonlyKnownAsName?: string
  hasCheckedCorpExecFeeAgreement?: boolean
  corpTrusteeDetails?: CorpTrusteeDetails
  isCorporateExecutorClient?: string
  corpTrusteeDisqualifiers?: string
  corpTrusteeFees?: string
  allToPartner?: string
  alternatePetGuardianName?: string
  alternatePetGuardianRelationship?: string
  respClause?: string
  corpCoTrusteeDisqualifiers?: string
  corpCoTrusteeFees?: string
  allAssetsToChildren?: string
  predeceased?: string
  alternateGuardianName?: string
  alternateGuardianRelationship?: string
  unsupportedProvince?: string
  giftDetails?: GiftDetail[]
  corpAltTrusteeDisqualifiers?: string
  corpAltTrusteeFees?: string
  charitableGiftDetails?: CharitableGiftDetail[]
  nonPartnerCorpAltTrusteeDisqualifiers?: string
  nonPartnerCorpAltTrusteeFees?: string
  inContemplationOfMarriage?: string
  charityPartnerGiftInclude?: string
  charityPartnerGiftType?: string
  charityPartnerGiftAmount?: string
  charityPartnerGiftDetails?: CharityPartnerGiftDetails
  charityPartnerNotify?: string
  charityPartnerGiftTiming?: string
  willType?: string
}

export interface RemoteDistribution {
  type: string
  name: string
  relationship: string
  portions: string
  id: string
  percentage: string
  number?: string
  location?: string
  predeceasedSplit?: string
  alternateName?: string
  alternateRelationship?: string
  alternateNumber?: string
  alternateLocation?: string
}

export interface Children {
  name: string
  dob: string
  isStep: boolean
  id: string
  distribution?: string
}

export interface Pet {
  name: string
  type: string
  id: string
}

export interface CorpTrusteeDetails {
  ref: string
  role: string
  agreeDate: string
  legalName: string
}

export interface GiftDetail {
  description: string
  recipient: string
  relationship: string
  id: string
}

export interface CharitableGiftDetail {
  type: string
  name: string
  relationship: string
  amount: string
  id: string
  isPercent: boolean
  number?: string
  location?: string
}

export interface CharityPartnerGiftDetails {
  name: string
  location: string
  isPercent: boolean
  type: string
  id: string
  relationship: string
  amount: string
  number?: string
  isPublicFoundation?: boolean
}

export interface Referrer {
  ref?: string
  reftag?: string
}

export interface SocialAnswerStore {
  email?: string
  social_country?: string
  social_province?: string
  social_includeFacebook?: string
  social_emailIsFacebook?: string
  social_facebookWishes?: string
  password?: string
  social_facebookWishesSet?: string
  social_facebookInstructions?: string
  social_includeInstagram?: string
  social_emailIsInstagram?: string
  social_instagramWishes?: string
  social_instagramInstructions?: string
  social_includeTwitter?: string
  social_emailIsTwitter?: string
  social_twitterWishes?: string
  social_includeTiktok?: string
  social_includeLinkedin?: string
  social_emailIsLinkedin?: string
  social_linkedinWishes?: string
  social_includeGoogle?: string
  social_emailIsGoogle?: string
  social_googleManagerSet?: string
  social_tellGoogle?: string
  social_includeApple?: string
  social_firstName?: string
  social_lastName?: string
  social_facebookEmail?: string
  social_instagramEmail?: string
  social_twitterEmail?: string
  social_googleEmail?: string
  social_googleInstructions?: string
  social_emailIsApple?: string
  social_appleEmail?: string
  social_appleLegacyContact?: string
  social_tellApple?: string
  social_appleInstructions?: string
  social_middleName?: string
  social_facebookLegacyContact?: string
  social_linkedinEmail?: string
  social_emailIsTiktok?: string
  social_tiktokWishes?: string
  social_tiktokInstructions?: string
  social_twitterInstructions?: string
  social_linkedinInstructions?: string
  social_tiktokEmail?: string
  social_state?: string
}

// None of these at time of creation
export interface Toggles {}

export interface User {
  _id: string
  isPartner: boolean
  email: string
  documents: Document[]
  payments: Payment[]
  createdAt: string
  codes: Code[]
  updatesExpiry: UpdatesExpiry
  role: string
  addOns?: PaymentAddOn[]
  bonusDocsAnswerStore?: BonusDocsAnswerStore
  mailingList?: boolean
  accountLocked?: boolean
  socialDocuments?: SocialDocument[]
  timesGenerated?: TimesGenerated
  howDidYouHearAboutUs?: string
  documentDownloads?: DocumentDownload[]
  partner?: string
  postalCode?: string
  isVerified?: boolean
}

export interface Document {
  _id: string
  docType: string
  key: string
  created: string
  bonus?: boolean
  tagAlong?: boolean
  signed?: boolean
  previewKey?: string
}

export interface Payment {
  docTypes: string[]
  _id?: string
  chargeId: string
  productId: number
  partnerPaidFor: boolean
  created: string
  addOns: AddOn[]
  promoCodeUsed?: PromoCodeUsed
  price?: number
  discount?: number
  paymentPlan?: PaymentPlan
  referrer?: Referrer
}

export interface AddOn {
  type: string
  price?: number
  oneTimeUse: boolean
  partnerPaidFor?: boolean
  _id?: string
  label?: string
}

export interface PromoCodeUsed {
  isPercent: boolean
  expiryDate: string
  isActive: boolean
  oneTimeUse: boolean
  _id: string
  code: string
  amount: number
  __v?: number
  description?: string
  referrer?: Referrer
}

export interface PaymentPlan {
  downPayment: number
  paymentPlanId: string
  installments: Installment[]
  _id: string
}

export interface Installment {
  amount: number
  scheduled: string
  _id: string
}

export interface Code {
  code: string
  label: string
  _id?: string
}

export interface UpdatesExpiry {
  will: any
}

export interface PaymentAddOn {
  _id: string
  type: string
  oneTimeUse: boolean
}

export interface BonusDocsAnswerStore {
  wishes?: Wishes
}

export interface Wishes {
  wishes_organs?: string
  wishes_body?: string
  wishes_ceremony?: string
  wishes_additionalWishes?: string
  wishes_ashes?: string
}

export interface SocialDocument {
  docType: string
  key: string
  isFree: boolean
  _id: string
  created: string
}

export interface TimesGenerated {
  social: string[]
  will: string[]
}

export interface DocumentDownload {
  type: string
  documents: {
    docType: string
    docId?: string
    _id: string
  }
  _id: string
  timestamp: string
}
