import { useQuery } from 'react-query'
import { CharityPartnerEndpoint, getCharityPartner } from 'utils/api/charityPartner.api'

const useGetCharityPartner = (id: string) => useQuery({
  queryKey: [CharityPartnerEndpoint.CharityPartner, id],
  queryFn: () => getCharityPartner(id),
  retry: false,
  staleTime: 60000, // 1 minute
  refetchOnWindowFocus: false,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetCharityPartner