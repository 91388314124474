import {
  useState,
  ReactNode,
  useContext,
  createContext,
  useEffect,
} from 'react'
import moment from 'moment'

import {
  BusinessReportRequest,
  BusinessReportResponse,
} from 'utils/api/data.types'
import {
  localStorage,
  useDebounce,
  useGetBusinessReportData,
  useProcessedBusinessReportData,
} from 'utils'
import {
  IBusinessReportContext,
  ReportView,
} from './BusinessReportContext.types'
import { LocalStorageKey } from 'global.types'

export const defaultFilters: BusinessReportRequest = {
  start: '2020-01-01',
  end: moment().format('YYYY-MM-DD'),
  excludeMissingAges: false,
  excludeLargeDiscounts: true,
}

export const unsetFilters: BusinessReportRequest = {
  end: moment().format('YYYY-MM-DD'),
  start: '2020-01-01',
}

const BusinessReportContext = createContext<IBusinessReportContext | undefined>(
  undefined,
)

export const useBusinessReportContext = () => {
  const context = useContext(BusinessReportContext)

  if (context === undefined) {
    throw new Error(
      'useBusinessReportContext must be within ReportDataProvider',
    )
  }

  return context
}

export const ReportDataProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[]
}) => {
  // Data context state
  const [reportData, setReportData] = useState<BusinessReportResponse[]>([])
  const [dataLoadingError, setDataLoadingError] = useState<string | undefined>(
    undefined,
  )
  const [currentlyProcessedCount, setCurrentlyProcessedCount] = useState(0)
  const [isProcessingData, setIsProcessingData] = useState(false)
  const [processDataError, setProcessDataError] = useState<string | undefined>(
    undefined,
  )
  const [processingStatus, setProcessingStatus] = useState<string | undefined>(
    undefined,
  )
  const [processingProgress, setProcessingProgress] = useState(0)

  // Filters context state
  const [filters, setFilters] = useState<BusinessReportRequest>(defaultFilters)

  // Interface context state
  const [showData, toggleShowData] = useState<boolean>(false)
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
  const [filtersHeight, setFiltersHeight] = useState(0)
  const [showFilters, toggleShowFilters] = useState(false)
  const [view, setView] = useState<ReportView>(ReportView.HowDidYouHerAboutUs)
  const [pinFilters, togglePinFilters] = useState<boolean>(
    localStorage
      .read(LocalStorageKey.PinnedBusinessReportFilters)
      ?.toLowerCase() === 'true',
  )

  // Data
  const debouncedFilters = useDebounce<BusinessReportRequest>(filters, 800)
  const {
    data,
    isLoading: isLoadingData,
    refetch: refetchData,
    isRefetching: isRefetchingData,
    hasInitialData,
  } = useGetBusinessReportData({
    params: debouncedFilters,
    setDataLoadingError,
    setShowLoadingOverlay,
    setProcessingStatus,
  })
  const { processedReportData } = useProcessedBusinessReportData({
    reportData,
    setIsProcessingData,
    setProcessDataError,
    setProcessingStatus,
    setProcessingProgress,
  })

  // Side effects
  useEffect(() => {
    if (data) setReportData(data)
  }, [data, debouncedFilters])

  useEffect(() => {
    localStorage.write(
      LocalStorageKey.PinnedBusinessReportFilters,
      pinFilters ? 'true' : 'false',
    )
  }, [pinFilters])

  return (
    <BusinessReportContext.Provider
      value={{
        data: {
          reportData,
          setReportData,

          showData,
          toggleShowData,

          isLoadingData: isLoadingData || isRefetchingData,

          processedReportData,

          currentlyProcessedCount,
          setCurrentlyProcessedCount,

          isProcessingData,
          processingStatus,
          processDataError,
          processingProgress,

          dataLoadingError,
          setDataLoadingError,

          refetchData,

          hasInitialData,
        },

        filters: {
          filters,
          setFilters,

          debouncedFilters,
        },

        interface: {
          view,
          setView,

          showFilters,
          toggleShowFilters: () => toggleShowFilters((previous) => !previous),

          pinFilters,
          togglePinFilters: () => togglePinFilters((previous) => !previous),

          filtersHeight,
          setFiltersHeight,

          showLoadingOverlay,
          setShowLoadingOverlay,
        },
      }}
    >
      {children}
    </BusinessReportContext.Provider>
  )
}
