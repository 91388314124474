import {
  Box,
  Paper,
  Theme,
  Button,
  SxProps,
  Divider,
  useMediaQuery,
} from '@mui/material'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'

import {
  Text,
  Tabs,
  InputList,
  InputField,
  InputSelect,
  ToggleButtons,
} from 'components'
import { regex, useFormData, checkAllPropertiesTrue } from 'utils'
import { Province } from 'global.types'
import { Lawyer } from 'utils/api/lawyer.types'
import cleanLawyerData from './cleanLawyerData'

enum LawyerType {
  Lawyer = 'lawyer',
  Firm = 'firm',
}

enum FeeType {
  Flat = 'flat',
  Standard = 'standard',
}

interface FormMetaData {
  lawyerType: LawyerType
  feeType: FeeType
}

interface LawyerFormProps {
  initialLawyerData?: Lawyer
  onSubmit: (data: Lawyer) => void
}

const defaultInitialFormData = {
  _id: undefined,
  bio: undefined,
  city: '',
  email: '',
  imageUrl: '',
  worksRemote: true,
  active: true,
  province: Province.Alberta,
  lawyerFirstName: undefined,
  lawyerLastName: undefined,
  contactFirstName: undefined,
  contactLastName: undefined,
  firm: undefined,
  highlights: undefined,
  flatFeeData: undefined,
}

const initialFormMetaData = {
  lawyerType: LawyerType.Lawyer,
  feeType: FeeType.Standard,
}

const initialValidationData = {
  city: false,
  email: false,
  imageUrl: false,

  lawyerFirstName: false,
  lawyerLastName: false,

  contactFirstName: false,
  contactLastName: false,
  firm: false,

  standardHighlights: false,

  flatFeeDefaultPrice: false,
  flatFeeDefaultHighlights: false,

  flatFeeSpousalTrustPrice: false,
  flatFeeSpousalTrustHighlights: false,

  flatFeeFamilyDisabilityPrice: false,
  flatFeeFamilyDisabilityHighlights: false,

  flatFeeNotRightForMePrice: false,
  flatFeeNotRightForMeHighlights: false,
}

const LawyerForm = (props: LawyerFormProps) => {
  const { initialLawyerData = defaultInitialFormData, onSubmit } = props

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  // const isExistingLawyer = !!initialLawyerData._id

  const [formData, updateFormData] = useFormData<Lawyer>(initialLawyerData)
  const [formMetaData, updateFormMetaData] =
    useFormData<FormMetaData>(initialFormMetaData)
  const [isValid, setIsValid] = useState(initialValidationData)
  const [displayValidation, setDisplayValidation] = useState(false)

  const columnStyle: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    width: isMobile ? '100%' : '50%',
  }

  const resetState = () => {
    updateFormData(initialLawyerData)
    updateFormMetaData(initialFormMetaData)
    setIsValid(initialValidationData)
    setDisplayValidation(false)
  }

  useEffect(() => {
    const city = !!formData.city
    const email = regex.EMAIL.test(formData.email)
    const imageUrl = regex.URL.test(formData.imageUrl)

    const lawyerFirstName =
      formMetaData.lawyerType === LawyerType.Firm || !!formData.lawyerFirstName
    const lawyerLastName =
      formMetaData.lawyerType === LawyerType.Firm || !!formData.lawyerLastName

    const contactFirstName =
      formMetaData.lawyerType === LawyerType.Lawyer ||
      !!formData.contactFirstName
    const contactLastName =
      formMetaData.lawyerType === LawyerType.Lawyer ||
      !!formData.contactLastName
    const firm =
      formMetaData.lawyerType === LawyerType.Lawyer || !!formData.firm

    const standardHighlights =
      formMetaData.feeType === FeeType.Flat ||
      !!formData.highlights?.every((value) => !!value)

    const flatFeeDefaultHighlights =
      formMetaData.feeType === FeeType.Standard ||
      !!formData.flatFeeData?.default.highlights?.every((value) => !!value)
    const flatFeeDefaultPrice =
      formMetaData.feeType === FeeType.Standard ||
      !!formData.flatFeeData?.default?.price

    const flatFeeSpousalTrustPrice =
      formMetaData.feeType === FeeType.Standard ||
      _isEmpty(formData.flatFeeData?.spousalTrust?.highlights) ||
      !!formData.flatFeeData?.spousalTrust?.price
    const flatFeeSpousalTrustHighlights =
      formMetaData.feeType === FeeType.Standard ||
      !formData.flatFeeData?.spousalTrust?.price ||
      (!_isEmpty(formData.flatFeeData?.spousalTrust?.highlights) &&
        !!formData.flatFeeData?.spousalTrust?.highlights.every(
          (value) => !!value,
        ))

    const flatFeeFamilyDisabilityPrice =
      formMetaData.feeType === FeeType.Standard ||
      _isEmpty(formData.flatFeeData?.familyDisability?.highlights) ||
      !!formData.flatFeeData?.familyDisability?.price
    const flatFeeFamilyDisabilityHighlights =
      formMetaData.feeType === FeeType.Standard ||
      !formData.flatFeeData?.familyDisability?.price ||
      (!_isEmpty(formData.flatFeeData?.familyDisability?.highlights) &&
        !!formData.flatFeeData?.familyDisability?.highlights.every(
          (value) => !!value,
        ))

    const flatFeeNotRightForMePrice =
      formMetaData.feeType === FeeType.Standard ||
      _isEmpty(formData.flatFeeData?.notRightForMe?.highlights) ||
      !!formData.flatFeeData?.notRightForMe?.price
    const flatFeeNotRightForMeHighlights =
      formMetaData.feeType === FeeType.Standard ||
      !formData.flatFeeData?.notRightForMe?.price ||
      (!_isEmpty(formData.flatFeeData?.notRightForMe?.highlights) &&
        !!formData.flatFeeData?.notRightForMe?.highlights.every(
          (value) => !!value,
        ))

    setIsValid({
      city,
      email,
      imageUrl,

      lawyerFirstName,
      lawyerLastName,

      contactFirstName,
      contactLastName,
      firm,

      standardHighlights,

      flatFeeDefaultPrice,
      flatFeeDefaultHighlights,

      flatFeeSpousalTrustPrice,
      flatFeeSpousalTrustHighlights,

      flatFeeFamilyDisabilityPrice,
      flatFeeFamilyDisabilityHighlights,

      flatFeeNotRightForMePrice,
      flatFeeNotRightForMeHighlights,
    })
  }, [formData, formMetaData])

  return (
    <Paper
      sx={{
        flexGrow: 1,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          columnGap: 2,
        }}
      >
        <Box sx={columnStyle}>
          <Tabs
            value={formMetaData.lawyerType}
            onChange={(lawyerType) => {
              if (lawyerType === LawyerType.Lawyer) {
                updateFormData({
                  contactFirstName: undefined,
                  contactLastName: undefined,
                  firm: undefined,
                })
              } else {
                updateFormData({
                  lawyerFirstName: undefined,
                  lawyerLastName: undefined,
                })
              }
              updateFormMetaData({ lawyerType })
            }}
            tabs={[
              {
                label: 'Lawyer',
                value: LawyerType.Lawyer,
              },
              {
                label: 'Law Firm',
                value: LawyerType.Firm,
              },
            ]}
          />

          {formMetaData.lawyerType === LawyerType.Lawyer ? (
            <>
              <InputField
                required
                label="First Name"
                value={formData.lawyerFirstName}
                displayValidation={displayValidation}
                isValid={isValid.lawyerFirstName}
                onChange={(lawyerFirstName) =>
                  updateFormData({ lawyerFirstName })
                }
              />
              <InputField
                required
                label="Last Name"
                value={formData.lawyerLastName}
                displayValidation={displayValidation}
                isValid={isValid.lawyerLastName}
                onChange={(lawyerLastName) =>
                  updateFormData({ lawyerLastName })
                }
              />
            </>
          ) : (
            <>
              <InputField
                required
                label="Contact Last Name"
                value={formData.contactFirstName}
                displayValidation={displayValidation}
                isValid={isValid.contactFirstName}
                onChange={(contactFirstName) =>
                  updateFormData({ contactFirstName })
                }
              />
              <InputField
                required
                label="Contact Last Name"
                value={formData.contactLastName}
                displayValidation={displayValidation}
                isValid={isValid.contactLastName}
                onChange={(contactLastName) =>
                  updateFormData({ contactLastName })
                }
              />
              <InputField
                required
                label="Firm"
                value={formData.firm}
                displayValidation={displayValidation}
                isValid={isValid.firm}
                onChange={(firm) => updateFormData({ firm })}
              />
            </>
          )}

          <Divider />

          <InputField
            rows={2}
            label="Bio"
            value={formData.bio}
            onChange={(bio) => updateFormData({ bio })}
          />
          <InputField
            required
            label="City"
            value={formData.city}
            displayValidation={displayValidation}
            isValid={isValid.city}
            onChange={(city) => updateFormData({ city })}
          />
          <InputField
            required
            label="Email"
            value={formData.email}
            displayValidation={displayValidation}
            isValid={isValid.email}
            onChange={(email) => updateFormData({ email })}
          />
          <InputField
            required
            label="Image URL"
            value={formData.imageUrl}
            displayValidation={displayValidation}
            isValid={isValid.imageUrl}
            onChange={(imageUrl) => updateFormData({ imageUrl })}
          />
          <ToggleButtons
            fullWidth
            value={formData.worksRemote}
            onChange={(worksRemote) => updateFormData({ worksRemote })}
            buttons={[
              {
                value: true,
                label: 'Works Remotely',
              },
              {
                value: false,
                label: 'Non-remote',
              },
            ]}
          />
          <InputSelect
            label="Province"
            value={formData.province}
            onChange={(province) => updateFormData({ province })}
            options={Object.values(Province).map((province) => ({
              label: province,
              value: province,
            }))}
          />
          {isMobile && <Divider />}
        </Box>
        <Box
          sx={{
            ...columnStyle,
            mt: isMobile ? 2 : 0,
          }}
        >
          <Tabs
            value={formMetaData.feeType}
            onChange={(feeType) => {
              if (feeType === FeeType.Standard) {
                updateFormData({
                  flatFeeData: undefined,
                })
              } else {
                updateFormData({
                  highlights: undefined,
                })
              }
              updateFormMetaData({ feeType })
            }}
            tabs={[
              {
                label: 'Standard Lawyer',
                value: FeeType.Standard,
              },
              {
                label: 'Flat Fee Lawyer',
                value: FeeType.Flat,
              },
            ]}
          />

          {formMetaData.feeType === FeeType.Standard ? (
            <InputList
              label="Highlights"
              onChange={(highlights) => {
                updateFormData({ highlights })
              }}
              displayValidation={displayValidation}
              invalidIfEmpty
              minItems={3}
              maxItems={3}
              values={formData.highlights || [undefined, undefined, undefined]}
            />
          ) : (
            <>
              {/* Default */}
              <InputField
                type="number"
                label="Default Price"
                startAdornment="$"
                value={
                  formData.flatFeeData?.default?.price
                    ? formData.flatFeeData?.default.price.toString()
                    : undefined
                }
                onChange={(price) =>
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      default: {
                        ...formData.flatFeeData?.default,
                        price: price ? parseFloat(price) : undefined,
                      },
                    },
                  })
                }
                displayValidation={displayValidation}
                isValid={isValid.flatFeeDefaultPrice}
              />
              <InputList
                label="Default Highlights"
                onChange={(highlights) => {
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      default: { ...formData.flatFeeData?.default, highlights },
                    },
                  })
                }}
                displayValidation={displayValidation}
                invalidIfEmpty
                minItems={1}
                maxItems={4}
                values={
                  formData.flatFeeData?.default?.highlights || [undefined]
                }
              />
              <Divider />

              {/* Spousal Trust */}
              <InputField
                type="number"
                label="Spousal Trust Price"
                startAdornment="$"
                value={
                  formData.flatFeeData?.spousalTrust?.price
                    ? formData.flatFeeData?.spousalTrust?.price.toString()
                    : undefined
                }
                onChange={(price) =>
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      spousalTrust: {
                        ...formData.flatFeeData?.spousalTrust,
                        price: price ? parseFloat(price) : undefined,
                      },
                    },
                  })
                }
                displayValidation={displayValidation}
                isValid={isValid.flatFeeSpousalTrustPrice}
              />
              <InputList
                label="Spousal Trust Highlights"
                onChange={(highlights) => {
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      spousalTrust: {
                        ...formData.flatFeeData?.spousalTrust,
                        highlights,
                      },
                    },
                  })
                }}
                displayValidation={displayValidation}
                invalidIfEmpty
                maxItems={4}
                values={formData.flatFeeData?.spousalTrust?.highlights || []}
              />
              <Divider />

              {/* Family Disability */}
              <InputField
                type="number"
                label="Family Disability Price"
                startAdornment="$"
                value={
                  formData.flatFeeData?.familyDisability?.price
                    ? formData.flatFeeData?.familyDisability?.price.toString()
                    : undefined
                }
                onChange={(price) =>
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      familyDisability: {
                        ...formData.flatFeeData?.familyDisability,
                        price: price ? parseFloat(price) : undefined,
                      },
                    },
                  })
                }
                displayValidation={displayValidation}
                isValid={isValid.flatFeeFamilyDisabilityPrice}
              />
              <InputList
                label="Family Disability Highlights"
                onChange={(highlights) => {
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      familyDisability: {
                        ...formData.flatFeeData?.familyDisability,
                        highlights,
                      },
                    },
                  })
                }}
                displayValidation={displayValidation}
                invalidIfEmpty
                maxItems={4}
                values={
                  formData.flatFeeData?.familyDisability?.highlights || []
                }
              />
              <Divider />

              {/* Not Right For Me */}
              <InputField
                type="number"
                label="Not Right For Me Price"
                startAdornment="$"
                value={
                  formData.flatFeeData?.notRightForMe?.price
                    ? formData.flatFeeData?.notRightForMe?.price.toString()
                    : undefined
                }
                onChange={(price) =>
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      notRightForMe: {
                        ...formData.flatFeeData?.notRightForMe,
                        price: price ? parseFloat(price) : undefined,
                      },
                    },
                  })
                }
                displayValidation={displayValidation}
                isValid={isValid.flatFeeNotRightForMePrice}
              />
              <InputList
                label="Not Right For Me Highlights"
                onChange={(highlights) => {
                  updateFormData({
                    flatFeeData: {
                      ...formData.flatFeeData,
                      notRightForMe: {
                        ...formData.flatFeeData?.notRightForMe,
                        highlights,
                      },
                    },
                  })
                }}
                displayValidation={displayValidation}
                invalidIfEmpty
                maxItems={4}
                values={formData.flatFeeData?.notRightForMe?.highlights || []}
              />
              {isMobile && <Divider />}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: 2,
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          disabled={_isEqual(formData, initialLawyerData)}
          onClick={() => {
            setDisplayValidation(true)
            if (checkAllPropertiesTrue(isValid)) {
              onSubmit(cleanLawyerData(formData))
            }
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          onClick={resetState}
          disabled={_isEqual(formData, initialLawyerData)}
        >
          Reset
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {initialLawyerData._id && !isMobile && (
          <Text variant="subtitle2" sx={{ alignSelf: 'center' }}>
            ID: {initialLawyerData._id}
          </Text>
        )}
      </Box>
    </Paper>
  )
}

export default LawyerForm
