import { Children } from 'react'
import { Box } from '@mui/material'
import _isEmpty from 'lodash/isEmpty'

import { TitledWrapper, Text } from 'components'
import { UserDetails } from 'utils/api/user.types'

const AssociatedCodes = ({ data }: { data: UserDetails }) => {
  return (
    <TitledWrapper title="Associated Codes">
      <Box
        sx={{
          p: 2,
        }}
      >
        {Children.toArray(
          data.codes.map((code) => (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 3fr 3fr',
                gridTemplateRows: '1fr',
                rowGap: 1,
              }}
            >
              <Text>{code.code}</Text>
              <Text sx={{ color: 'text.secondary' }}>{code.label}</Text>
              <Text sx={{ color: 'text.secondary' }}>{code._id}</Text>
            </Box>
          )),
        )}

        {_isEmpty(data.codes) && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              py: 2,
              color: 'text.disabled',
            }}
          >
            <Text variant="subtitle2" align="center">
              No associated codes
            </Text>
          </Box>
        )}
      </Box>
    </TitledWrapper>
  )
}

export default AssociatedCodes
