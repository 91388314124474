import _startCase from 'lodash/startCase'
import _toLower from 'lodash/toLower'

import { round } from 'utils'

const currency = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  if (Intl) {
    return formatter.format(value)
  }
  return `$${value.toFixed(2)}`
}

const percentage = (value: number, precision?: number) =>
  `${round.fixed(value, precision)}%`

const number = (value: number, precision?: number) => {
  const formatted = precision ? round.fixed(value, precision) : value.toString()

  const [integral, fractional] = formatted.split('.')

  const integralFormatted = integral.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return fractional ? `${integralFormatted}.${fractional}` : integralFormatted
}

const currencyToNumber = (value: string) => {
  return parseFloat(value.replace('$', '').replaceAll(',', ''))
}

const percentageToNumber = (value: string) => {
  return parseFloat(value.replace('%', ''))
}

const titleCase = (value: string) => _startCase(_toLower(value))

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  currency,
  percentage,
  number,
  currencyToNumber,
  percentageToNumber,
  titleCase,
}
