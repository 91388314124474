import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DataGridToolbar } from 'components'
import { useEffect, useRef, useState } from 'react'
import { useGetCorporateExecutorDataJson } from 'utils'
import { CorporateExecutorResponse } from 'utils/api/data.types'
import columnDefs from './utils/columnDefs'
import getAllDataGridRows from './utils/getAllDataGridRows'

const CorporateExecutorReportView = () => {
  const paperRef = useRef<HTMLDivElement>(null)

  const [paperHeight, setPaperHeight] = useState(0)
  const [filterUngenerated, setFilterUngenerated] = useState(false)
  const [filterUnpaid, setFilterUnpaid] = useState(false)
  const [dataRows, setDataRows] = useState<any[]>([])
  const [primaryFilteredData, setPrimaryFilteredData] = useState<
    CorporateExecutorResponse[] | undefined
  >(undefined)

  const { data, isLoading } = useGetCorporateExecutorDataJson()

  useEffect(() => {
    setPaperHeight(paperRef.current?.offsetHeight || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperRef.current, data])

  useEffect(() => {
    if (data) {
      let filteredData = [...data]

      if (filterUngenerated) {
        filteredData = filteredData.filter((item) => item.hasGenerated)
      }

      if (filterUnpaid) {
        filteredData = filteredData.filter((item) => item.hasPaid)
      }

      setPrimaryFilteredData(filteredData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUngenerated, filterUnpaid, data])

  useEffect(() => {
    const allDataGridRows = getAllDataGridRows(primaryFilteredData) as any[]
    setDataRows(allDataGridRows)
  }, [primaryFilteredData, data])

  return (
    <>
      <Paper
        ref={paperRef}
        style={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper sx={{ height: '3rem', px: 1, display: 'flex' }} elevation={0}>
          <Box sx={{ flexGrow: 1 }}>
            <FormGroup row sx={{ justifyContent: 'flex-end', mt: '0.2rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={filterUngenerated}
                    onChange={() => setFilterUngenerated(!filterUngenerated)}
                  />
                }
                label="Filter ungenerated"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={filterUnpaid}
                    onChange={() => setFilterUnpaid(!filterUnpaid)}
                  />
                }
                label="Filter unpaid"
              />
            </FormGroup>
          </Box>
        </Paper>
        <Box
          sx={{
            width: '100%',
            height: `calc(${paperHeight}px - 3rem)`,
          }}
        >
          <DataGrid
            rows={dataRows}
            columns={columnDefs.allData}
            components={{ Toolbar: DataGridToolbar }}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </>
  )
}

export default CorporateExecutorReportView
