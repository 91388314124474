import { Children } from 'react'
import { Box } from '@mui/material'
import _isEmpty from 'lodash/isEmpty'
import _capitalize from 'lodash/capitalize'

import { Text, TitledWrapper } from 'components'
import { UserDetails } from 'utils/api/user.types'

const AvailableAddons = ({ data }: { data: UserDetails }) => {
  return (
    <TitledWrapper title="Currently Available Addons">
      <Box
        sx={{
          p: 2,
        }}
      >
        {Children.toArray(
          data.addOns.map((addOn) => (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 3fr 3fr',
                gridTemplateRows: '1fr',
                rowGap: 1,
              }}
            >
              <Text>{_capitalize(addOn.type)}</Text>
              <Text sx={{ color: 'text.secondary' }}>
                {addOn.oneTimeUse ? 'one time use' : 'multi-use'}
              </Text>
              <Text sx={{ color: 'text.secondary' }}>{addOn._id}</Text>
            </Box>
          )),
        )}

        {_isEmpty(data.addOns) && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              py: 2,
              color: 'text.disabled',
            }}
          >
            <Text variant="subtitle2" align="center">
              No available add-ons
            </Text>
          </Box>
        )}
      </Box>
    </TitledWrapper>
  )
}

export default AvailableAddons
