import { GridRowsProp } from '@mui/x-data-grid'

import { CharityPartnersResponse } from 'utils/api/data.types'
import getTotalledData from './getTotalledData'

export interface CharitablePartnerGiftsTotals extends CharityPartnersResponse {
  dollarCount: number
  percentCount: number
  dollarAverage: number
  percentAverage: number
  totalPercent: number
  totalAge: number
  averageAge: number
  ageCount: number
}

const useTotalledDataGridRows = (
  data?: CharityPartnersResponse[]
): GridRowsProp => {
  if (data) {
    const summedData = getTotalledData(data)

    return summedData.map((gift, index) => ({
      id: index,
      ...gift,
    }))
  } else {
    return []
  }
}

export default useTotalledDataGridRows