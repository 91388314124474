import {
  Box,
  Paper,
  Button,
  Checkbox,
  IconButton,
  FormControlLabel,
  Alert,
  Backdrop,
} from '@mui/material'
import moment from 'moment'
import _isEqual from 'lodash/isEqual'
import { Range } from 'react-date-range'
import PushPinIcon from '@mui/icons-material/PushPin'
import { useContext, useEffect, useRef, useState } from 'react'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import {
  reportGenders,
  ReportGenders,
  ReportProvince,
  reportProvinces,
  ReportStandardAnswer,
  reportStandardAnswers,
} from 'utils/api/data.types'
import SelectFilter from './SelectFilter'
import ReportDateRangePicker from './ReportDateRangePicker'
import { ThemeContext, useBusinessReportContext } from 'utils'
import ReportNumberRangeSelector from './ReportNumberRangeSelector'
import { unsetFilters } from 'utils/context/BusinessReportContext'

const topOffset = 64

const formatDate = (date?: Date) =>
  !date ? undefined : moment(date).format('YYYY-MM-DD')

const randomTip = () => {
  const tips = [
    'You can pin the filters menu open by clicking the pin icon in the top right',
    'No values selected for a filter removes that filter from the query',
    'The data you are querying is updated every morning at approximately 4:00am',
    'The query will not be updated until you click the "Apply" button',
    'The range is querying the date the user started their questionnaire',
    'The airspeed velocity of an unladen swallow is roughly 20.1 MPH',
    'The "Reset to Current" button will reset the filters to the filters of the currently loaded data',
    'Data is cached both locally and on the server for 1 hour, so if you come back to your query it will load much faster',
    'Your local data cache is cleared if you leave the Admin Panel, refresh the page, or open in a new tab/window',
    'If one age is removed in the filter, you need to remove the other one as well',
    'No data is loaded initially, so make sure you click "Load Data" first',
  ]

  return tips[Math.floor(Math.random() * tips.length)]
}

const ReportFilterMenu = () => {
  const {
    data: { refetchData, hasInitialData, isLoadingData },
    filters: { filters, setFilters },
    interface: {
      showFilters,
      toggleShowFilters,
      pinFilters,
      togglePinFilters,
      setFiltersHeight,
      filtersHeight,
    },
    data: { dataLoadingError },
  } = useBusinessReportContext()
  const [draftFilters, setDraftFilters] = useState(filters)
  const [tip, setTip] = useState(randomTip())

  const [dateRange, setDateRange] = useState<Range>({
    startDate: moment(filters.start).toDate(),
    endDate: moment(filters.end).toDate(),
    key: 'selection',
  })

  const drawerRef = useRef<HTMLInputElement>(null)
  const { mode: colourMode } = useContext(ThemeContext) || {}
  const updateDrawerOffset = () => {
    setFiltersHeight?.(drawerRef.current?.offsetHeight ?? 0)
  }

  useEffect(() => {
    updateDrawerOffset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerRef.current, showFilters])

  useEffect(() => {
    window.addEventListener('resize', updateDrawerOffset)
    return () => window.removeEventListener('resize', updateDrawerOffset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDraftFilters((prev) => ({
      ...prev,
      start: formatDate(dateRange.startDate) ?? prev.start,
      end: formatDate(dateRange.endDate) ?? prev.end,
    }))
  }, [dateRange])

  useEffect(() => {
    if (isLoadingData) setTip(randomTip())
  }, [isLoadingData])

  return (
    <>
      <Backdrop open={showFilters && !pinFilters} />
      <Paper
        elevation={1}
        sx={{
          position: 'absolute',
          top: showFilters || pinFilters ? topOffset : -filtersHeight,
          left: pinFilters ? '0.6rem' : '1.6rem',
          right: pinFilters ? '0.6rem' : '1.6rem',
          zIndex: 1000,
          border: !pinFilters
            ? `solid 1px ${colourMode === 'dark' ? '#696969' : '#CCCCCC'}`
            : undefined,
          borderTop: 'none',
          borderRadius: 0,
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 5,

          display: 'flex',
          flexDirection: 'column',
        }}
        ref={drawerRef}
      >
        {/* Filters */}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            rowGap: 2,
            columnGap: 2,
          }}
        >
          <ReportDateRangePicker
            tooltip="Range of questionnaire start dates"
            label="Range"
            range={dateRange}
            setRange={setDateRange}
          />

          <Paper
            sx={{
              width: { xs: undefined, md: '366px' },
              flexGrow: { xs: 1, md: 0 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!draftFilters.excludeLargeDiscounts}
                  onChange={(event) => {
                    const excludeLargeDiscounts = event.target.checked

                    setDraftFilters((prev) => {
                      if (excludeLargeDiscounts)
                        return {
                          ...prev,
                          excludeLargeDiscounts,
                        }

                      const { excludeLargeDiscounts: _, ...newState } = prev
                      return newState
                    })
                  }}
                />
              }
              label="Exclude large discounts"
            />
          </Paper>

          <SelectFilter
            label="Provinces"
            values={reportProvinces}
            selected={
              draftFilters.provinces === undefined ? [] : draftFilters.provinces
            }
            onChange={(provinces) =>
              setDraftFilters((prev) => ({
                ...prev,
                provinces:
                  provinces === []
                    ? undefined
                    : (provinces as ReportProvince[]),
              }))
            }
          />

          <SelectFilter
            label="Has Partner"
            values={reportStandardAnswers as string[]}
            selected={
              draftFilters.hasPartner === undefined
                ? []
                : (draftFilters.hasPartner as string[])
            }
            onChange={(hasPartner) =>
              setDraftFilters((prev) => ({
                ...prev,
                hasPartner:
                  hasPartner === []
                    ? undefined
                    : (hasPartner as ReportStandardAnswer[]),
              }))
            }
          />

          <SelectFilter
            label="Has Children"
            values={reportStandardAnswers as string[]}
            selected={
              draftFilters.hasChildren === undefined
                ? []
                : (draftFilters.hasChildren as string[])
            }
            onChange={(hasChildren) =>
              setDraftFilters((prev) => ({
                ...prev,
                hasChildren:
                  hasChildren === []
                    ? undefined
                    : (hasChildren as ReportStandardAnswer[]),
              }))
            }
          />

          <SelectFilter
            label="Has Pets"
            values={reportStandardAnswers as string[]}
            selected={
              draftFilters.hasPets === undefined
                ? []
                : (draftFilters.hasPets as string[])
            }
            onChange={(hasPets) =>
              setDraftFilters((prev) => ({
                ...prev,
                hasPets:
                  hasPets === []
                    ? undefined
                    : (hasPets as ReportStandardAnswer[]),
              }))
            }
          />

          <SelectFilter
            label="Gender"
            values={reportGenders as string[]}
            selected={
              draftFilters.genders === undefined
                ? []
                : (draftFilters.genders as string[])
            }
            onChange={(genders) =>
              setDraftFilters((prev) => ({
                ...prev,
                genders:
                  genders === [] ? undefined : (genders as ReportGenders[]),
              }))
            }
          />

          <ReportNumberRangeSelector
            range={[draftFilters.minAge, draftFilters.maxAge]}
            labels={['Min Age', 'Max Age', 'Exclude unset ages']}
            onChange={([minAge, maxAge]) =>
              setDraftFilters((prev) => ({
                ...prev,
                minAge,
                maxAge,
              }))
            }
            displayExcludeUnsetSelector
            excludeUnset={draftFilters.excludeMissingAges}
            setExcludeUnset={(excludeMissingAges) =>
              setDraftFilters((prev) => {
                if (excludeMissingAges) return { ...prev, excludeMissingAges }

                const { excludeMissingAges: _, ...newState } = prev
                return newState
              })
            }
          />
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            px: '1rem',
            pb: '1rem',
            pt: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column-reverse', lg: 'row' },
            justifyContent: 'space-between',
            columnGap: '1rem',
            rowGap: '1rem',
          }}
        >
          <Alert
            severity={dataLoadingError ? 'error' : 'info'}
            sx={{
              width: { xs: undefined, lg: '30rem' },
              flexGrow: { xs: 1, lg: undefined },
            }}
          >
            {dataLoadingError ?? tip}
          </Alert>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              columnGap: '1rem',
              flexGrow: '1',
            }}
          >
            <Button
              variant="outlined"
              disabled={_isEqual(unsetFilters, draftFilters)}
              onClick={() => {
                setDraftFilters(unsetFilters)
              }}
            >
              Remove All
            </Button>
            <Button
              variant="outlined"
              disabled={_isEqual(filters, draftFilters)}
              onClick={() => {
                setDraftFilters(filters)
              }}
            >
              Reset to Current
            </Button>
            <Button
              variant="outlined"
              disabled={
                _isEqual(filters, draftFilters) &&
                !dataLoadingError &&
                hasInitialData
              }
              onClick={() => {
                setFilters(draftFilters)

                if (dataLoadingError || !hasInitialData) {
                  refetchData()
                }
              }}
            >
              {dataLoadingError
                ? 'Retry'
                : hasInitialData
                ? 'Apply'
                : 'Load Data'}
            </Button>
          </Box>
        </Box>

        {/* Pin toggle */}
        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            top: '0.25rem',
            right: '0.25rem',
          }}
          onClick={togglePinFilters}
        >
          {pinFilters ? (
            <PushPinIcon fontSize="inherit" />
          ) : (
            <PushPinOutlinedIcon fontSize="inherit" />
          )}
        </IconButton>
      </Paper>

      {!pinFilters && (
        <Box
          onClick={toggleShowFilters}
          sx={{
            zIndex: 999,
            position: 'absolute',
            left: '52px',
            top: `${
              (showFilters ? filtersHeight + topOffset : topOffset) - 2
            }px`,
            color: '#FFFFFF',
            backgroundColor: '#E0662F',
            borderTop: 'none',
            width: 100,
            p: 1,
            textAlign: 'center',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,

            ':hover': {
              top: `${showFilters ? filtersHeight + topOffset : topOffset}px`,
              cursor: 'pointer',
            },
          }}
        >
          {showFilters ? 'Hide' : 'Show'} Filters
        </Box>
      )}
    </>
  )
}

export default ReportFilterMenu
