import { BarChart } from 'components'
import { CharitableGiftsResponse } from 'utils/api/data.types'

import calculateGraphData from '../utils/calculateGraphData'

const ChartsByProvince = ({
  filteredData,
}: {
  filteredData: CharitableGiftsResponse[]
}) => {
  const totalDonationsByProvince =
    calculateGraphData.totalDonationsByProvince(filteredData)

  const averageDonationsByProvince =
    calculateGraphData.averageDonationsByProvince(filteredData)

  const countOfDonationsByProvince =
    calculateGraphData.countOfDonationsByProvince(filteredData)

  return (
    <>
      <BarChart
        height="20rem"
        leftLabel="Total Donated Amount"
        data={totalDonationsByProvince}
        valueFormat="^($,.2f"
      />
      <BarChart
        height="20rem"
        leftLabel="Average Donated Amount"
        data={averageDonationsByProvince}
        valueFormat="^($,.2f"
      />
      <BarChart
        height="20rem"
        leftLabel="Donation Count"
        data={countOfDonationsByProvince}
      />
    </>
  )
}

export default ChartsByProvince
