import { BarChartData } from 'components/BarChart/BarChart'
import { CharityPartnersResponse } from 'utils/api/data.types'
import { LineChartMetaData } from 'components/LineChart/LineChart'

const totalDonationsByProvince = (data: CharityPartnersResponse[]): BarChartData[] => {
  const accumulator = {
    Alberta: {
      index: 'Alberta',
      value: 0,
    },
    'British Columbia': {
      index: 'British Columbia',
      value: 0,
    },
    Manitoba: {
      index: 'Manitoba',
      value: 0,
    },
    'New Brunswick': {
      index: 'New Brunswick',
      value: 0,
    },
    'Newfoundland and Labrador': {
      index: 'Newfoundland and Labrador',
      value: 0,
    },
    'Nova Scotia': {
      index: 'Nova Scotia',
      value: 0,
    },
    Ontario: {
      index: 'Ontario',
      value: 0,
    },
    'Prince Edward Island': {
      index: 'Prince Edward Island',
      value: 0,
    },
    Saskatchewan: { index: 'Saskatchewan', value: 0 },
  }

  data.forEach((gift) => {
    if (gift.province) {
      accumulator[gift.province as keyof typeof accumulator].value +=
        gift.amount
    }
  })

  return Object.values(accumulator)
}

const averageDonationsByProvince = (data: CharityPartnersResponse[]): BarChartData[] => {
  const accumulator = {
    Alberta: {
      index: 'Alberta',
      value: 0,
      count: 0,
      total: 0,
    },
    'British Columbia': {
      index: 'British Columbia',
      value: 0,
      count: 0,
      total: 0,
    },
    Manitoba: {
      index: 'Manitoba',
      value: 0,
      count: 0,
      total: 0,
    },
    'New Brunswick': {
      index: 'New Brunswick',
      value: 0,
      count: 0,
      total: 0,
    },
    'Newfoundland and Labrador': {
      index: 'Newfoundland and Labrador',
      value: 0,
      count: 0,
      total: 0,
    },
    'Nova Scotia': {
      index: 'Nova Scotia',
      value: 0,
      count: 0,
      total: 0,
    },
    Ontario: {
      index: 'Ontario',
      value: 0,
      count: 0,
      total: 0,
    },
    'Prince Edward Island': {
      index: 'Prince Edward Island',
      value: 0,
      count: 0,
      total: 0,
    },
    Saskatchewan: { index: 'Saskatchewan', value: 0, count: 0, total: 0 },
  }

  data.forEach((gift) => {
    const typedKey = gift.province as keyof typeof accumulator

    if (gift.province) {
      accumulator[typedKey].count += 1
      accumulator[typedKey].total += gift.amount
      accumulator[typedKey].value =
        accumulator[typedKey].total / accumulator[typedKey].count
    }
  })

  return Object.values(accumulator)
}

const countOfDonationsByProvince = (data: CharityPartnersResponse[]): BarChartData[] => {
  const accumulator = {
    Alberta: {
      index: 'Alberta',
      value: 0,
    },
    'British Columbia': {
      index: 'British Columbia',
      value: 0,
    },
    Manitoba: {
      index: 'Manitoba',
      value: 0,
    },
    'New Brunswick': {
      index: 'New Brunswick',
      value: 0,
    },
    'Newfoundland and Labrador': {
      index: 'Newfoundland and Labrador',
      value: 0,
    },
    'Nova Scotia': {
      index: 'Nova Scotia',
      value: 0,
    },
    Ontario: {
      index: 'Ontario',
      value: 0,
    },
    'Prince Edward Island': {
      index: 'Prince Edward Island',
      value: 0,
    },
    Saskatchewan: { index: 'Saskatchewan', value: 0 },
  }

  data.forEach((gift) => {
    if (gift.province) {
      accumulator[gift.province as keyof typeof accumulator].value += 1
    }
  })

  return Object.values(accumulator)
}

const totalDonationsByAge = (data: CharityPartnersResponse[]): LineChartMetaData => {
  const accumulator: {
    [key: string]: {
      x: string
      y: number
    }
  } = {}

  const maxAge =
    [...data]
      .filter((gift) => !!gift.age)
      .sort((a, b) => (b.age || 0) - (a.age || 0))
      .splice(0, 1)[0]?.age || 100

  for (let i = 18; i <= maxAge; i++) {
    Object.assign(accumulator, { [i.toString()]: { x: i.toString(), y: 0 } })
  }

  data.forEach((gift) => {
    if (gift.age) {
      accumulator[gift.age.toString()].y += gift.amount
    }
  })

  return { id: 'Age', data: Object.values(accumulator) }
}

const averageDonationsByAge = (data: CharityPartnersResponse[]): LineChartMetaData => {
  const accumulator: {
    [key: string]: {
      x: string
      y: number
      count: number
      total: number
    }
  } = {}

  const maxAge =
    [...data]
      .filter((gift) => !!gift.age)
      .sort((a, b) => (b.age || 0) - (a.age || 0))
      .splice(0, 1)[0]?.age || 100


  for (let i = 18; i <= maxAge; i++) {
    Object.assign(accumulator, { [i.toString()]: { x: i.toString(), y: 0, total: 0, count: 0 } })
  }

  data.forEach((gift) => {
    if (gift.age) {
      accumulator[gift.age.toString()].total += gift.amount
      accumulator[gift.age.toString()].count += 1
      accumulator[gift.age.toString()].y =
        accumulator[gift.age.toString()].total /
        accumulator[gift.age.toString()].count
    }
  })

  return { id: 'Age', data: Object.values(accumulator) }
}

const countOfDonationsByAge = (data: CharityPartnersResponse[]): LineChartMetaData => {
  const accumulator: {
    [key: string]: {
      x: string
      y: number
    }
  } = {}

  const maxAge =
    [...data]
      .filter((gift) => !!gift.age)
      .sort((a, b) => (b.age || 0) - (a.age || 0))
      .splice(0, 1)[0]?.age || 100

  for (let i = 18; i <= maxAge; i++) {
    Object.assign(accumulator, { [i.toString()]: { x: i.toString(), y: 0 } })
  }

  data.forEach((gift) => {
    if (gift.age) {
      accumulator[gift.age.toString()].y += 1
    }
  })

  return { id: 'Age', data: Object.values(accumulator) }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  totalDonationsByProvince,
  averageDonationsByProvince,
  countOfDonationsByProvince,

  totalDonationsByAge,
  averageDonationsByAge,
  countOfDonationsByAge
}