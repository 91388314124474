import { useState } from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import { Box, Divider, useMediaQuery, Theme, Alert } from '@mui/material'

import { format } from 'utils'
import { InputSelect } from 'components'
import { CharitableGiftsResponse } from 'utils/api/data.types'

import getTotalledData from '../utils/getTotalledData'
import StatsFilter, { Filters } from '../../components/StatsFilter'
import SingleStat from 'views/AnalyticsAndReportsViews/components/SingleStat'
import LeaderboardStat from 'views/AnalyticsAndReportsViews/components/LeaderboardStat'

import ChartsByProvince from './ChartsByProvince'
import ChartsByAge from './ChartsByAge'

enum DataBy {
  Province = 'province',
  Age = 'age',
}

const CharitableGiftsStats = ({
  data,
  filters,
  updateFilters,
  initialFilterData,
}: {
  filters: Filters
  initialFilterData: Filters
  data: CharitableGiftsResponse[]
  updateFilters: (newFilter: object) => void
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const [dataBy, setDataBy] = useState<DataBy>(DataBy.Province)

  const clonedData = _cloneDeep(data)
  const filteredData = clonedData.filter((gift) => !gift.isPercent)
  const totalledFilteredData = getTotalledData({ data: filteredData })

  const topDonations = [...filteredData]
    .sort((a, b) => b.amount - a.amount)
    .slice(0, 5)

  const topCharityByTotal = [...totalledFilteredData]
    .sort((a, b) => b.amount - a.amount)
    .slice(0, 5)

  const topCharityByDonationCount = [...totalledFilteredData]
    .sort((a, b) => b.dollarCount - a.dollarCount)
    .slice(0, 5)

  const topCharityByAverageDonation = [...totalledFilteredData]
    .sort((a, b) => b.dollarAverage - a.dollarAverage)
    .slice(0, 5)

  const totalDonated = [...filteredData]
    .map((gift) => gift.amount)
    .reduce((a, b) => a + b, 0)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        rowGap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <Alert severity="info">
          Just a heads up! This data currently contains only donations of a
          dollar value, no percentage donations are included.
        </Alert>
        <StatsFilter
          initialFilterData={initialFilterData}
          filters={filters}
          updateFilters={updateFilters}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: '1fr',
          rowGap: 2,
          columnGap: 2,
        }}
      >
        <SingleStat
          title="Total Donated"
          value={format.currency(totalDonated)}
        />
        <SingleStat
          title="Number of Donations"
          value={filteredData.length.toString()}
        />
        <SingleStat
          title="Average Donation"
          value={format.currency(totalDonated / filteredData.length)}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
          gridTemplateRows: '1fr',
          rowGap: 2,
          columnGap: 2,
        }}
      >
        <LeaderboardStat
          title="Top donations"
          values={topDonations.map((gift) => ({
            amount: format.currency(gift.amount),
            attribution: gift.name,
          }))}
        />
        <LeaderboardStat
          title="Top charities by total value"
          values={topCharityByTotal.map((charity) => ({
            amount: format.currency(charity.amount),
            attribution: charity.name,
          }))}
        />
        <LeaderboardStat
          title="Top charities by donation count"
          values={topCharityByDonationCount.map((charity) => ({
            amount: charity.dollarCount.toString(),
            attribution: charity.name,
          }))}
        />
        <LeaderboardStat
          title="Top charities by average donation"
          values={topCharityByAverageDonation.map((charity) => ({
            amount: format.currency(charity.dollarAverage),
            attribution: charity.name,
          }))}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <Box sx={{ width: '18rem' }}>
          <InputSelect
            fullWidth
            label="Display Data By"
            value={dataBy}
            onChange={(value) => setDataBy(value as DataBy)}
            options={[
              { label: 'Province', value: DataBy.Province },
              { label: 'Age', value: DataBy.Age },
            ]}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
          }}
        >
          {dataBy === DataBy.Province && (
            <ChartsByProvince filteredData={filteredData} />
          )}

          {dataBy === DataBy.Age && <ChartsByAge filteredData={filteredData} />}
        </Box>
      </Box>
    </Box>
  )
}

export default CharitableGiftsStats
