import { useContext, useState } from 'react'

import {
  InputField,
  InputSelect,
  TitledWrapper,
  ToggleButtons,
} from 'components'
import { dates, useRoles, useFormData, UserContext } from 'utils'
import { Role } from 'utils/api/role.api'
import { Box, Button } from '@mui/material'
import ConfirmationModal from './basicUserData/ConfirmationModal'
import { UpdateUserRequest } from 'utils/api/user.types'

const BasicUserDetails = () => {
  const { data: userRoles } = useRoles()
  const { data } = useContext(UserContext) || {}

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationData, setConfirmationData] = useState<UpdateUserRequest>(
    {},
  )

  const [formData, updateFormData] = useFormData<UpdateUserRequest>({
    accountLocked: data?.accountLocked || false,
    role: data?.role || Role.User,
  })

  return data ? (
    <>
      <ConfirmationModal
        onClose={() => {
          setShowConfirmationModal(false)
          setConfirmationData({})
        }}
        show={showConfirmationModal}
        updateRequestData={confirmationData}
        userId={data._id}
        userEmail={data.email}
      />
      <InputField label="ID" value={data._id} disabled />
      <InputField
        label="Created at"
        value={dates.format(data.createdAt)}
        disabled
      />
      <InputField label="Email" value={data.email} disabled fullWidth />

      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
        <InputField
          label="Postal Code"
          value={data.postalCode}
          disabled
          fullWidth
        />
        <Button
          sx={{ width: '10rem' }}
          variant="outlined"
          disabled={!data.postalCode}
          onClick={() => {
            window.open(
              `https://maps.google.com/?q=${encodeURIComponent(
                `${data.postalCode}, Canada` || '',
              )}`,
              '_blank',
            )
          }}
        >
          View on Map
        </Button>
      </Box>
      {data.partner && (
        <InputField label="Partner ID" value={data.partner} disabled />
      )}

      <ToggleButtons
        fullWidth
        value={data.isPartner}
        buttons={[
          { label: 'Non-partner', value: false },
          { label: 'Partner', value: true },
        ]}
        disabled
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
        <ToggleButtons
          fullWidth
          value={
            formData.accountLocked === undefined
              ? data.accountLocked
              : formData.accountLocked
          }
          buttons={[
            { label: 'Account Unlocked', value: false },
            { label: 'Account Locked', value: true },
          ]}
          disabled={!userRoles?.includes(Role.Admin)}
          onChange={(accountLocked) => updateFormData({ accountLocked })}
        />
        {formData.accountLocked !== data.accountLocked && (
          <Button
            variant="outlined"
            onClick={() => {
              setConfirmationData({
                accountLocked: formData.accountLocked,
              })
              setShowConfirmationModal(true)
            }}
          >
            Save
          </Button>
        )}
      </Box>
      <InputField
        label="How did you hear about us?"
        value={data.howDidYouHearAboutUs}
        disabled
      />
      <ToggleButtons
        fullWidth
        value={data.mailingList}
        buttons={[
          { label: 'On mailing list', value: true },
          { label: 'Not on mailing list', value: false },
        ]}
        disabled
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
        <InputSelect
          fullWidth
          disabled={!userRoles?.includes(Role.SuperAdmin)}
          label="User role"
          value={formData.role || data.role}
          options={[
            { label: 'User', value: Role.User },
            { label: 'Admin', value: Role.Admin },
            { label: 'Super Admin', value: Role.SuperAdmin },
          ]}
          onChange={(role) => updateFormData({ role })}
        />
        {formData.role !== data.role && (
          <Button
            variant="outlined"
            onClick={() => {
              setConfirmationData({
                role: formData.role,
              })
              setShowConfirmationModal(true)
            }}
          >
            Save
          </Button>
        )}
      </Box>
      <InputField
        label="Updates expiry"
        value={
          data.updatesExpiry.will === null ? 'None' : "You shouldn't see this"
        }
        disabled
      />
      <InputField
        disabled
        label="Questionnaire first completed"
        value={
          data.questionnaire.questionnaireFirstCompleted
            ? dates.format(data.questionnaire.questionnaireFirstCompleted)
            : 'N/A'
        }
      />
      <TitledWrapper title="A/B Test" sx={{ py: 1 }}>
        <ToggleButtons
          fullWidth
          value={data.questionnaire.abTest}
          buttons={[
            { label: 'A', value: 'a' },
            { label: 'B', value: 'b' },
          ]}
          disabled
        />
      </TitledWrapper>

      <InputField
        disabled
        label="Ref"
        value={data.questionnaire.referrer?.ref}
      />
      <InputField
        disabled
        label="Reftag"
        value={data.questionnaire.referrer?.reftag}
      />
    </>
  ) : (
    <></>
  )
}

export default BasicUserDetails
