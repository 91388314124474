import { GridRowsProp } from '@mui/x-data-grid'

import { DistributionCharitiesResponse } from 'utils/api/data.types'
import getTotalledData from './getTotalledData'

export interface DistributionCharitiesGiftsTotals extends DistributionCharitiesResponse {
  count: number
  averagePercentage: number
  totalPercentage: number
  averageAge: number
  totalAge: number
  ageCount: number
}

const useTotalledDataGridRows = (
  data?: DistributionCharitiesResponse[]
): GridRowsProp => {
  if (data) {
    const summedData = getTotalledData(data)

    return summedData.map((gift, index) => ({
      id: index,
      ...gift,
    }))
  } else {
    return []
  }
}

export default useTotalledDataGridRows