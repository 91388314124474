import { Children } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { Box, List, ListItem, ListItemText } from '@mui/material'

import { Text, TitledWrapper } from 'components'
import { dates, spreadSx, styles } from 'utils'
import { UserDetails } from 'utils/api/user.types'

const DocumentGenerations = ({ data }: { data: UserDetails }) => {
  return (
    <>
      <TitledWrapper
        title={`Will/POA Document Generations (${data.timesGenerated.will.length} total)`}
      >
        <List
          dense
          sx={spreadSx([
            {
              height: '10rem',
              overflow: 'scroll',
            },
            styles.scrollbar,
          ])}
        >
          {Children.toArray(
            data.timesGenerated.will.map((timestamp) => (
              <ListItem>
                <ListItemText>{dates.format(timestamp)}</ListItemText>
              </ListItem>
            )),
          )}
          {_isEmpty(data.timesGenerated.will) && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                py: 2,
                color: 'text.disabled',
              }}
            >
              <Text variant="subtitle2" align="center">
                No Will/POA documents generated
              </Text>
            </Box>
          )}
        </List>
      </TitledWrapper>
      <TitledWrapper
        title={`Social Document Generations (${data.timesGenerated.social.length} total)`}
      >
        <List
          dense
          sx={spreadSx([
            {
              height: '10rem',
              overflow: 'scroll',
            },
            styles.scrollbar,
          ])}
        >
          {Children.toArray(
            data.timesGenerated.social.map((timestamp) => (
              <ListItem>
                <ListItemText>{dates.format(timestamp)}</ListItemText>
              </ListItem>
            )),
          )}
          {_isEmpty(data.timesGenerated.social) && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                py: 2,
                color: 'text.disabled',
              }}
            >
              <Text variant="subtitle2" align="center">
                No social documents generated
              </Text>
            </Box>
          )}
        </List>
      </TitledWrapper>
    </>
  )
}

export default DocumentGenerations
