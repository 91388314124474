
export enum Environment {
  Production = 'production',
  Staging = 'staging',
  Dev = 'dev'
}

const getEnv = (): Environment => {
  if (window.location.hostname === 'admin.epiloguewills.com') {
    return Environment.Production
  } else if (window.location.hostname === 'admin-staging.epiloguewills.com') {
    return Environment.Staging
  } else {
    return Environment.Dev
  }
}

export default getEnv