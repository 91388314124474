
const PROMO_CODE = /^[\d&A-Za-z-]+$/

const CITY_PROVINCECODE = /^[^\da-z]+, [A-Z]{2}$/

const URL =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\da-z]+([.-][\da-z]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/

export const EMAIL =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  URL,
  EMAIL,
  PROMO_CODE,
  CITY_PROVINCECODE
}