import { Checkbox, FormControlLabel, Paper, TextField } from '@mui/material'
import { Box } from '@mui/system'

type RangeArray = [number | undefined, number | undefined]

const ReportNumberRangeSelector = ({
  range,
  labels,
  onChange,
  displayExcludeUnsetSelector = false,
  excludeUnset,
  setExcludeUnset,
}: {
  range: RangeArray
  labels: [string, string, string?]
  onChange: (range: RangeArray) => void
  displayExcludeUnsetSelector?: boolean
  excludeUnset?: boolean
  setExcludeUnset?: (excludeUnset: boolean) => void
}) => {
  const [start, end] = range
  const [startLabel, endLabel, unsetLabel] = labels

  return (
    <Paper
      sx={{
        p: '0.5rem',
        pt: displayExcludeUnsetSelector ? '0.9rem' : undefined,
        width: { xs: undefined, md: 350 },
        flexGrow: { xs: 1, md: 0 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          width: { xs: 350, md: undefined },
        }}
      >
        <TextField
          fullWidth
          type="number"
          value={start === undefined ? '' : start}
          label={startLabel}
          error={start === undefined && end !== undefined}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            const value =
              event.target.value && parseInt(event.target.value) >= 0
                ? parseInt(event.target.value)
                : undefined

            onChange([value, end])
          }}
        />
        <TextField
          fullWidth
          type="number"
          value={end === undefined ? '' : end}
          label={endLabel}
          error={
            (start === undefined && end !== undefined) ||
            (start !== undefined && end !== undefined && end < start)
          }
          sx={{ width: '100%' }}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            const value =
              event.target.value && parseInt(event.target.value) >= 0
                ? parseInt(event.target.value)
                : undefined

            onChange([start, value])
          }}
        />
      </Box>
      {displayExcludeUnsetSelector && setExcludeUnset && (
        <FormControlLabel
          sx={{
            position: 'relative',
            top: '0.2rem',
          }}
          control={
            <Checkbox
              checked={!!excludeUnset}
              onChange={(event) => {
                setExcludeUnset(event.target.checked)
              }}
            />
          }
          label={unsetLabel ?? 'Exclude unset values'}
        />
      )}
    </Paper>
  )
}

export default ReportNumberRangeSelector
