const standardRanges = [
  { min: 18, max: 25 },
  { min: 26, max: 35 },
  { min: 36, max: 45 },
  { min: 46, max: 55 },
  { min: 56, max: 65 },
  { min: 66, max: 75 },
  { min: 76, max: 85 },
  { min: 85, max: 999 },
]

const getAgeRange = <KeyType = string>(
  age: number,
  ranges = standardRanges,
) => {
  for (let i = 0; i < ranges.length; i++) {
    const { min, max } = ranges[i]
    if (age >= min && age <= max) {
      return {
        key: (max === 999 ? `${min}+` : `${min}-${max}`) as unknown as KeyType,
        min,
        max,
      }
    }
  }

  throw new Error(`Age ${age} is not in any known range`)
}

export default getAgeRange
