import _capitalize from 'lodash/capitalize'
import { GridColDef } from '@mui/x-data-grid'

import { dates, format, gridComparator } from 'utils'

const allDataColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    valueFormatter: ({ value }) => value.toUpperCase(),
    description: 'Name of charity entered or selected by the user',
  },
  {
    field: 'number',
    headerName: 'Number',
    width: 150,
    valueFormatter: ({ value }) => (value ? value : 'N/A'),
    description: 'Registration number of charity if available',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    sortComparator: gridComparator.formattedAmount,
    description: 'Total value of donation',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'mostRecentGenerationDate',
    headerName: 'Latest Doc Generated',
    width: 200,
    valueFormatter: ({ value }) => (value ? dates.format(value) : 'N/A'),
    description: 'Date document including donation was generated',
  },
  {
    field: 'coreProductPaymentDate',
    headerName: 'Payment Date',
    width: 200,
    valueFormatter: ({ value }) => (value ? dates.format(value) : 'N/A'),
    description: 'Date user made their original purchase',
  },
  {
    field: 'gender',
    headerName: 'Gender',
    width: 75,
    description: 'User\'s gender',
    valueFormatter: ({ value }) => (value ? _capitalize(value) : undefined),
  },
  {
    field: 'province',
    headerName: 'Province',
    width: 150,
    description: 'Province in which user resides',
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 75,
    description: 'Age of user',
  },
  {
    field: 'hasPartner',
    headerName: 'Has Partner',
    width: 150,
    description: 'If user has a partner',
    valueFormatter: ({ value }) => (value ? _capitalize(value) : undefined),
  },
  {
    field: 'hasChildren',
    headerName: 'Has Children',
    width: 150,
    description: 'If user has any children',
    valueFormatter: ({ value }) => (value ? _capitalize(value) : undefined),
  },
]

const totalledDataColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    description: 'Name of charity entered or selected by the user',
  },
  {
    field: 'number',
    headerName: 'Number',
    width: 150,
    valueFormatter: ({ value }) => (value ? value : 'N/A'),
    description: 'Registration number of charity if available',
  },
  {
    field: 'averageAge',
    headerName: 'Average Age',
    width: 100,
    description: 'Average age of donor',
    valueFormatter: ({ value }) => (value ? value.toFixed(1) : undefined),
    type: 'number'
  },
  {
    field: 'totalDonations',
    headerName: 'Total Donations',
    width: 125,
    description: 'Total count of all donation types',
    type: 'number'
  },
  {
    field: 'dollarCount',
    headerName: 'Dollar Donation Count',
    width: 200,
    description: 'Total count of dollar value donations',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'dollarAverage',
    headerName: 'Dollar Average',
    width: 150,
    sortComparator: gridComparator.formattedAmount,
    valueFormatter: ({ value }) => format.currency(value),
    description: 'Average amount of dollar value donations',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'amount',
    headerName: 'Total Dollar Amount',
    width: 150,
    sortComparator: gridComparator.formattedAmount,
    valueFormatter: ({ value }) => format.currency(value),
    description: 'Total amount of dollar value donations',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'percentCount',
    headerName: 'Percent Count',
    width: 150,
    description: 'Total count of percent of estate donations',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'percentAverage',
    headerName: 'Percent Average',
    width: 150,
    valueFormatter: ({ value }) => `${value.toFixed(2)}%`,
    description: 'Average amount of percent of estate donations',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'countGenerated',
    headerName: 'Count Generated',
    width: 150,
    description: 'Count of donors who have generated their documents',
    type: 'number'
  },
  {
    field: 'countPaid',
    headerName: 'Count Paid',
    width: 150,
    description: 'Count of donors who have paid',
    type: 'number'
  },
  {
    field: 'countHasPartner',
    headerName: 'Count Has Partner',
    width: 150,
    description: 'Count of donors who have a partner',
    type: 'number'
  },
  {
    field: 'countNoPartner',
    headerName: 'Count No Partner',
    width: 150,
    description: 'Count of donors who do not have a partner',
    type: 'number'
  },
  {
    field: 'countHasChildren',
    headerName: 'Count Has Children',
    width: 150,
    description: 'Count of donors who have children',
    type: 'number'
  },
  {
    field: 'countNoChildren',
    headerName: 'Count No Children',
    width: 150,
    description: 'Count of donors who have no children',
    type: 'number'
  },
  {
    field: 'countGenderMale',
    headerName: 'Count Male Donors',
    width: 150,
    description: 'Count of donors who identify as Male',
    type: 'number'
  },
  {
    field: 'countGenderFemale',
    headerName: 'Count Female Donors',
    width: 150,
    description: 'Count of donors who identify as Female',
    type: 'number'
  },
  {
    field: 'countGenderOther',
    headerName: 'Count Other Donors',
    width: 150,
    description: 'Count of donors who identify as neither Male nor Female',
    type: 'number'
  },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  allDataColumns,
  totalledDataColumns
}