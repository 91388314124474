import { CharitableGiftsResponse } from 'utils/api/data.types'

export interface CharitableGiftsTotals extends CharitableGiftsResponse {
  totalDonations: number

  dollarCount: number
  dollarAverage: number

  percentCount: number
  percentAverage: number
  totalPercent: number

  averageAge?: number
  totalAge: number

  countGenerated: number
  countPaid: number
  countHasPartner: number
  countNoPartner: number
  countHasChildren: number
  countNoChildren: number
  countGenderMale: number
  countGenderFemale: number
  countGenderOther: number
}

const getTotalledData = ({
  data,
}: {
  data?: CharitableGiftsResponse[]
}): CharitableGiftsTotals[] => {
  if (data) {
    const summedData: { [key: string]: CharitableGiftsTotals } = {}

    data
      .forEach((gift) => {
        const uppercaseGiftName = gift.name.toUpperCase()

        if (Object.keys(summedData).includes(uppercaseGiftName)) {
          if (!summedData[uppercaseGiftName].number && gift.number) {
            summedData[uppercaseGiftName].number = gift.number
          }

          if (summedData[uppercaseGiftName].isPercent) {
            // PERCENT DONATION
            summedData[uppercaseGiftName].percentCount += 1
            summedData[uppercaseGiftName].totalPercent += gift.amount

            summedData[uppercaseGiftName].percentAverage =
              summedData[uppercaseGiftName].totalPercent /
              summedData[uppercaseGiftName].percentCount
          } else {
            // DOLLAR DONATION
            summedData[uppercaseGiftName].dollarCount += 1
            summedData[uppercaseGiftName].amount += gift.amount

            summedData[uppercaseGiftName].dollarAverage =
              summedData[uppercaseGiftName].amount /
              summedData[uppercaseGiftName].dollarCount
          }

          if (gift.age) {
            summedData[uppercaseGiftName].totalAge += gift.age

            summedData[uppercaseGiftName].averageAge =
              summedData[uppercaseGiftName].totalAge /
              (summedData[uppercaseGiftName].percentCount + summedData[uppercaseGiftName].dollarCount)
          }

          if (gift.hasGenerated)
            summedData[uppercaseGiftName].countGenerated += 1

          if (gift.hasPaid)
            summedData[uppercaseGiftName].countPaid += 1

          if (gift.hasPartner === 'yes')
            summedData[uppercaseGiftName].countHasPartner += 1

          if (gift.hasPartner === 'no')
            summedData[uppercaseGiftName].countNoPartner += 1

          if (gift.hasChildren === 'yes')
            summedData[uppercaseGiftName].countHasChildren += 1

          if (gift.hasChildren === 'no')
            summedData[uppercaseGiftName].countNoChildren += 1

          if (gift.gender === 'male')
            summedData[uppercaseGiftName].countGenderMale += 1

          if (gift.gender === 'female')
            summedData[uppercaseGiftName].countGenderFemale += 1

          if (!!gift.gender && gift.gender !== 'male' && gift.gender !== 'female')
            summedData[uppercaseGiftName].countGenderOther += 1


        } else {
          if (gift.isPercent) {
            summedData[uppercaseGiftName] = {
              ...gift,
              name: uppercaseGiftName,
              percentCount: 1,
              dollarCount: 0,
              amount: 0,
              dollarAverage: 0,
              totalPercent: gift.amount,
              percentAverage: gift.amount,
              averageAge: gift.age ? gift.age : undefined,
              totalAge: gift.age ? gift.age : 0,
              totalDonations: 1,

              countGenerated: gift.hasGenerated ? 1 : 0,
              countPaid: gift.hasPaid ? 1 : 0,
              countHasPartner: gift.hasPartner === 'yes' ? 1 : 0,
              countNoPartner: gift.hasPartner === 'no' ? 1 : 0,
              countHasChildren: gift.hasChildren === 'yes' ? 1 : 0,
              countNoChildren: gift.hasChildren === 'no' ? 1 : 0,
              countGenderMale: gift.gender === 'male' ? 1 : 0,
              countGenderFemale: gift.gender === 'female' ? 1 : 0,
              countGenderOther: !!gift.gender &&
                gift.gender !== 'male' &&
                gift.gender !== 'female'
                ? 1 : 0,
            }
          } else {
            summedData[uppercaseGiftName] = {
              ...gift,
              name: uppercaseGiftName,
              dollarCount: 1,
              percentCount: 0,
              dollarAverage: gift.amount,
              totalPercent: 0,
              percentAverage: 0,
              averageAge: gift.age ? gift.age : undefined,
              totalAge: gift.age ? gift.age : 0,
              totalDonations: 1,

              countGenerated: gift.hasGenerated ? 1 : 0,
              countPaid: gift.hasPaid ? 1 : 0,
              countHasPartner: gift.hasPartner === 'yes' ? 1 : 0,
              countNoPartner: gift.hasPartner === 'no' ? 1 : 0,
              countHasChildren: gift.hasChildren === 'yes' ? 1 : 0,
              countNoChildren: gift.hasChildren === 'no' ? 1 : 0,
              countGenderMale: gift.gender === 'male' ? 1 : 0,
              countGenderFemale: gift.gender === 'female' ? 1 : 0,
              countGenderOther: !!gift.gender &&
                gift.gender !== 'male' &&
                gift.gender !== 'female'
                ? 1 : 0,
            }
          }
        }
      })

    return Object.values(summedData)
  } else {
    return []
  }
}

export default getTotalledData