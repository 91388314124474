import { ReactText } from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box, SxProps, Theme, Breakpoint, useMediaQuery } from '@mui/material'

import { spreadSx } from 'utils'
import { Text } from 'components'
import { GetUsersSortMethod } from 'utils/api/user.types'
import { GetPromoCodeSortMethod } from 'utils/api/promoCode.api'
import { GetCharityPartnersSortMethod } from 'utils/api/charityPartner.api'

interface TableHeaderCellProps {
  children: ReactText
  sx?: SxProps
  sort?:
    | GetPromoCodeSortMethod
    | GetUsersSortMethod
    | GetCharityPartnersSortMethod
  setSort?: Function
  sortAsc?:
    | GetPromoCodeSortMethod
    | GetUsersSortMethod
    | GetCharityPartnersSortMethod
  sortDes?:
    | GetPromoCodeSortMethod
    | GetUsersSortMethod
    | GetCharityPartnersSortMethod
  width?: number
  hideBelowBreakpoint?: Breakpoint
}

const TableHeaderCell = ({
  sx,
  sort,
  setSort,
  sortAsc,
  sortDes,
  children,
  width = 1,
  hideBelowBreakpoint,
}: TableHeaderCellProps) => {
  const useSort = sort && setSort && sortAsc && sortDes
  const isHidden = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(hideBelowBreakpoint || 'md'),
  )

  const onClick = () => {
    if (!useSort) return

    if (sort === sortDes) {
      setSort(sortAsc)
    } else {
      setSort(sortDes)
    }
  }

  return (
    <Box
      sx={spreadSx([
        {
          p: 1,
          display: isHidden && hideBelowBreakpoint ? 'none' : 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          cursor: useSort ? 'pointer' : 'auto',
          flex: width,
        },
        sx,
      ])}
      onClick={useSort ? onClick : undefined}
    >
      {useSort && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pl: 1,
          }}
        >
          <ArrowDropUpIcon
            sx={{
              color: (theme: Theme) =>
                sort === sortAsc
                  ? theme.palette.primary.main
                  : theme.palette.grey[300],
              mb: '-0.5rem',
            }}
          />
          <ArrowDropDownIcon
            sx={{
              color: (theme: Theme) =>
                sort === sortDes
                  ? theme.palette.primary.main
                  : theme.palette.grey[300],
              mt: '-0.5rem',
            }}
          />
        </Box>
      )}
      <Text preset="tablehead" sx={{ flexGrow: 1 }}>
        {children}
      </Text>
    </Box>
  )
}

export default TableHeaderCell
