import { Role } from './role.api'

export type GetUsersLimit = 10 | 25 | 50

export enum GetUsersSortMethod {
  DateNew = 'dateNew',
  DateOld = 'dateOld',
  EmailAZ = 'emailAZ',
  EmailZA = 'emailZA',
}

export interface GetUsersParams {
  page: number
  limit: GetUsersLimit,
  q?: string,
  sort?: GetUsersSortMethod
}

export enum UserEndpoint {
  User = '/user',
  Login = '/user/login',
  Me = '/user/me',
  ID = '/user/id',
  Logout = '/user/logout',
  ForgotPassword = '/user/forgot_password'
}

export interface UpdateUserRequest {
  accountLocked?: boolean,
  role?: Role
}

export interface SendResetPasswordResponse {
  errorMessage?: string
}

export interface UsersSearchResponse {
  totalPages: string
  items: User[]
}

export interface User {
  _id: string
  email: string
  password: string
  role: Role
  accountLocked?: boolean
  updatesExpiry: UpdatesExpiry
  timesGenerated?: TimesGenerated
  addOns?: any[]
  questionnaire: string
  mailingList?: boolean
  isPartner: boolean
  documents?: Document[]
  socialDocuments?: any[]
  payments?: any[]
  codes: Code[]
  createdAt: Date
  __v: number
  bonusDocsAnswerStore?: BonusDocsAnswerStore
  howDidYouHearAboutUs?: string
  isVerified?: boolean
  partner?: string
  corpTrusteeDetails?: CorpTrusteeDetails
}

export interface BonusDocsAnswerStore {
}

export interface Code {
  label: string
  code: string
  id: string
}

export interface Document {
  docType: string
  key: string
  previewKey: string
  bonus: boolean
  tagAlong: boolean
  signed: boolean
  id: string
  created: Date
}

export interface Payment {
  chargeId: string
  productId: number
  price?: number
  discount: number
  promoCodeUsed: PromoCodeUsed
  docTypes: string[]
  addOns: PaymentAddOn[]
  partnerPaidFor: boolean
  referrer: Referrer
  id: string
  created: Date
}

export interface PromoCodeUsed {
  id: string
  isPercent: boolean
  expiryDate: Date
  isActive: boolean
  oneTimeUse: boolean
  amount: number
  code: string
  v: number
}

export interface Referrer {
  ref: string
  reftag: string
}

export interface TimesGenerated {
  will: Date[]
  social: Date[]
}

export interface UpdatesExpiry {
  will: null
}


// User Details
export interface UserDetails {
  _id: string
  email: string
  role: Role
  accountLocked: boolean
  updatesExpiry: UpdatesExpiry
  timesGenerated: TimesGenerated
  addOns: UserDetailsAddOn[]
  questionnaire: Questionnaire
  mailingList: boolean
  isPartner: boolean
  documents: Document[]
  socialDocuments: SocialDocument[]
  payments: Payment[]
  codes: Code[]
  createdAt: Date
  bonusDocsAnswerStore: BonusDocsAnswerStore
  howDidYouHearAboutUs: string
  partner?: string
  postalCode?: string
}

export interface UserDetailsAddOn {
  type: string
  oneTimeUse: boolean
  _id: string
}

export interface BonusDocsAnswerStore {
  wishes: Wishes
}

export interface Wishes {
  organs: string
  body: string
  ashes: string
  ceremony: string
  additionalWishes: string
}

export interface Code {
  label: string
  code: string
  _id: string
}

export interface Document {
  docType: string
  key: string
  bonus: boolean
  tagAlong: boolean
  signed: boolean
  _id: string
  created: Date
}

export interface Payment {
  chargeId: string
  productId: number
  price?: number
  discount: number
  docTypes: string[]
  addOns: PaymentAddOn[]
  partnerPaidFor: boolean
  referrer: Referrer
  _id: string
  created: Date
}

export interface PaymentAddOn {
  type: string
  price: number
  partnerPaidFor: boolean
  oneTimeUse: boolean
  _id: string
}

export interface Referrer {
  ref: string
  reftag: string
}

export interface Questionnaire {
  _id: string
  answerCache: object // Non-typescript
  answerStoreUpdatedSinceGenerate: boolean
  socialAnswerStoreUpdatedSinceGenerate: boolean
  questionnairesStarted: string[]
  accountCreated: boolean
  referrer: Referrer
  referrerInitiated: boolean
  answerStore: object // Non-typescript
  socialAnswerStore: object // Non-typescript
  token: string
  abTest: string
  createdAt: Date
  __v: number
  questionnaireFirstCompleted: Date
}

export interface SocialDocument {
  docType: string
  key: string
  isFree: boolean
  _id: string
  created: Date
}

export interface TimesGenerated {
  will: Date[]
  social: Date[]
}

export interface UpdatesExpiry {
  will: null
}

export interface Child {
  isStep: boolean
  id: string
  dob: string
  name: string
}

export interface Pet {
  id: string
  name: string
  type: string
}

export interface CharitableGiftDetails {
  type: string
  relationship: string
  id: string
  isPercent: boolean
  name: string
  number?: string
  location?: string
  amount: string
}

export interface GiftDetails {
  id: string
  description: string
  recipient: string
  relationship: string
}

export interface RemoteDistribution {
  type: string
  name: string
  portions: string
  id: string
  relationship: string
  percentage: string
  predeceasedSplit?: string
  number?: string
  location?: string
}

export interface GetMeResponse {
  _id: string
  email: string
  isPartner: boolean
  payments: Payment[]
  mailingList: boolean
  questionnaire: Questionnaire
  bonusDocsAnswerStore: BonusDocsAnswerStore
  addOns: any[]
}

export interface CorpTrusteeDetails {
  ref: string
  role: string
  agreeDate: string
  legalName: string
}