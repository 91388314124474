import { Paper } from '@mui/material'
import { useContext } from 'react'
import { DatumValue, ValueFormat } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import { LegendProps } from '@nivo/legends'

import { ThemeContext } from 'utils'

interface LineChartProps {
  height: string
  leftLabel: string
  bottomLabel: string
  data: LineChartMetaData[]
  xFormat?: ValueFormat<DatumValue, void>
  yFormat?: ValueFormat<DatumValue, void>
  isInteractive?: boolean
  displayLegend?: boolean
}

export interface LineChartMetaData {
  id: string
  color?: string
  data: LineChartData[]
}

export interface LineChartData {
  x: string
  y: number
}

const colors = ['#E37645', '#221C54', '#87B38D', '#1D84B5', '#A491D3']

const LineChart = ({
  data,
  height,
  leftLabel,
  bottomLabel,
  xFormat,
  yFormat,
  isInteractive = false,
  displayLegend = false,
}: LineChartProps) => {
  const { mode: colourMode } = useContext(ThemeContext) || {}

  const legend: LegendProps[] | undefined = displayLegend
    ? [
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -40,
          itemWidth: Math.max(...data.map((d) => d.id.length)) * 6.5 + 20,
          itemHeight: 20,
          itemsSpacing: 4,
          symbolSize: 20,
          symbolShape: 'circle',
          itemDirection: 'left-to-right',
          itemTextColor: colourMode === 'dark' ? '#FFFFFF' : '#000000',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]
    : undefined

  return (
    <Paper elevation={5} sx={{ height, pt: '1rem' }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 50, bottom: 50, left: 110 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        colors={data.map((d, i) => d.color ?? colors[i])}
        isInteractive={isInteractive}
        animate={true}
        axisTop={null}
        axisRight={null}
        theme={{
          textColor: colourMode === 'dark' ? '#FFFFFF' : '#000000',
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: bottomLabel,
          legendOffset: 36,
          legendPosition: 'middle',
          format: xFormat,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: leftLabel,
          legendOffset: -85,
          legendPosition: 'middle',
          format: yFormat,
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={legend}
      />
    </Paper>
  )
}

export default LineChart
