const standardiseErrorMessage = (error: string): string => {
  if (error.includes('referrer_1 dup key')) {
    return 'A promo code with the supplied ref/reftag already exists'
  } else if (error.includes('code_1 dup key')) {
    return 'The supplied promo code already exists'
  } else if (error.includes('slug_1 dup key')) {
    return 'This charity partner already exists'
  } else if (error.includes('contact.phone must be a valid phone number')) {
    return 'Phone number must be a valid Canadian phone number'
  } else {
    return error
  }
}

export default standardiseErrorMessage