import { Autocomplete, Paper, TextField } from '@mui/material'
import { LineChart } from 'components'
import { useEffect, useState } from 'react'
import { useBusinessReportContext } from 'utils'
import { QuestionnairesOverTimeData } from 'utils/workers/processBusinessDataWorkers.types'
import RawDataTable from '../RawDataTable'

const QuestionnairesOverTime = () => {
  const {
    data: { processedReportData: { questionnairesOverTime } = {} },
  } = useBusinessReportContext()

  const [boundingIndices, setBoundingIndices] = useState<[number, number]>([
    questionnairesOverTime
      ? Object.keys(questionnairesOverTime).length - 13
      : 0,
    questionnairesOverTime ? Object.keys(questionnairesOverTime).length - 1 : 0,
  ])

  const [dataSubset, setDataSubset] = useState(
    questionnairesOverTime
      ? Object.keys(questionnairesOverTime)
          .slice(...boundingIndices)
          .reduce((result, key) => {
            result[key] = questionnairesOverTime[key]
            return result
          }, {} as QuestionnairesOverTimeData)
      : [],
  )

  useEffect(() => {
    if (!questionnairesOverTime) return

    setDataSubset(
      Object.keys(questionnairesOverTime)
        .slice(...boundingIndices)
        .reduce((result, key) => {
          result[key] = questionnairesOverTime[key]
          return result
        }, {} as QuestionnairesOverTimeData),
    )
  }, [boundingIndices, questionnairesOverTime])

  if (!questionnairesOverTime) return <></>

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '1rem',
          columnGap: '1rem',
          alignItems: 'center',
        }}
      >
        <Autocomplete
          disablePortal
          options={Object.keys(questionnairesOverTime)}
          value={Object.keys(questionnairesOverTime)[boundingIndices[0]]}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Start" />}
          onChange={(_, value) => {
            if (!value) return

            setBoundingIndices((prev: [number, number]) => [
              Object.keys(questionnairesOverTime).indexOf(value),
              prev[1],
            ])
          }}
        />
        to
        <Autocomplete
          disablePortal
          options={Object.keys(questionnairesOverTime).slice(
            boundingIndices[0],
            Object.keys(questionnairesOverTime).length - 1,
          )}
          value={Object.keys(questionnairesOverTime)[boundingIndices[1]]}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="End" />}
          onChange={(_, value) => {
            if (!value) return

            setBoundingIndices((prev) => [
              prev[0],
              Object.keys(questionnairesOverTime).indexOf(value),
            ])
          }}
        />
      </Paper>

      <Paper>
        <LineChart
          isInteractive
          height="20rem"
          leftLabel="Non-partner"
          bottomLabel="Month"
          displayLegend
          data={[
            {
              id: 'Paid',
              data: [
                ...Object.entries(dataSubset).map(([key, value]) => ({
                  x: key,
                  y: value.nonPartner.paid,
                })),
              ],
            },
            {
              id: 'Generated',
              data: [
                ...Object.entries(dataSubset).map(([key, value]) => ({
                  x: key,
                  y: value.nonPartner.generated,
                })),
              ],
            },
            {
              id: 'Accounts',
              data: [
                ...Object.entries(dataSubset).map(([key, value]) => ({
                  x: key,
                  y: value.nonPartner.accounts,
                })),
              ],
            },
            {
              id: 'Questionnaires',
              data: [
                ...Object.entries(dataSubset).map(([key, value]) => ({
                  x: key,
                  y: value.nonPartner.questionnaires,
                })),
              ],
            },
          ]}
        />
      </Paper>

      <Paper>
        <LineChart
          isInteractive
          height="20rem"
          leftLabel="Partner"
          bottomLabel="Month"
          displayLegend
          data={[
            {
              id: 'Generated',
              data: [
                ...Object.entries(dataSubset).map(([key, value]) => ({
                  x: key,
                  y: value.partner.generated,
                })),
              ],
            },
            {
              id: 'Questionnaires & Accounts',
              data: [
                ...Object.entries(dataSubset).map(([key, value]) => ({
                  x: key,
                  y: value.partner.questionnaires,
                })),
              ],
            },
          ]}
        />
      </Paper>

      <RawDataTable
        title="Non-partner"
        columnHeaders={['Questionnaires', 'Accounts', 'Generated', 'Paid']}
        rowHeaders={Object.keys(dataSubset)}
        data={Object.values(dataSubset).map(({ nonPartner }) => [
          nonPartner.questionnaires,
          nonPartner.accounts,
          nonPartner.generated,
          nonPartner.paid,
        ])}
      />

      <RawDataTable
        title="Partner"
        columnHeaders={['Questionnaires/Accounts', 'Generated']}
        rowHeaders={Object.keys(dataSubset)}
        data={Object.values(dataSubset).map(({ partner }) => [
          partner.questionnaires,
          partner.generated,
        ])}
      />
    </>
  )
}

export default QuestionnairesOverTime
