import { GridColDef } from '@mui/x-data-grid'
import { Gender, StandardAnswer } from 'global.types'
import _capitalize from 'lodash/capitalize'
import _startCase from 'lodash/startCase'
import moment from 'moment'
import { gridComparator } from 'utils'

const allData: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 150,
    description: 'First name of user',
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 150,
    description: 'Last name of user',
  },
  {
    field: 'email',
    headerName: 'Email Address',
    width: 250,
    description: 'Email address of user',
  },
  {
    field: 'city',
    headerName: 'City',
    width: 150,
    description: 'City of user',
  },
  {
    field: 'province',
    headerName: 'Province',
    width: 250,
    description: 'Province of user',
  },
  {
    field: 'postalCode',
    headerName: 'Postal Code',
    width: 100,
    description: 'Postal code of user',
  },
  {
    field: 'hasPartner',
    headerName: 'Has Partner',
    width: 100,
    description: 'If user has a partner',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'maritalStatus',
    headerName: 'Marital Status',
    width: 120,
    description: 'Marital status of user',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'hasChildren',
    headerName: 'Has Children',
    width: 100,
    description: 'If user has any children',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'gender',
    headerName: 'Gender',
    width: 100,
    description: "User's gender",
    valueFormatter: ({ value }: { value: Gender }) =>
      value === 'notSay' ? 'Unsaid' : _capitalize(value),
  },
  {
    field: 'dateOfBirth',
    headerName: 'Date of Birth',
    width: 150,
    description: 'Date of birth of user (YYYY-MM-DD)',
    valueFormatter: ({ value }: { value?: string }) =>
      value ? moment(value).format('YYYY-MM-DD') : '',
    sortComparator: gridComparator.date,
  },
  {
    field: 'ownsCanadianRealEstate',
    headerName: 'Owns Canadian Real Estate',
    width: 220,
    description: 'If user owns Canadian real estate',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'ownsForeignProperty',
    headerName: 'Owns Foreign Property',
    width: 190,
    description: 'If user owns foreign property',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'hasRrspAccount',
    headerName: 'Has RRSP',
    width: 100,
    description: 'If user has an RRSP account',
    valueFormatter: ({ value }: { value: boolean | null }) =>
      value === null ? '' : value ? 'Yes' : 'No',
  },
  {
    field: 'hasRrifAccount',
    headerName: 'Has RRIF',
    width: 100,
    description: 'If user has an RRIF account',
    valueFormatter: ({ value }: { value: boolean | null }) =>
      value === null ? '' : value ? 'yes' : 'No',
  },
  {
    field: 'hasTfsaAccount',
    headerName: 'Has TFSA',
    width: 100,
    description: 'If user has a TFSA account',
    valueFormatter: ({ value }: { value: boolean | null }) =>
      value === null ? '' : value ? 'Yes' : 'No',
  },
  {
    field: 'hasLifeInsurance',
    headerName: 'Has Life Insurance',
    width: 100,
    description: 'If user has life insurance',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'hasResps',
    headerName: 'Has RESPs',
    width: 100,
    description: 'If user has RESPs',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'isRbcClient',
    headerName: 'Is RBC Client',
    width: 100,
    description: 'If user is an RBC client',
    valueFormatter: ({ value }: { value: StandardAnswer }) => _startCase(value),
  },
  {
    field: 'corpExecType',
    headerName: 'Role',
    width: 100,
    description: 'Role of corporate executor',
    valueFormatter: ({ value }: { value: string }) =>
      _startCase(value).replace(' ', '-'),
  },
  {
    field: 'isPartner',
    headerName: 'Is Partner',
    width: 100,
    description: 'If user is a partner account',
    valueFormatter: ({ value }: { value: boolean | undefined }) =>
      value ? 'Yes' : 'No',
  },
  {
    field: 'hasGenerated',
    headerName: 'Has Generated',
    width: 120,
    description: 'If user has generated a document',
    valueFormatter: ({ value }: { value: boolean | undefined }) =>
      value ? 'Yes' : 'No',
  },
  {
    field: 'hasPaid',
    headerName: 'Has Paid',
    width: 100,
    description: 'If user has paid for documents',
    valueFormatter: ({ value }: { value: boolean | undefined }) =>
      value ? 'Yes' : 'No',
  },
  {
    field: 'ref',
    headerName: 'Ref',
    width: 200,
    description: 'Ref code of user',
  },
  {
    field: 'reftag',
    headerName: 'Ref-tag',
    width: 300,
    description: 'Ref-tag of user',
  },
  {
    field: 'feeAgreementSigned',
    headerName: 'Fee Agreement Signed On',
    width: 200,
    description: 'Date user agreed to the fee agreement',
  },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  allData,
}
