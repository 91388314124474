import qs from 'qs'

import { request } from 'utils'
import {
  DataFormat,
  DataEndpoint,
  CharitableGiftsResponse,
  CharityPartnersResponse,
  DistributionCharitiesResponse,
  BusinessReportResponse,
  BusinessReportRequest,
  CorporateExecutorResponse,
} from './data.types'

export const getCharitableGiftsJson = () => {
  return request<CharitableGiftsResponse[]>({
    url: DataEndpoint.CharitableGifts,
    method: 'GET',
    params: { format: DataFormat.JSON },
  }).then((res) => res.data.payload)
}

export const getCharitableGiftsCsv = () => {
  return request<string, never>({
    url: DataEndpoint.CharitableGifts,
    method: 'GET',
    params: { format: DataFormat.CSV },
  }).then((res) => res.data)
}

export const getCharityPartnersJson = () => {
  return request<CharityPartnersResponse[]>({
    url: DataEndpoint.CharityPartners,
    method: 'GET',
    params: { format: DataFormat.JSON },
  }).then((res) => res.data.payload)
}

export const getCharityPartnersCsv = () => {
  return request<string, never>({
    url: DataEndpoint.CharityPartners,
    method: 'GET',
    params: { format: DataFormat.CSV },
  }).then((res) => res.data)
}

export const getDistributionCharitiesJson = () => {
  return request<DistributionCharitiesResponse[]>({
    url: DataEndpoint.DistributionCharities,
    method: 'GET',
    params: { format: DataFormat.JSON },
  }).then((res) => res.data.payload)
}

export const getDistributionCharitiesCsv = () => {
  return request<string, never>({
    url: DataEndpoint.DistributionCharities,
    method: 'GET',
    params: { format: DataFormat.CSV },
  }).then((res) => res.data)
}

export const getBusinessReportData = (
  params: BusinessReportRequest,
  signal?: AbortSignal,
) =>
  request<BusinessReportResponse[]>({
    params,
    method: 'GET',
    abortSignal: signal,
    url: DataEndpoint.BusinessReport,
    paramsSerializer: (params) => qs.stringify(params),
  })

export const getCorporateExecutorJson = () => {
  return request<CorporateExecutorResponse[]>({
    url: DataEndpoint.CorporateExecutor,
    method: 'GET',
    params: { format: DataFormat.JSON },
  }).then((res) => res.data.payload)
}
