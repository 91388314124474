import {
  Box,
  alpha,
  Paper,
  Button,
  Popper,
  Tooltip,
  Typography,
  ClickAwayListener,
  SxProps,
} from '@mui/material'
import {
  Range,
  DateRangePicker as OriginalDateRangePicker,
} from 'react-date-range'
import moment from 'moment'
import { flushSync } from 'react-dom'
import { Dispatch, SetStateAction, useState } from 'react'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const formatDate = (date?: Date) =>
  !date ? undefined : moment(date).format('YYYY-MM-DD')

const DateRangePicker = ({
  label,
  range,
  setRange,
  tooltip = false,
  sx = {},
}: {
  label: string
  tooltip?: string | boolean
  range: Range
  setRange: Dispatch<SetStateAction<Range>>
  sx?: SxProps
}) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    flushSync(() => {
      setAnchorEl(event.currentTarget)
    })
    setOpen((prev) => !prev)
  }

  return (
    <Paper
      elevation={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '1rem',
        alignItems: 'center',
        p: 1,
        width: { xs: undefined, md: 350 },
        flexGrow: { xs: 1, md: 0 },
        justifyContent: { xs: 'center', md: 'space-around' },
        ...sx,
      }}
    >
      <Tooltip title={tooltip}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '0.4rem',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>{label}:</Typography>
          <Typography>
            {formatDate(range.startDate)} - {formatDate(range.endDate)}
          </Typography>
        </Box>
      </Tooltip>
      <Button variant="outlined" onClick={handleClick} sx={{ width: '5rem' }}>
        {open ? 'Close' : 'Change'}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        sx={{
          zIndex: 1000,
        }}
        placement="bottom"
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box
            sx={{
              border: (theme) =>
                `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`,
              borderRadius: '1rem',
              p: 1,
              backgroundColor: '#FFFFFF',
            }}
          >
            <OriginalDateRangePicker
              editableDateInputs
              retainEndDateOnFirstSelection
              ranges={[range]}
              onChange={({ selection }) => {
                setRange((prev) => ({
                  ...prev,
                  ...selection,
                }))
              }}
            />
          </Box>
        </ClickAwayListener>
      </Popper>
    </Paper>
  )
}

export default DateRangePicker
