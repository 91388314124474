import { GridRowsProp } from '@mui/x-data-grid'

import { CorporateExecutorResponse } from 'utils/api/data.types'

const getAllDataGridRows = (
  data?: CorporateExecutorResponse[],
): GridRowsProp => {
  if (data) {
    return [...data].map((gift, index) => ({
      id: index,
      ...gift,
    }))
  } else {
    return []
  }
}

export default getAllDataGridRows
