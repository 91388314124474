import ReactDOM from 'react-dom'
import React, { useState, useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material/styles'
import { PaletteMode, createTheme } from '@mui/material'
import { ReactQueryDevtools } from 'react-query/devtools'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import {
  getEnv,
  getTheme,
  queryClient,
  ThemeContext,
  localStorage,
  ReportDataProvider,
} from 'utils'
import App from './App'
import { LocalStorageKey } from 'global.types'
import { Environment } from 'utils/helpers/getEnv'

import 'index.css'

const isNotProduction = getEnv() !== Environment.Production

const Index = () => {
  const [mode, setMode] = useState<PaletteMode>(
    localStorage.read(LocalStorageKey.ColourMode) || 'light',
  )

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        )
        localStorage.write(
          LocalStorageKey.ColourMode,
          mode === 'light' ? 'dark' : 'light',
        )
      },
      mode,
    }),
    [mode],
  )

  const theme = useMemo(() => createTheme(getTheme(mode)), [mode])

  return (
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ThemeContext.Provider value={colorMode}>
              <ReportDataProvider>
                <ThemeProvider theme={theme}>
                  {isNotProduction && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                  <App />
                </ThemeProvider>
              </ReportDataProvider>
            </ThemeContext.Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </LocalizationProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))
