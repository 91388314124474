import { useMutation } from 'react-query'
import { PromoCode, PromoCodeEndpoint, updatePromoCode } from 'utils/api/promoCode.api'
import queryClient from 'utils/query/queryClient'

const useMutatePromoCode = () => {
  const { mutateAsync, isLoading } =
    useMutation<PromoCode, unknown, PromoCode>(
      (data) => updatePromoCode(data),
      {
        onSuccess: () => { queryClient.invalidateQueries(PromoCodeEndpoint.PromoCode) }
      }
    )

  return { mutateAsync, isLoading }
}

export default useMutatePromoCode