import _omit from 'lodash/omit'
import { useState } from 'react'
import _reduce from 'lodash/reduce'
import _isEqual from 'lodash/isEqual'
import _startCase from 'lodash/startCase'
import { Alert, Box, Button } from '@mui/material'

import { useMutatePromoCode } from 'utils'
import { PromoCodeForm, Text } from 'components'
import { PromoCode, Referrer } from 'utils/api/promoCode.api'

interface CodeDiff {
  key: string
  original: string | number | boolean | Referrer | undefined
  updated: string | number | boolean | Referrer | undefined
}

type PromoCodeWithoutUsage = Omit<PromoCode, 'usage'>

const getPromoCodeDifferences = (
  original: PromoCodeWithoutUsage,
  updated: PromoCodeWithoutUsage,
): CodeDiff[] => {
  const diffs = _reduce<PromoCode, string[]>(
    original,
    (result, value, key) =>
      _isEqual(value, updated[key as keyof PromoCodeWithoutUsage])
        ? result
        : result.concat(key),
    [],
  )

  return diffs.map((diff) => ({
    key: diff,
    original: original[diff as keyof PromoCodeWithoutUsage],
    updated: updated[diff as keyof PromoCodeWithoutUsage],
  }))
}

const PromoCodeDetails = ({ codeData }: { codeData: PromoCode }) => {
  const { mutateAsync } = useMutatePromoCode()

  const [error, setError] = useState<undefined | string>(undefined)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [successfullyUpdated, setSuccessfullyUpdated] = useState(false)
  const [formData, setFormData] = useState<PromoCode>(codeData)
  const [codeDiff, setCodeDiff] = useState<CodeDiff[]>([])

  return (
    <Box sx={{ position: 'relative' }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2, ml: 2, mr: 4 }}>
          {error}
        </Alert>
      )}
      {showConfirmation && (
        <Box
          sx={{
            position: 'absolute',
            left: '1rem',
            right: '2rem',
            top: '0.2rem',
            bottom: '0.9rem',
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '0.2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '1rem',
              background: '#FFFFFF',
              color: '#000000',
              borderRadius: '0.2rem',
              p: 2,
            }}
          >
            <Text variant="body">
              {successfullyUpdated
                ? 'Successfully updated'
                : 'You are about to update'}{' '}
              promo code '{codeData.code || 'ERROR'}'
            </Text>
            {
              <Box>
                {codeDiff.map((diff) => (
                  <Box
                    key={diff.key}
                    sx={{
                      display: 'flex',
                      columnGap: 1,
                    }}
                  >
                    <Text variant="subtitle2">{_startCase(diff.key)}:</Text>
                    <Text variant="subtitle2">
                      {diff.original?.toString() || '[None]'}
                    </Text>
                    <Text variant="subtitle2">{'->'}</Text>
                    <Text variant="subtitle2">
                      {diff.updated?.toString() || '[None]'}
                    </Text>
                  </Box>
                ))}
              </Box>
            }
            <Box
              sx={{
                display: 'flex',
                columnGap: '1rem',
              }}
            >
              {!successfullyUpdated && (
                <Button
                  variant="contained"
                  onClick={() => {
                    mutateAsync(formData)
                      .then(() => {
                        setError(undefined)
                        setSuccessfullyUpdated(true)
                      })
                      .catch((err) => {
                        setError(err.response.data.errorMessage)
                        setShowConfirmation(false)
                      })
                  }}
                >
                  Update
                </Button>
              )}
              <Button
                variant={successfullyUpdated ? 'contained' : 'outlined'}
                onClick={() => {
                  setShowConfirmation(false)
                  setSuccessfullyUpdated(false)
                  setCodeDiff([])
                }}
              >
                {successfullyUpdated ? 'Close' : 'Cancel'}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <PromoCodeForm
        sx={{
          mb: 2,
          ml: 2,
          mr: 4,
          p: 2,
        }}
        key={`${codeData._id}_details`}
        initialPromoCodeData={codeData}
        onSubmit={(newFormData) => {
          setCodeDiff(
            getPromoCodeDifferences(
              _omit(codeData, 'usage'),
              _omit(newFormData, 'usage'),
            ),
          )
          setFormData(_omit(newFormData, 'usage'))
          setShowConfirmation(true)
        }}
      />
    </Box>
  )
}

export default PromoCodeDetails
