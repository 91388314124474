import moment from 'moment'
import { Range } from 'react-date-range'
import _upperCase from 'lodash/upperCase'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Paper } from '@mui/material'
import { useEffect, useRef, useState, useMemo } from 'react'

import { Filters } from '../components/StatsFilter'
import { getCharitableGiftsCsv } from 'utils/api/data.api'
import { useFormData, useGetCharitableGiftsJson } from 'utils'
import { DataControls, Tabs, DataGridToolbar } from 'components'
import { CharitableGiftsResponse, DataEndpoint } from 'utils/api/data.types'

import columnDefs from './utils/columnDefs'
import BasicFilters from '../components/BasicFilters'
import useAllDataGridRows from './utils/useAllDataGridRows'
import getInitialFilterData from './utils/getInitialFilterData'
import useTotalledDataGridRows from './utils/useTotalledDataGridRows'
import CharitableGiftsStats from './components/CharitableGiftsStats'

enum DisplayOption {
  All = 'all',
  Totalled = 'totalled',
  Stats = 'stats',
}

const CharitableGiftsView = () => {
  const { data, isLoading } = useGetCharitableGiftsJson()

  const [tableWrapperHeight, setTableWrapperHeight] = useState(0)
  const [filterUngenerated, setFilterUngenerated] = useState(false)
  const [filterUnpaid, setFilterUnpaid] = useState(false)
  const [displayOption, setDisplayOption] = useState<DisplayOption>(
    DisplayOption.All,
  )
  const [range, setRange] = useState<Range>({
    startDate: new Date('2020-01-02'),
    endDate: new Date(),
    key: 'selection',
  })

  const filteredData: CharitableGiftsResponse[] = useMemo(() => {
    return data
      ? [...data]
          .filter((gift) => (filterUngenerated ? gift.hasGenerated : true))
          .filter((gift) => (filterUnpaid ? gift.hasPaid : true))
          .filter((gift) =>
            gift.mostRecentGenerationDate
              ? moment(gift.mostRecentGenerationDate).isBetween(
                  range.startDate,
                  range.endDate,
                  undefined,
                  '[]',
                )
              : true,
          )
      : []
  }, [data, filterUngenerated, filterUnpaid, range.endDate, range.startDate])

  const [filters, updateFilters] = useFormData<Filters>(
    getInitialFilterData(filteredData),
  )

  const advancedFilteredData: CharitableGiftsResponse[] = useMemo(() => {
    return filteredData
      .filter((gift) => !!gift.age && gift.age >= filters.minAge)
      .filter((gift) => !!gift.age && gift.age <= filters.maxAge)
      .filter(
        (gift) => !!gift.province && filters.provinces.includes(gift.province),
      )
      .filter(
        (gift) =>
          !!gift.hasPartner && filters.hasPartner.includes(gift.hasPartner),
      )
      .filter(
        (gift) =>
          !!gift.hasChildren && filters.hasChildren.includes(gift.hasChildren),
      )
      .filter((gift) => filters.charities.includes(_upperCase(gift.name)))
      .filter((gift) => !!gift.gender && filters.genders.includes(gift.gender))
  }, [filteredData, filters])

  const allDataGridRows = useAllDataGridRows({
    data: filteredData,
  })

  const totalledDataGridRows = useTotalledDataGridRows({
    data: filteredData,
  })

  const paperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTableWrapperHeight(paperRef.current?.offsetHeight || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, paperRef.current])

  useEffect(() => {
    updateFilters(getInitialFilterData(filteredData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData])

  return (
    <>
      <DataControls
        invalidationKey={[DataEndpoint.CharitableGifts]}
        getCsvRequest={getCharitableGiftsCsv}
        downloadTitle="charitable_gifts.csv"
        range={range}
        setRange={setRange}
      />
      <Paper
        ref={paperRef}
        style={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper sx={{ height: '3rem', px: 1, display: 'flex' }} elevation={0}>
          <Tabs
            value={displayOption}
            disabled={isLoading}
            onChange={(value) => {
              setDisplayOption(value as DisplayOption)
            }}
            tabs={[
              { label: 'All', value: DisplayOption.All },
              { label: 'Totalled', value: DisplayOption.Totalled },
              { label: 'Stats', value: DisplayOption.Stats },
            ]}
          />
          <BasicFilters
            filterUngenerated={filterUngenerated}
            setFilterUngenerated={setFilterUngenerated}
            filterUnpaid={filterUnpaid}
            setFilterUnpaid={setFilterUnpaid}
          />
        </Paper>
        <Box
          sx={{
            width: '100%',
            height:
              displayOption === DisplayOption.Stats
                ? 'unset'
                : `calc(${tableWrapperHeight}px - 3rem)`,
          }}
        >
          {displayOption === DisplayOption.All && (
            <DataGrid
              key={DisplayOption.All}
              rows={allDataGridRows}
              columns={columnDefs.allDataColumns}
              components={{
                Toolbar: DataGridToolbar,
              }}
              loading={isLoading}
            />
          )}

          {displayOption === DisplayOption.Totalled && (
            <DataGrid
              key={DisplayOption.Totalled}
              rows={totalledDataGridRows}
              columns={columnDefs.totalledDataColumns}
              components={{
                Toolbar: DataGridToolbar,
              }}
              loading={isLoading}
            />
          )}

          {displayOption === DisplayOption.Stats && (
            <CharitableGiftsStats
              filters={filters}
              data={advancedFilteredData}
              updateFilters={updateFilters}
              initialFilterData={getInitialFilterData(filteredData)}
            />
          )}
        </Box>
      </Paper>
    </>
  )
}

export default CharitableGiftsView
