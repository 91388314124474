import { Paper } from '@mui/material'
import { PieChart } from 'components'
import { ReactNode } from 'react'
import { format, useBusinessReportContext } from 'utils'
import BarChart from 'components/BarChart/_BarChart'

const rbcRefs = {
  'EMPLOYEE-1011422': {
    label: 'RBC Employees (Old Referral Link)',
    color: '#C55A11',
  },
  'WEALTH-13011822': {
    label: 'RBC Wealth (Wealth Referral Link)',
    color: '#70AD47',
  },
  'BANKING-13011822': {
    label: 'RBC Customers (Banking Referral Link)',
    color: '#FFC000',
  },
  'AMPLI-MARCH-22': {
    label: 'Ampli (Ref Only - No Code at Checkout)',
    color: '#C8C3E8',
  },
  'RBC-EMPLOYEE-': {
    label: 'RBC Employees (Only Code at Checkout)',
    color: '#E37645',
  },
  'EMPLOYEE-10211222': {
    label: 'RBC Employees (New Referral Link)',
    color: '#3F396E',
  },
  'RBC-': { label: 'RBC Customers (Only Code at Checkout)', color: '#87B38D' },
  'INSURANCE-04050322': {
    label: 'RBC Insurance (Insurance Referral Link)',
    color: '#1D84B5',
  },
}

const pieChartColors = Object.values(rbcRefs).map(({ color }) => color)

const Row = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <Paper
    elevation={8}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      rowGap: '1rem',
      columnGap: '1rem',
      justifyContent: 'space-evenly',
      p: 1,
    }}
  >
    {children}
  </Paper>
)

const RbcMonthlyReport = () => {
  const {
    data: { processedReportData },
  } = useBusinessReportContext()

  if (!processedReportData?.rbcMonthlyReport) return <></>

  const { rbcMonthlyReport } = processedReportData

  const keyConversionData = [
    {
      label: 'Started Questionnaire',
      values: Object.entries(rbcRefs).reduce(
        (acc, [reftag, { label, color }]) => ({
          ...acc,
          [label]: {
            value: rbcMonthlyReport.byRef[reftag].questionnaires,
            color,
          },
        }),
        {},
      ),
    },
    {
      label: 'Created Account',
      values: Object.entries(rbcRefs).reduce(
        (acc, [reftag, { label, color }]) => ({
          ...acc,
          [label]: {
            value: rbcMonthlyReport.byRef[reftag].accounts,
            color,
          },
        }),
        {},
      ),
    },
    {
      label: 'Completed Questionnaire',
      values: Object.entries(rbcRefs).reduce(
        (acc, [reftag, { label, color }]) => ({
          ...acc,
          [label]: {
            value: rbcMonthlyReport.byRef[reftag].generated,
            color,
          },
        }),
        {},
      ),
    },
    {
      label: 'Purchased',
      values: Object.entries(rbcRefs).reduce(
        (acc, [reftag, { label, color }]) => ({
          ...acc,
          [label]: {
            value: rbcMonthlyReport.byRef[reftag].paid,
            color,
          },
        }),
        {},
      ),
    },
  ]

  const grossRevenue = [
    {
      label: 'Gross Revenue',
      values: Object.entries(rbcRefs).reduce(
        (acc, [reftag, { label, color }]) => ({
          ...acc,
          [label]: {
            value: rbcMonthlyReport.byRef[reftag].revenue,
            color,
          },
        }),
        {},
      ),
    },
  ]

  return (
    <>
      <BarChart data={keyConversionData} valuePadding={350} ticks={9} />

      <BarChart
        data={grossRevenue}
        layout="vertical"
        ticks={11}
        formatLabel={format.currency}
        valuePadding={10000}
      />

      <Row>
        <PieChart
          title="Created Account"
          elevation={0}
          data={Object.entries(rbcMonthlyReport.byGender).map(
            ([key, { accounts }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.accounts,
                0,
              )

              return {
                id: key,
                value: accounts / total,
                color: pieChartColors[index],
              }
            },
          )}
        />

        <PieChart
          title="Completed Questionnaire"
          elevation={0}
          data={Object.entries(rbcMonthlyReport.byGender).map(
            ([key, { generated }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.generated,
                0,
              )

              return {
                id: key === 'no answer' ? 'other' : key,
                value: generated / total,
                color: pieChartColors[index],
              }
            },
          )}
        />

        <PieChart
          title="Purchased"
          elevation={0}
          data={Object.entries(rbcMonthlyReport.byGender).map(
            ([key, { paid }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.paid,
                0,
              )

              return {
                id: key === 'no answer' ? 'other' : key,
                value: paid / total,
                color: pieChartColors[index],
              }
            },
          )}
        />
      </Row>
      <Row>
        <PieChart
          elevation={0}
          title="Created Account"
          data={Object.entries(rbcMonthlyReport.byPartner).map(
            ([key, { accounts }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.accounts,
                0,
              )

              return {
                id: key,
                value: accounts / total,
                color: pieChartColors[index],
              }
            },
          )}
        />

        <PieChart
          elevation={0}
          title="Completed Questionnaire"
          data={Object.entries(rbcMonthlyReport.byPartner).map(
            ([key, { generated }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.generated,
                0,
              )

              return {
                id: key,
                value: generated / total,
                color: pieChartColors[index],
              }
            },
          )}
        />

        <PieChart
          elevation={0}
          title="Purchased"
          data={Object.entries(rbcMonthlyReport.byPartner).map(
            ([key, { paid }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.paid,
                0,
              )

              return {
                id: key,
                value: paid / total,
                color: pieChartColors[index],
              }
            },
          )}
        />
      </Row>
      <Row>
        <PieChart
          elevation={0}
          title="Created Account"
          data={Object.entries(rbcMonthlyReport.byChildren).map(
            ([key, { accounts }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.accounts,
                0,
              )

              return {
                id: key,
                value: accounts / total,
                color: pieChartColors[index],
              }
            },
          )}
        />

        <PieChart
          elevation={0}
          title="Completed Questionnaire"
          data={Object.entries(rbcMonthlyReport.byChildren).map(
            ([key, { generated }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.generated,
                0,
              )

              return {
                id: key,
                value: generated / total,
                color: pieChartColors[index],
              }
            },
          )}
        />

        <PieChart
          elevation={0}
          title="Purchased "
          data={Object.entries(rbcMonthlyReport.byChildren).map(
            ([key, { paid }], index) => {
              const total = Object.values(rbcMonthlyReport.byGender).reduce(
                (acc, current) => acc + current.paid,
                0,
              )

              return {
                id: key,
                value: paid / total,
                color: pieChartColors[index],
              }
            },
          )}
        />
      </Row>
      <Row>
        <PieChart
          elevation={0}
          title="Created Account"
          legendFormatter={(value) => value}
          data={Object.entries(rbcMonthlyReport.byAge)
            .filter(([key]) => key !== 'total')
            .map(([key, { accounts }], index) => {
              const {
                total: { accounts: total },
              } = rbcMonthlyReport.byAge

              return {
                id: key,
                value: accounts / total,
                color: pieChartColors[index],
              }
            })}
        />

        <PieChart
          elevation={0}
          title="Completed Questionnaire"
          legendFormatter={(value) => value}
          data={Object.entries(rbcMonthlyReport.byAge)
            .filter(([key]) => key !== 'total')
            .map(([key, { generated }], index) => {
              const {
                total: { accounts: total },
              } = rbcMonthlyReport.byAge

              return {
                id: key,
                value: generated / total,
                color: pieChartColors[index],
              }
            })}
        />

        <PieChart
          elevation={0}
          title="Purchased "
          legendFormatter={(value) => value}
          data={Object.entries(rbcMonthlyReport.byAge)
            .filter(([key]) => key !== 'total')
            .map(([key, { paid }], index) => {
              const {
                total: { accounts: total },
              } = rbcMonthlyReport.byAge

              return {
                id: key,
                value: paid / total,
                color: pieChartColors[index],
              }
            })}
        />
      </Row>
    </>
  )
}

export default RbcMonthlyReport
