import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { Children } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _capitalize from 'lodash/capitalize'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { dates, dictionary } from 'utils'
import { Text, TitledWrapper } from 'components'
import { UserDetails } from 'utils/api/user.types'

const PaymentsList = ({ data }: { data: UserDetails }) => {
  return (
    <TitledWrapper title="Payments" sx={{ py: 1 }}>
      <Box>
        {Children.toArray(
          data.payments.map((payment) => (
            <Accordion elevation={0} sx={{ background: 'none' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Text sx={{ width: '33%', flexShrink: 0 }}>
                  {dictionary.products(payment.productId)}
                </Text>
                <Text sx={{ color: 'text.secondary' }}>
                  {dates.format(payment.created)}
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 3fr',
                    gridTemplateRows: '1fr',
                    rowGap: 1,
                    mx: 2,
                    '& > *': {
                      borderBottom: 'solid 1px #CCCCCC',
                      pb: 1,
                    },
                  }}
                >
                  <Text>Price:</Text>
                  <Text sx={{ color: 'text.secondary' }}>
                    ${payment.price ? payment.price.toFixed(2) : '0.00'}
                  </Text>

                  <Text>Discount:</Text>
                  <Text sx={{ color: 'text.secondary' }}>
                    {payment.discount && payment.price
                      ? `$${payment.discount.toFixed(2)} (${(
                          (payment.discount / payment.price) *
                          100
                        ).toFixed(2)}%)`
                      : '$0.00'}
                  </Text>

                  <Text>Paid for partner:</Text>
                  <Text sx={{ color: 'text.secondary' }}>
                    {payment.partnerPaidFor ? 'Yes' : 'No'}
                  </Text>

                  <Text>ID:</Text>
                  <Text sx={{ color: 'text.secondary' }}>{payment._id}</Text>

                  <Text>Charge ID:</Text>
                  <Text sx={{ color: 'text.secondary' }}>
                    {payment.chargeId === 'paid by partner'
                      ? 'Paid by partner'
                      : payment.chargeId}
                  </Text>

                  <Text>Ref/Reftag:</Text>
                  <Text sx={{ color: 'text.secondary' }}>
                    {payment.referrer?.ref && payment.referrer?.reftag
                      ? `${payment.referrer.ref}/${payment.referrer.reftag}`
                      : 'None'}
                  </Text>

                  <Text>Docs purchased:</Text>
                  {_isEmpty(payment.docTypes) ? (
                    <Text sx={{ color: 'text.secondary' }}>None</Text>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {Children.toArray(
                        payment.docTypes.map((doctype) => (
                          <Text sx={{ color: 'text.secondary' }}>
                            {dictionary.doctype(doctype)}
                          </Text>
                        )),
                      )}
                    </Box>
                  )}

                  <Text>Add-Ons purchased:</Text>
                  {_isEmpty(payment.addOns) ? (
                    <Text sx={{ color: 'text.secondary' }}>None</Text>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {Children.toArray(
                        payment.addOns.map((addOn) => (
                          <Text sx={{ color: 'text.secondary' }}>
                            {`$${
                              addOn.price ? addOn.price.toFixed(2) : '0.00'
                            } - ${_capitalize(addOn.type)} ${
                              addOn.partnerPaidFor ? '(including partner)' : ''
                            }`}
                          </Text>
                        )),
                      )}
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          )),
        )}
        {_isEmpty(data.payments) && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              py: 2,
              color: 'text.disabled',
            }}
          >
            <Text variant="subtitle2" align="center">
              No payments
            </Text>
          </Box>
        )}
      </Box>
    </TitledWrapper>
  )
}

export default PaymentsList
