import { SxProps, Theme } from '@mui/material'

const scrollbar: SxProps<Theme> = {
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '7px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: (theme: Theme) => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
    boxShadow: (theme: Theme) => `0 0 1px ${theme.palette.mode === 'light' ? ' rgba(255, 255, 255, 0.5)' : ' rgba(0, 0, 0, 0.5)'
      }`,
  },
  ' ::-webkit-scrollbar-corner': {
    background: 'rgba(0,0,0,0)'
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  scrollbar
}