import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  Theme,
  Accordion,
  useMediaQuery,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

import { Text } from 'components'
import { dates, dictionary } from 'utils'
import { Document } from 'utils/api/user.types'

const StandardDocumentListItem = ({ document }: { document: Document }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Accordion elevation={0} sx={{ background: 'none' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : '2fr 1fr 2fr',
            gridTemplateRows: '1fr',
            width: '100%',
            mr: 1,
          }}
        >
          <Text sx={{ gridColumn: isMobile ? undefined : '1 / span 2' }}>
            {dictionary.doctype(document.docType)}
          </Text>
          <Text align={isMobile ? 'left' : 'right'}>
            {dates.format(document.created)}
          </Text>
          <Text sx={{ color: 'text.disabled', fontSize: '0.8rem' }}>
            {document.bonus ? 'Bonus Document' : 'Standard Document'}
          </Text>
          <Text sx={{ color: 'text.disabled', fontSize: '0.8rem' }}>
            {document.signed ? 'Signed' : 'Unsigned'}
          </Text>
          <Text
            align={isMobile ? 'left' : 'right'}
            sx={{ color: 'text.disabled', fontSize: '0.8rem' }}
          >
            {document._id}
          </Text>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : '1fr 3fr',
            gridTemplateRows: '1fr',
            width: '100%',
            rowGap: 1,
          }}
        >
          <Text sx={{ color: 'text.disabled', fontSize: '0.8rem' }}>
            Preview Key:
          </Text>
          <Text sx={{ color: 'text.disabled', fontSize: '0.8rem' }}>
            {document.previewKey ? document.previewKey : 'None'}
          </Text>
          <Text sx={{ color: 'text.disabled', fontSize: '0.8rem' }}>
            Document Key:
          </Text>
          <Text sx={{ color: 'text.disabled', fontSize: '0.8rem' }}>
            {document.key ? document.key : 'None'}
          </Text>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default StandardDocumentListItem
