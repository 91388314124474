import { Box } from '@mui/material'

import { Tabs } from 'components'
import { UserContext } from 'utils'
import { useContext, useState } from 'react'
import AnswerDisplay from './questionnaire/AnswerDisplay'

enum BonusStore {
  Wishes = 'wishes',
}

const BonusAnswerStore = () => {
  const { data } = useContext(UserContext) || {}
  const [dataType, setDataType] = useState<BonusStore>(BonusStore.Wishes)

  return data ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Tabs
        variant="fullWidth"
        value={dataType}
        onChange={(value) => setDataType(value as BonusStore)}
        tabs={[
          {
            label: 'Wishes',
            value: BonusStore.Wishes,
          },
        ]}
      />

      <AnswerDisplay answers={data.bonusDocsAnswerStore?.wishes || []} />
    </Box>
  ) : (
    <></>
  )
}

export default BonusAnswerStore
