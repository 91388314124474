import {
  Range,
  DateRangePicker as OriginalDateRangePicker,
} from 'react-date-range'
import { useState } from 'react'
import { Box, Button } from '@mui/material'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateRangePicker = ({
  range,
  setRange,
}: {
  range: Range
  setRange: (range: Range) => void
}) => {
  const [isShowing, setIsShowing] = useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
        alignItems: 'flex-end',
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          setIsShowing(!isShowing)
        }}
        sx={{
          width: '11rem',
        }}
      >
        {isShowing ? 'Hide Date Picker' : 'Show Date Picker'}
      </Button>
      {isShowing && (
        <OriginalDateRangePicker
          ranges={[range]}
          onChange={(newRange) => {
            setRange(newRange.selection)
          }}
        />
      )}
    </Box>
  )
}

export default DateRangePicker
