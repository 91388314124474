export enum PreviewViews {
  Mobile = '400px',
  Desktop = '100%',
}

export enum ImageArrangements {
  Left = 'left',
  Right = 'right',
}

export enum MobileImageArrangements {
  Top = 'top',
  Bottom = 'bottom',
}

export enum CtaStyles {
  Rounded = 'rounded',
  Square = 'square',
  Link = 'link',
}

export interface EmbedObject {
  ctaColour: string
  ctaTextColour: string
  bgColour: string
  smallHeading: string
  heading: string
  body: string
  ctaDestination: string
  ctaText: string
  imageUrl: string
  smallHeadingFont: string
  headerFont: string
  bodyFont: string
  imageArrangement: ImageArrangements
  mobileImageArrangement: MobileImageArrangements
  ctaStyle: CtaStyles
  textColour: string
  ctaHoverColour: string
  headerWeight: string
  bodyWeight: string
  smallHeadingWeight: string
}

export interface EmbedImage {
  url: string,
  name: string
}