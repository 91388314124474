import { ReactText } from 'react'
import { Box, SxProps, Breakpoint, Theme, useMediaQuery } from '@mui/material'

import { spreadSx } from 'utils'
import { Text } from 'components'

const TableDataCell = ({
  sx,
  children,
  width = 1,
  hideBelowBreakpoint,
}: {
  children?: ReactText
  sx?: SxProps
  width?: number
  hideBelowBreakpoint?: Breakpoint
}) => {
  const isHidden = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(hideBelowBreakpoint || 'md'),
  )

  return (
    <Box
      sx={spreadSx([
        {
          display: isHidden && hideBelowBreakpoint ? 'none' : undefined,
          p: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          minWidth: 0,
          flex: width,
        },
        sx,
      ])}
    >
      <Text>{children || '-'}</Text>
    </Box>
  )
}

export default TableDataCell
