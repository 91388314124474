import {
  Paper,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemIcon,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material'
import _toLower from 'lodash/toLower'
import _startCase from 'lodash/startCase'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const formatLabel = (label: string) =>
  label === 'notSay' ? ' Unsaid' : _startCase(_toLower(label))

const SelectFilter = ({
  label,
  values,
  selected,
  onChange,
}: {
  label: string
  values: string[]
  selected: string[]
  onChange: (newSelectedValues: string[]) => void
}) => {
  const isAllSelected = values.length > 0 && selected.length === values.length

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    if (value[value.length - 1] === 'all') {
      onChange(selected.length === values.length ? [] : values)
    } else {
      onChange(Array.isArray(value) ? value : [value])
    }
  }

  return (
    <Paper
      sx={{
        py: '0.4rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormControl
        sx={{
          margin: (theme) => theme.spacing(1),
          width: 350,
        }}
      >
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-select-label`}
          label={label}
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={(selectedOptions: string[]) =>
            selectedOptions.map((option) => formatLabel(option)).join(', ')
          }
          MenuProps={{
            sx: {
              maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
              width: 250,
            },
            variant: 'menu',
          }}
        >
          <MenuItem
            value="all"
            sx={{
              fontWeight: isAllSelected ? 500 : undefined,
              backgroundColor: 'rgba(25, 118, 210, 0.08)',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.08)',
              },
            }}
          >
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < values.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {values.map((value) => (
            <MenuItem key={value} value={value}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(value) > -1} />
              </ListItemIcon>
              <ListItemText primary={formatLabel(value)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  )
}

export default SelectFilter
