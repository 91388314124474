import { Children } from 'react'
import { Box, Paper } from '@mui/material'

import { Text } from 'components'

interface LeaderboardValue {
  amount: string
  attribution: string
}

const LeaderboardStat = ({
  title,
  values,
}: {
  title: string
  values: LeaderboardValue[]
}) => {
  return (
    <Paper elevation={5} sx={{ p: 2, flexGrow: 1 }}>
      <Text variant="h6">{title}</Text>
      {Children.toArray(
        values.map((value, index) => (
          <Box sx={{ display: 'flex', columnGap: 1 }}>
            <Text sx={{ whiteSpace: 'nowrap' }}>
              {index + 1}. {value.amount}
            </Text>
            <Text sx={{ justifySelf: 'flex-end' }}>({value.attribution})</Text>
          </Box>
        )),
      )}
    </Paper>
  )
}

export default LeaderboardStat
