import { GridComparatorFn } from '@mui/x-data-grid'
import moment from 'moment'
import { format } from 'utils'

const formattedAmount: GridComparatorFn<string> = (value1, value2) => {
  const isValue1Percent = value1.slice(value1.length - 1) === '%'
  const isValue2Percent = value2.slice(value2.length - 1) === '%'

  if (isValue1Percent && isValue2Percent) {
    return format.percentageToNumber(value1) - format.percentageToNumber(value2)
  } else if (isValue1Percent && !isValue2Percent) {
    return -1
  } else if (!isValue1Percent && isValue2Percent) {
    return 1
  } else {
    return format.currencyToNumber(value1) - format.currencyToNumber(value2)
  }
}

const date: GridComparatorFn = (value1, value2) => {
  return moment(value1).isBefore(moment(value2)) ? -1 : 1
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formattedAmount,
  date,
}
