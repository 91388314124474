import { useQuery } from 'react-query'
import { DataEndpoint } from 'utils/api/data.types'
import { getCharitableGiftsJson } from 'utils/api/data.api'

const useGetCharitableGiftsJson = () => useQuery({
  queryKey: [DataEndpoint.CharitableGifts, 'json'],
  queryFn: () => getCharitableGiftsJson(),
  retry: false,
  staleTime: 21600000, // 6 hours
  refetchOnWindowFocus: false,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetCharitableGiftsJson