import { Box, List, ListItemText, ListItem } from '@mui/material'

import { Text } from 'components'
import { dictionary, spreadSx, styles } from 'utils'
import FormattedAnswerStoreValue from './FormattedAnswerStoreValue'

const answerStoreKeysToFilter = ['password']

const AnswerDisplay = ({ answers }: { answers: object }) => {
  return (
    <Box
      sx={spreadSx([
        {
          height: 'calc(100vh - 17rem)',
          overflowY: 'scroll',
        },
        styles.scrollbar,
      ])}
    >
      <List dense>
        {Object.keys(answers)
          .filter((key) => !answerStoreKeysToFilter.includes(key))
          .map((key) => (
            <ListItem key={key}>
              <ListItemText>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr',
                    gridTemplateRows: '1fr',
                    rowGap: 1,
                    mx: 2,
                    '& > *': {
                      borderBottom: 'solid 1px #CCCCCC',
                      pb: 1,
                    },
                  }}
                >
                  <Text sx={{ pr: 1 }}>
                    {dictionary.questionnaireKeys(key)}
                  </Text>
                  <FormattedAnswerStoreValue
                    storeKey={key}
                    value={answers[key as keyof typeof answers]}
                  />
                </Box>
              </ListItemText>
            </ListItem>
          ))}
      </List>
    </Box>
  )
}

export default AnswerDisplay
