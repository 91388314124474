import { request } from 'utils'

export enum Role {
  User = 'user',
  Admin = 'admin',
  SuperAdmin = 'superAdmin'
}

export enum RoleEndpoint {
  Role = '/role',
}

export const getRoles = (): Promise<Role[]> => {
  return request({ url: RoleEndpoint.Role, method: 'GET' }).then(res => res.data.payload?.roles || [])
}

