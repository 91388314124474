import { useState } from 'react'
import { Box } from '@mui/material'

import { PromoCodeForm } from 'components'
import ConfirmationModal from './ConfirmationModal'
import { PromoCode } from 'utils/api/promoCode.api'

const CreatePromoCodeView = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [promoCodeRequestData, setPromoCodeRequestData] = useState<PromoCode>({
    isPercent: true,
    amount: 0,
    prefix: undefined,
    code: undefined,
    expiryDate: undefined,
    oneTimeUse: false,
    isActive: true,
    numOfCodes: undefined,
    referrer: undefined,
    description: undefined,
  })

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        setShow={setShowConfirmationModal}
        promoCodeRequest={promoCodeRequestData}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          columnGap: 2,
          flexGrow: 1,
        }}
      >
        <PromoCodeForm
          onSubmit={(promoCodeData) => {
            setPromoCodeRequestData(promoCodeData)
            setShowConfirmationModal(true)
          }}
        />
      </Box>
    </>
  )
}

export default CreatePromoCodeView
