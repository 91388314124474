import { useQuery } from 'react-query'
import { DataEndpoint } from 'utils/api/data.types'
import { getCorporateExecutorJson } from 'utils/api/data.api'

const useGetCorporateExecutorDataJson = () =>
  useQuery({
    queryKey: [DataEndpoint.CorporateExecutor, 'json'],
    queryFn: () => getCorporateExecutorJson(),
    retry: false,
    staleTime: 21600000, // 6 hours
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.error(error)
    },
  })

export default useGetCorporateExecutorDataJson
