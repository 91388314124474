import { Breakpoint } from '@mui/material'

export interface ColumnMetaData {
  hideBelowBreakpoint?: Breakpoint
  width: number
}

export enum Province {
  Alberta = 'Alberta',
  BritishColumbia = 'British Columbia',
  Manitoba = 'Manitoba',
  NewBrunswick = 'New Brunswick',
  NewfoundlandAndLabrador = 'Newfoundland and Labrador',
  NorthwestTerritories = 'Northwest Territories',
  NovaScotia = 'Nova Scotia',
  Nunavut = 'Nunavut',
  Ontario = 'Ontario',
  PrinceEdwardIsland = 'Prince Edward Island',
  Quebec = 'Quebec',
  Saskatchewan = 'Saskatchewan',
  Yukon = 'Yukon',
}

export enum LocalStorageKey {
  ColourMode = 'colourMode',
  PinnedBusinessReportFilters = 'pinnedBusinessReportFilters',
}

export enum AdditionalOption {
  Other = 'other',
  NotSay = 'notSay',
  NoAnswer = 'NO ANSWER',
}

export type StandardAnswer = 'no' | 'yes' | 'unsure'

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  NotSay = 'notSay',
}

export type MaritalStatus =
  | 'commonLaw'
  | 'divorced'
  | 'married'
  | 'separated'
  | 'single'
  | 'widowed'

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}
