import { Tabs } from 'components'
import { Box } from '@mui/material'
import _pickBy from 'lodash/pickBy'
import _startsWith from 'lodash/startsWith'
import { useContext, useState } from 'react'

import { UserContext } from 'utils'
import AnswerDisplay from './questionnaire/AnswerDisplay'

enum DataType {
  Cache = 'cache',
  Store = 'store',
}

const Questionnaire = ({ type }: { type: 'social' | 'will' }) => {
  const { data } = useContext(UserContext) || {}
  const [dataType, setDataType] = useState<DataType>(DataType.Store)

  const answerStore =
    type === 'will'
      ? data?.questionnaire.answerStore || {}
      : data?.questionnaire.socialAnswerStore || {}

  const answerCache =
    type === 'will'
      ? _pickBy(
          data?.questionnaire.answerCache,
          (_, key) => !_startsWith(key, 'social_'),
        ) || {}
      : _pickBy(data?.questionnaire.answerCache, (_, key) =>
          _startsWith(key, 'social_'),
        ) || {}

  return data ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Tabs
        variant="fullWidth"
        value={dataType}
        onChange={(value) => setDataType(value as DataType)}
        tabs={[
          {
            label: 'Answer Store',
            value: DataType.Store,
          },
          {
            label: 'Answer Cache',
            value: DataType.Cache,
          },
        ]}
      />

      {dataType === DataType.Store ? (
        <AnswerDisplay answers={answerStore} />
      ) : (
        <AnswerDisplay answers={answerCache} />
      )}
    </Box>
  ) : (
    <></>
  )
}

export default Questionnaire
