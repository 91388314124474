import _upperCase from 'lodash/upperCase'
import { Filters } from '../../components/StatsFilter'
import { DistributionCharitiesResponse } from 'utils/api/data.types'

const getInitialFilterData = (data: DistributionCharitiesResponse[]): Filters => {
  const minAge =
    [...data]
      .filter((gift) => !!gift.age)
      .sort((a, b) => (a.age || 0) - (b.age || 0))
      .splice(0, 1)[0]?.age || 0

  const maxAge =
    [...data]
      .filter((gift) => !!gift.age)
      .sort((a, b) => (b.age || 0) - (a.age || 0))
      .splice(0, 1)[0]?.age || 100

  const genders = [
    ...new Set(data.filter((gift) => !!gift.gender).map((gift) => gift.gender)),
  ] as string[]

  const provinces = [
    ...new Set(
      data.filter((gift) => !!gift.province).map((gift) => gift.province),
    ),
  ] as string[]

  const hasChildren = [
    ...new Set(
      data.filter((gift) => !!gift.hasChildren).map((gift) => gift.hasChildren),
    ),
  ] as string[]

  const hasPartner = [
    ...new Set(
      data.filter((gift) => !!gift.hasPartner).map((gift) => gift.hasPartner),
    ),
  ] as string[]

  const charities = [
    ...new Set(data.map((gift) => _upperCase(gift.name))),
  ].sort() as string[]

  return {
    minAge,
    maxAge,
    genders,
    provinces,
    hasChildren,
    hasPartner,
    charities,
  }
}

export default getInitialFilterData