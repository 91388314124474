import _capitalize from 'lodash/capitalize'

import { BarChart } from 'components'
import RawDataTable from '../RawDataTable'
import { round, useBusinessReportContext, format } from 'utils'

const CompletionAndRevenueRates = () => {
  const {
    data: { processedReportData },
  } = useBusinessReportContext()

  if (!processedReportData?.completionAndRevenueRates) return <></>

  const { completionAndRevenueRates } = processedReportData

  return (
    <>
      <BarChart
        isInteractive
        maxValue={1}
        height="20rem"
        leftLabel="Questionnaire Completion & Payment Percentage"
        data={Object.entries(completionAndRevenueRates).map(([key, data]) => {
          const { partnerCount, nonPartnerCount, generated, paid } = data
          const accounts = partnerCount + nonPartnerCount

          return {
            index: _capitalize(key),
            'Generated/Accounts': generated / accounts,
            'Paid/Generated': paid / generated,
          }
        })}
        legend={['Generated/Accounts', 'Paid/Generated']}
        valueFormat="^>-.2%"
      />
      <BarChart
        isInteractive
        height="20rem"
        leftLabel="Revenue per Account & Customer"
        legend={['Value Per Account', 'Value Per Customer']}
        data={Object.entries(completionAndRevenueRates).map(([key, data]) => {
          const { partnerCount, nonPartnerCount, paid, totalRevenue } = data
          const accounts = partnerCount + nonPartnerCount

          return {
            index: _capitalize(key),
            'Value Per Account': totalRevenue / accounts,
            'Value Per Customer': totalRevenue / paid,
          }
        })}
        valueFormat=">-$,.2~r"
      />

      <RawDataTable
        columnHeaders={Object.keys(completionAndRevenueRates).map((key) =>
          _capitalize(key),
        )}
        rowHeaders={[
          'Non-Partner',
          'Accounts',
          'Generated',
          'Paid',
          'Transaction Value',
        ]}
        data={Object.values(completionAndRevenueRates)}
        valueFormat={[
          (data) => data.nonPartnerCount,
          (data) => data.nonPartnerCount + data.partnerCount,
          (data) => data.generated,
          (data) => data.paid,
          (data) => format.currency(data.totalRevenue),
        ]}
      />

      <RawDataTable
        title="Efficiency"
        columnHeaders={Object.keys(completionAndRevenueRates).map((key) =>
          _capitalize(key),
        )}
        rowHeaders={['Generated/Accounts', 'Paid/Generated', 'Paid/Accounts']}
        data={Object.values(completionAndRevenueRates)}
        valueFormat={[
          (data) =>
            `${round.fixed(
              (data.generated / (data.nonPartnerCount + data.partnerCount)) *
                100,
              3,
            )}%`,
          (data) => `${round.fixed((data.paid / data.generated) * 100, 3)}%`,
          (data) =>
            `${round.fixed(
              (data.paid / (data.nonPartnerCount + data.partnerCount)) * 100,
              3,
            )}%`,
        ]}
      />

      <RawDataTable
        title="Revenue"
        columnHeaders={Object.keys(completionAndRevenueRates).map((key) =>
          _capitalize(key),
        )}
        rowHeaders={['Transaction Value/Account', 'Transaction Value/Customer']}
        data={Object.values(completionAndRevenueRates)}
        valueFormat={[
          (data) =>
            format.currency(
              data.totalRevenue / (data.nonPartnerCount + data.partnerCount),
            ),
          (data) => format.currency(data.totalRevenue / data.paid),
        ]}
      />
    </>
  )
}

export default CompletionAndRevenueRates
