import moment, { Moment } from 'moment'

const dateToString = (date: Date): string => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss-0800')
}

const stringToDate = (dateString: string) => {
  return moment(dateString).utc(true).utcOffset(-8).toDate()
}

const getNowPstDate = (): Date => {
  return moment().utc(true).utcOffset(-8).toDate()
}

const getNowPstString = () => dateToString(getNowPstDate())

const isDateInFuture = (date: Date | string | number | Moment): boolean => {
  return moment(date).isAfter(moment())
}

const format = (date: Date | string | number | Moment): string => moment(date).format('YYYY/MM/DD, h:mm:ssa')

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dateToString,
  stringToDate,
  getNowPstDate,
  isDateInFuture,
  getNowPstString,
  format
}