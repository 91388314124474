import {
  Box,
  List,
  Theme,
  Alert,
  Dialog,
  Button,
  Divider,
  ListItem,
  DialogTitle,
  useMediaQuery,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import moment from 'moment'
import { useState, useRef } from 'react'

import { Text } from 'components'
import { createPromoCode, PromoCode } from 'utils/api/promoCode.api'
import { copyText, spreadSx, standardiseErrorMessage, styles } from 'utils'

const Row = ({ title, value }: { title: string; value: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 2,
      }}
    >
      <Box
        sx={{
          width: '30%',
        }}
      >
        <Text>{title}:</Text>
      </Box>
      <Box
        sx={{
          width: '70%',
        }}
      >
        <Text>{value}</Text>
      </Box>
    </Box>
  )
}

const ConfirmationModal = ({
  show,
  setShow,
  promoCodeRequest,
}: {
  show: boolean
  setShow: Function
  promoCodeRequest: PromoCode
}) => {
  const contentContainerRef = useRef<HTMLDivElement>(null)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [isWorking, setIsWorking] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [createdPromoCodes, setCreatedPromoCodes] = useState<
    PromoCode[] | undefined
  >(undefined)

  const {
    isPercent,
    amount,
    prefix,
    code,
    expiryDate,
    oneTimeUse,
    numOfCodes,
    referrer,
    description,
  } = promoCodeRequest

  const promoCodeCount = numOfCodes ? numOfCodes : 1

  const handleCreate = () => {
    setIsWorking(!isWorking)

    createPromoCode(promoCodeRequest)
      .then((res) => {
        setError(undefined)
        setIsSuccessful(true)
        setCreatedPromoCodes(res.data.payload)
      })
      .catch((res) => {
        setError(standardiseErrorMessage(res.response.data.errorMessage))
      })
      .finally(() => {
        setTimeout(() => {
          setIsWorking(false)

          if (contentContainerRef.current) {
            contentContainerRef.current.scrollTop =
              contentContainerRef.current.scrollHeight || 0
          }
        }, 300)
      })
  }

  const resetState = () => {
    setIsWorking(false)
    setError(undefined)
    setIsSuccessful(false)
    setCreatedPromoCodes(undefined)
  }

  return (
    <Dialog
      open={show}
      PaperProps={{
        sx: {
          minWidth: isMobile ? '94vw' : '35rem',
          minHeight: isMobile ? '94vh' : '30rem',
          maxHeight: isMobile ? '100vh' : undefined,
          p: 2,
          m: isMobile ? 0 : '4rem',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {isWorking ? (
          <Text variant="h6">Please wait...</Text>
        ) : isSuccessful ? (
          <Text variant="h6">Success!</Text>
        ) : (
          <Text variant="h6">
            You are about to {numOfCodes ? 'generate' : 'create'}{' '}
            {promoCodeCount} promo
            {promoCodeCount > 1 ? ' codes' : ' code'}
          </Text>
        )}
      </DialogTitle>
      {isWorking ? (
        <DialogContent
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent ref={contentContainerRef}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 1,
              color: isSuccessful ? 'text.disabled' : undefined,
            }}
          >
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {code && [<Row title="Code" value={code} />, <Divider />]}
            <Row
              title="Amount"
              value={isPercent ? `${amount}% off` : `$${amount} off`}
            />
            <Divider />
            <Row
              title="Expiry"
              value={
                expiryDate
                  ? `${moment(expiryDate)
                      .utcOffset('-0800')
                      .format('dddd, MMMM Do YYYY, h:mm:ss a')} PST`
                  : 'None'
              }
            />
            <Divider />
            <Row title="Uses" value={oneTimeUse ? 'One' : 'Unlimited'} />
            <Divider />
            <Row
              title="Description"
              value={description ? description : 'None'}
            />
            <Divider />
            <Row
              title="Promo code type"
              value={
                referrer ? 'Auto apply (with ref/reftag)' : 'Manual apply only'
              }
            />
            <Divider />
            {referrer && [
              <Row title="Ref" value={referrer.ref} />,
              <Row title="Refteg" value={referrer.reftag} />,
              <Divider />,
            ]}
            {prefix && [<Row title="Prefix" value={prefix} />, <Divider />]}
          </Box>
          {isSuccessful && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text>
                  The following promo{' '}
                  {createdPromoCodes && createdPromoCodes.length > 1
                    ? 'codes have'
                    : 'code has'}{' '}
                  been created:
                </Text>
                <Button
                  onClick={() => {
                    copyText(
                      createdPromoCodes
                        ?.map((codeObj) => codeObj.code)
                        .join('\n') || '',
                    )
                  }}
                >
                  Copy
                </Button>
              </Box>
              <List
                sx={spreadSx([
                  {
                    maxHeight: '20rem',
                    overflow: 'scroll',
                    borderColor: 'text.secondary',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: 1,
                    marginBlock: 0,
                    mt: 1,
                    p: 2,
                  },
                  styles.scrollbar,
                ])}
              >
                {createdPromoCodes?.map((codeObj) => (
                  <ListItem>{codeObj.code}</ListItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => {
            setShow(false)
            resetState()
          }}
          variant="outlined"
          disabled={isWorking}
        >
          Go Back
        </Button>
        <Button
          onClick={handleCreate}
          variant="contained"
          disabled={isWorking || isSuccessful}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
