import { Box, Paper, Typography } from '@mui/material'
import { Children } from 'react'
import {
  Bar,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
} from 'recharts'

export type BarChartData = {
  values: {
    [key: string]: {
      value: number
      color: string
    }
  }
  label: string
}

const getIsSmallValue = (
  value: number,
  data: BarChartData[],
  isHorizontal: boolean,
) => {
  const largestBarSum = Math.max(
    ...data
      .map(({ values }) => values)
      .map((value) => Object.values(value).map(({ value }) => value))
      .map((values) => values.reduce((acc, value) => acc + value, 0)),
  )

  return value < largestBarSum / (isHorizontal ? 25 : 5)
}

interface CustomLabelProps {
  x: number
  y: number
  width: number
  height: number
  value: number
  data: BarChartData[]
  valuePadding: number
  formatLabel?: (label: number) => string
  isHorizontal: boolean
  dataKey: string
  index: number
}

const CustomLabel = (props: CustomLabelProps) => {
  const {
    x,
    y,
    width,
    height,
    value,
    data,
    valuePadding,
    formatLabel,
    isHorizontal,
    index,
    dataKey,
  } = props

  const isSmallValue = getIsSmallValue(value, data, isHorizontal)
  const isOdd = Object.keys(data[index].values).indexOf(dataKey) % 2 !== 0

  const labelText = formatLabel
    ? formatLabel(value - valuePadding)
    : value - valuePadding

  if (isHorizontal && isSmallValue) {
    return (
      <g>
        <line
          x1={x + width}
          y1={y + height / 1.8}
          x2={x + width + 20}
          y2={y + height / 1.8}
          stroke="gray"
        />
        <text
          x={x + width + 22}
          y={y + height / 1.8 + 4}
          fill="gray"
          fontSize={14}
        >
          {labelText}
        </text>
      </g>
    )
  }

  if (isHorizontal && !isSmallValue) {
    return (
      <text
        x={x + width / 2}
        y={y + height / 2 + 5}
        fill="white"
        textAnchor="middle"
        fontSize={14}
      >
        {labelText}
      </text>
    )
  }

  if (isSmallValue) {
    return (
      <g>
        <line
          x1={x + width / 2}
          y1={y + (isOdd ? height : 0)}
          x2={x + width / 2}
          y2={y + (isOdd ? height + 20 : -20)}
          stroke="gray"
        />
        <text
          x={x + width / 2}
          y={y + (isOdd ? height + 35 : -25)}
          fill="gray"
          textAnchor="middle"
        >
          {labelText}
        </text>
      </g>
    )
  }

  return (
    <text x={x + width / 2} y={y + height / 2} fill="white" textAnchor="middle">
      {labelText}
    </text>
  )
}

const valueAccessor =
  (attribute: string) =>
  ({ payload }: { payload: { [key: string]: number } }) => {
    return payload[attribute]
  }

interface BarChartProps {
  elevation?: number
  data: BarChartData[]
  valuePadding?: number
  ticks?: number
  layout?: 'horizontal' | 'vertical'
  formatLabel?: (label: number) => string
}

const BarChart = (props: BarChartProps) => {
  const {
    elevation = 8,
    data,
    valuePadding = 0,
    ticks = 5,
    layout = 'horizontal',
    formatLabel,
  } = props

  const isHorizontal = layout === 'horizontal'

  const keys = Object.keys(data[0].values).filter((key) => key !== 'color')

  const formattedData = data.map(({ values, ...rest }) => ({
    ...Object.entries(values).reduce(
      (acc, [key, { value }]) => ({ ...acc, [key]: value + valuePadding }),
      {},
    ),
    ...rest,
  })) as unknown as { [key: string]: number }[]

  const colors: { [key: string]: string } = Object.entries(
    data[0].values,
  ).reduce((acc, [key, { color }]) => ({ ...acc, [key]: color }), {})

  const legendData = Object.entries(data[0].values).map(([key, { color }]) => ({
    key,
    color,
  }))

  return (
    <Paper
      elevation={elevation}
      sx={{ position: 'relative', p: '1rem', pt: '1.5rem' }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          zIndex: 1000,
          p: '1rem',

          display: 'grid',
          gridTemplateColumns: 'max-content min-content',
          gridTemplateRows: `repeat(${legendData.length}, 1fr)`,
          gridColumnGap: '0.5rem',
          gridRowGap: '0.5rem',
        }}
      >
        {Children.toArray(
          legendData.map(({ color, key }) => [
            <Typography sx={{ fontSize: '0.8rem', textAlign: 'right' }}>
              {key}
            </Typography>,
            <Box
              sx={{
                height: '1rem',
                width: '1rem',
                backgroundColor: color,
                mt: '1px',
              }}
            />,
          ]),
        )}
      </Box>
      <ResponsiveContainer height={700} width="100%">
        <RechartsBarChart
          data={formattedData}
          barCategoryGap={30}
          margin={{ right: 30, left: 20 }}
          layout={layout}
        >
          <YAxis
            interval={isHorizontal ? 'preserveStartEnd' : undefined}
            tickCount={isHorizontal ? ticks : undefined}
            dataKey={isHorizontal ? undefined : 'label'}
            type={isHorizontal ? undefined : 'category'}
          />
          <XAxis
            interval={isHorizontal ? undefined : 'preserveStartEnd'}
            tickCount={isHorizontal ? undefined : ticks}
            dataKey={isHorizontal ? 'label' : undefined}
            type={isHorizontal ? undefined : 'number'}
          />
          <CartesianGrid vertical={!isHorizontal} horizontal={isHorizontal} />
          {Children.toArray(
            keys.map((key) => (
              <Bar
                dataKey={key}
                stackId="a"
                fill={colors[key]}
                maxBarSize={isHorizontal ? undefined : 200}
              >
                <LabelList
                  content={
                    // @ts-ignore Bar doesn't type its stuff properly
                    <CustomLabel
                      data={data}
                      valuePadding={valuePadding}
                      formatLabel={formatLabel}
                      isHorizontal={isHorizontal}
                      dataKey={key}
                    />
                  }
                  valueAccessor={valueAccessor(key)}
                />
              </Bar>
            )),
          )}
        </RechartsBarChart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default BarChart
