import _round from 'lodash/round'
import _floor from 'lodash/floor'
import _ceil from 'lodash/ceil'

const round = (value: number, precision?: number) =>
  _round(value, precision ?? 2)

round.fixed = (value: number, precision?: number) =>
  round(value, precision ?? 2).toFixed(precision ?? 2)

round.floor = (value: number, precision?: number) =>
  _floor(value, precision ?? 2)

round.ceil = (value: number, precision?: number) => _ceil(value, precision ?? 2)

round.nearest = (
  value: number,
  options?: { direction?: 'floor' | 'ceil'; nearest?: number },
) => {
  const { direction = 'ceil', nearest = 100 } = options ?? {}

  if (value % nearest === 0) {
    return value
  } else {
    return Math[direction](value / nearest) * nearest
  }
}

export default round
