import { PaletteMode } from '@mui/material'
import darkTheme from './darkTheme'
import lightTheme from './lightTheme'

const getTheme = (mode: PaletteMode) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // Standard mobile
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? lightTheme
      : darkTheme),
  },
})

export default getTheme