import { useContext } from 'react'

import { UserContext } from 'utils'
import PaymentsList from './payment/PaymentsList'
import AvailableAddons from './payment/AvailableAddons'
import AssociatedCodes from './payment/AssociatedCodes'

const PaymentDetails = () => {
  const { data } = useContext(UserContext) || {}

  return data ? (
    <>
      <PaymentsList data={data} />
      <AvailableAddons data={data} />
      <AssociatedCodes data={data} />
    </>
  ) : (
    <></>
  )
}

export default PaymentDetails
