import {
  Box,
  List,
  Theme,
  Alert,
  Dialog,
  Button,
  Divider,
  ListItem,
  DialogTitle,
  ListItemText,
  useMediaQuery,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import { useState } from 'react'
import _unset from 'lodash/unset'
import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import _isBoolean from 'lodash/isBoolean'

import {
  Contact,
  CharityPartner,
  createCharityPartner,
} from 'utils/api/charityPartner.api'
import { Text, QrCodeModal } from 'components'
import { Environment } from 'utils/helpers/getEnv'
import { copyText, getEnv, standardiseErrorMessage } from 'utils'
import { CharityPartnerPromoCodeData } from './CreateCharityPartnerView'
import { createPromoCode, PromoCode } from 'utils/api/promoCode.api'

const Row = ({ title, value }: { title: string; value: string }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          columnGap: 2,
        }}
      >
        <Box
          sx={{
            width: '30%',
          }}
        >
          <Text>{title}:</Text>
        </Box>
        <Box
          sx={{
            width: '70%',
          }}
        >
          <Text>{value}</Text>
        </Box>
      </Box>
      <Divider />
    </>
  )
}

const replaceAppUrl = (successValues: SuccessValues): SuccessValues => {
  const environment = getEnv()

  if (environment === Environment.Staging) {
    return {
      ...successValues,
      appUrl: successValues.appUrl.replace(
        'https://app.epiloguewills.com',
        'https://app-staging.epiloguewills.com',
      ),
    }
  }

  if (environment === Environment.Dev) {
    return {
      ...successValues,
      appUrl: successValues.appUrl.replace(
        'https://app.epiloguewills.com',
        'http://localhost:3000',
      ),
    }
  }

  return successValues
}

interface SuccessValues {
  slug: string
  appUrl: string
  marketingSiteUrl: string
  promoCode?: PromoCode
}

const ConfirmationModal = ({
  show,
  setShow,
  partnerData,
  promoCodeData,
}: {
  show: boolean
  setShow: Function
  partnerData: CharityPartner
  promoCodeData: CharityPartnerPromoCodeData
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [qrCodeValue, setQrCodeValue] = useState('')
  const [showQrModal, setShowQrModal] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [successValues, setSuccessValues] = useState<SuccessValues>({
    slug: '',
    appUrl: '',
    marketingSiteUrl: '',
  })

  const clonedPartnerData: CharityPartner = _cloneDeep(partnerData)

  Object.keys(clonedPartnerData).forEach((key) => {
    if (key === 'contact' && clonedPartnerData.contact !== undefined) {
      Object.keys(clonedPartnerData.contact).forEach((contactKey) => {
        if (
          clonedPartnerData.contact !== undefined &&
          clonedPartnerData.contact[contactKey as keyof Contact] === ''
        ) {
          _unset(clonedPartnerData, `contact.${contactKey}`)
        }
      })
    }

    if (key === 'contractStart') {
      // @ts-expect-error: TS-TODO
      clonedPartnerData.contractStart = clonedPartnerData.contractStart.toJSON()
    }

    if (
      !_isBoolean(clonedPartnerData[key as keyof CharityPartner]) &&
      (clonedPartnerData[key as keyof CharityPartner] === '' ||
        _isEmpty(clonedPartnerData[key as keyof CharityPartner]))
    ) {
      _unset(clonedPartnerData, key)
    }
  })

  const handleCreate = () => {
    setIsWorking(true)

    createCharityPartner(clonedPartnerData)
      .then((res) => {
        setIsSuccessful(true)

        if (promoCodeData.createPromoCode) {
          const urlParams = new URLSearchParams(
            `?${res.data.payload.appUrl.split('?')[1]}`,
          )
          const ref = urlParams.get('ref') || ''
          const reftag = urlParams.get('reftag') || ''

          createPromoCode({
            isPercent: promoCodeData.isPercent,
            amount: parseFloat(promoCodeData.amount),
            code: promoCodeData.code,
            oneTimeUse: false,
            isActive: true,
            referrer: {
              ref,
              reftag,
            },
            description: promoCodeData.description,
          })
            .then((promoRes) => {
              setSuccessValues({
                ...replaceAppUrl(res.data.payload),
                promoCode: promoRes.data.payload[0],
              })
            })
            .catch((error) => {
              setError(
                `Error Creating Promo Code: ${standardiseErrorMessage(
                  error.response.data.errorMessage,
                )}`,
              )
            })
        } else {
          setSuccessValues(replaceAppUrl(res.data.payload))
        }
      })
      .catch((res) => {
        setError(standardiseErrorMessage(res.response.data.errorMessage))
      })
      .finally(() => {
        setTimeout(() => {
          setIsWorking(false)
        }, 300)
      })
  }

  const resetState = () => {
    setIsWorking(false)
    setError(undefined)
    setIsSuccessful(false)
  }

  return (
    <>
      <QrCodeModal
        isOpen={showQrModal}
        setIsOpen={setShowQrModal}
        value={qrCodeValue}
      />
      <Dialog
        open={show}
        PaperProps={{
          sx: {
            minWidth: isMobile ? '94vw' : '35rem',
            minHeight: isMobile ? '94vh' : '30rem',
            maxHeight: isMobile ? '100vh' : undefined,
            p: 2,
            m: isMobile ? 0 : '4rem',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {isWorking ? (
            <Text variant="h6">Please wait...</Text>
          ) : isSuccessful ? (
            <Text variant="h6">
              Success, the new charity partner has been created!
            </Text>
          ) : (
            <Text variant="h6">
              You are about to create a new charity partner.
            </Text>
          )}
        </DialogTitle>
        {isWorking ? (
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </DialogContent>
        ) : (
          <DialogContent>
            {isSuccessful && (
              <Box
                sx={{
                  mb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 2,
                  borderRadius: 1,
                  border: 'solid 1px black',
                  p: 2,
                }}
              >
                <List>
                  <ListItem>
                    <ListItemText>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Text component="div" sx={{ width: '30%' }}>
                          App URL:
                        </Text>
                        <Text component="div" sx={{ width: '50%', mr: 2 }}>
                          {successValues.appUrl}
                        </Text>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 1,
                          }}
                        >
                          <Button
                            onClick={() => {
                              copyText(successValues.appUrl)
                            }}
                          >
                            Copy
                          </Button>
                          <Button
                            onClick={() => {
                              setQrCodeValue(successValues.appUrl)
                              setShowQrModal(true)
                            }}
                          >
                            QR Code
                          </Button>
                        </Box>
                      </Box>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Text component="div" sx={{ width: '30%' }}>
                          Marketing URL:
                        </Text>
                        <Text
                          component="div"
                          sx={{ width: '50%', mr: 2, wordBreak: 'break-all' }}
                        >
                          {successValues.marketingSiteUrl}
                        </Text>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 1,
                          }}
                        >
                          <Button
                            onClick={() => {
                              copyText(successValues.marketingSiteUrl)
                            }}
                          >
                            Copy
                          </Button>
                          <Button
                            onClick={() => {
                              setQrCodeValue(successValues.marketingSiteUrl)
                              setShowQrModal(true)
                            }}
                          >
                            QR Code
                          </Button>
                        </Box>
                      </Box>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Text component="div" sx={{ width: '30%' }}>
                          Slug:
                        </Text>
                        <Text
                          component="div"
                          sx={{ width: '50%', mr: 2, wordBreak: 'break-all' }}
                        >
                          {successValues.slug}
                        </Text>
                        <Button
                          onClick={() => {
                            copyText(successValues.slug)
                          }}
                        >
                          Copy
                        </Button>
                      </Box>
                    </ListItemText>
                  </ListItem>
                  {successValues.promoCode && (
                    <>
                      <Divider />
                      <ListItem>
                        <ListItemText>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Text component="div" sx={{ width: '30%' }}>
                              Promo Code:
                            </Text>
                            <Text
                              component="div"
                              sx={{
                                width: '70%',
                                mr: 2,
                                wordBreak: 'break-all',
                              }}
                            >
                              {successValues.promoCode.code || ''}
                            </Text>
                          </Box>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Text component="div" sx={{ width: '30%' }}>
                              Promo Amount:
                            </Text>
                            <Text
                              component="div"
                              sx={{
                                width: '70%',
                                mr: 2,
                                wordBreak: 'break-all',
                              }}
                            >
                              {`${
                                !successValues.promoCode.isPercent ? '$' : ''
                              }${successValues.promoCode.amount}${
                                successValues.promoCode.isPercent ? '%' : ''
                              } off`}
                            </Text>
                          </Box>
                        </ListItemText>
                      </ListItem>
                    </>
                  )}
                </List>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 1,
                color: isSuccessful ? 'text.disabled' : undefined,
              }}
            >
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              {clonedPartnerData.name && (
                <Row title="Name" value={clonedPartnerData.name} />
              )}

              {clonedPartnerData.displayName && (
                <Row
                  title="Display Name"
                  value={clonedPartnerData.displayName}
                />
              )}

              {clonedPartnerData.number && (
                <Row title="Number" value={clonedPartnerData.number} />
              )}

              {clonedPartnerData.location && (
                <Row title="Location" value={clonedPartnerData.location} />
              )}

              <Row
                title="Foundation Type"
                value={
                  clonedPartnerData.isPublicFoundation
                    ? 'Public Foundation'
                    : 'Non-public Foundation'
                }
              />

              {clonedPartnerData.contact?.name && (
                <Row
                  title="Contact Name"
                  value={clonedPartnerData.contact.name}
                />
              )}

              {clonedPartnerData.contact?.email && (
                <Row
                  title="Contact Email"
                  value={clonedPartnerData.contact.email}
                />
              )}

              {clonedPartnerData.contact?.phone && (
                <Row
                  title="Contact Phone"
                  value={clonedPartnerData.contact.phone}
                />
              )}

              {clonedPartnerData.contact?.mailingAddress && (
                <Row
                  title="Contact Mailing Address"
                  value={clonedPartnerData.contact.mailingAddress}
                />
              )}

              {clonedPartnerData.shortDescription && (
                <Row
                  title="Short Description"
                  value={clonedPartnerData.shortDescription}
                />
              )}

              {clonedPartnerData.longDescription && (
                <Row
                  title="Long Description"
                  value={clonedPartnerData.longDescription}
                />
              )}

              {clonedPartnerData.notifyDescription && (
                <Row
                  title="Notify Description"
                  value={clonedPartnerData.notifyDescription}
                />
              )}

              {promoCodeData.createPromoCode && (
                <Row title="Promo Code" value={promoCodeData.code} />
              )}

              {promoCodeData.createPromoCode && (
                <Row
                  title="Promo Amount"
                  value={`${!promoCodeData.isPercent ? '$' : ''}${
                    promoCodeData.amount
                  }${promoCodeData.isPercent ? '%' : ''} off`}
                />
              )}

              {promoCodeData.createPromoCode && (
                <Row
                  title="Promo Description"
                  value={promoCodeData.description}
                />
              )}
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setShow(false)
              resetState()
            }}
            variant="outlined"
            disabled={isWorking}
          >
            Go Back
          </Button>
          <Button
            onClick={handleCreate}
            variant="contained"
            disabled={isWorking || isSuccessful}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmationModal
