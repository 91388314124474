import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Card, CardActionArea, Divider } from '@mui/material'

import views from 'config/views'
import { Text } from 'components'

interface QuickLinkProps {
  title: string
  description: string
  link: string
  icon?: object
}

const QuickLink = ({ title, description, link, icon }: QuickLinkProps) => {
  const navigate = useNavigate()

  return (
    <Card variant="outlined">
      <CardActionArea
        onClick={() => {
          navigate(link)
        }}
      >
        <Box
          sx={{
            width: '15rem',
            height: '15rem',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1,
            overflow: 'clip',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              alignItems: 'center',
            }}
          >
            {icon}
            <Text variant="h6">{title}</Text>
          </Box>
          <Divider />
          <Text>{description}</Text>
        </Box>
      </CardActionArea>
    </Card>
  )
}

const HomeView = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      <Box>
        <Alert severity="info">
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <div>Welcome to the Epilogue Admin Panel!</div>
            <div>
              As features and such get added, they will become available in the
              menu that you can access by clicking the menu in the top right of
              your screen, or one of the quick links below
            </div>
            <div>
              Find a problem or have an idea for something you would like to be
              able to do here? Send Austin a message!
            </div>
          </Box>
        </Alert>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          rowGap: 2,
          columnGap: 2,
          mx: 'auto',
          justifyContent: 'center',
        }}
      >
        {React.Children.toArray(
          views.map((section) =>
            React.Children.toArray(
              section.children
                .filter((link) => link.showOnHomePage)
                .map((child) => {
                  const { title, Icon, path, description } = child
                  return (
                    <QuickLink
                      title={title}
                      description={description}
                      link={path}
                      icon={<Icon />}
                    />
                  )
                }),
            ),
          ),
        )}
      </Box>
    </Box>
  )
}

export default HomeView
