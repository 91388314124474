import { useEffect, useState } from 'react'

import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  SelectChangeEvent,
  Checkbox,
} from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface InputMultiSelectProps {
  label: string
  values: string[]
  onChange: (selectedValues: string[]) => void
  selectedValues: string[]
  transformSelectText?: (text: string) => string
  hideLabel?: boolean
}

const InputMultiSelect = ({
  label,
  values,
  onChange,
  hideLabel,
  selectedValues,
  transformSelectText,
}: InputMultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(selectedValues)

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event

    setSelectedOptions(typeof value === 'string' ? value.split(',') : value)
  }

  useEffect(() => {
    onChange(selectedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  useEffect(() => {
    setSelectedOptions(selectedValues)
  }, [selectedValues])

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id={`${label.replaceAll(' ', '-')}-multiple-checkbox-label`}>
        {hideLabel ? '' : label}
      </InputLabel>
      <Select
        labelId={`${label.replaceAll(' ', '-')}-multiple-checkbox-label`}
        id={`${label.replaceAll(' ', '-')}-multiple-checkbox`}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={<OutlinedInput label={hideLabel ? '' : label} />}
        renderValue={(selected) =>
          selected
            .map((value) =>
              transformSelectText ? transformSelectText(value) : value,
            )
            .join(', ')
        }
        MenuProps={MenuProps}
      >
        {values.map((value) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={selectedOptions.indexOf(value) > -1} />
            <ListItemText
              primary={transformSelectText ? transformSelectText(value) : value}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default InputMultiSelect
