import { useState } from 'react'
import download from 'downloadjs'
import { Range } from 'react-date-range'
import { Paper, Button } from '@mui/material'

import { queryClient } from 'utils'
import { DateRangePicker } from 'components'

interface DataControlsProps {
  invalidationKey: any | any[]
  getCsvRequest: () => Promise<string>
  downloadTitle: string
  range?: Range
  setRange?: (range: Range) => void
}

const DataControls = (props: DataControlsProps) => {
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false)
  const { invalidationKey, getCsvRequest, downloadTitle, range, setRange } =
    props

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        p: 2,
        mb: 1,
        columnGap: 2,
        alignItems: 'flex-start',
      }}
    >
      <Button
        variant="contained"
        disabled={isDownloadingCsv}
        onClick={() => {
          queryClient.invalidateQueries(invalidationKey)
        }}
      >
        Refresh Data
      </Button>
      <Button
        variant="contained"
        disabled={isDownloadingCsv}
        onClick={() => {
          setIsDownloadingCsv(true)
          getCsvRequest()
            .then((data) => {
              download(data, downloadTitle)
            })
            .catch(() => {
              window.alert('Failed to download CSV Data')
            })
            .finally(() => {
              setIsDownloadingCsv(false)
            })
        }}
      >
        Download All Data as CSV
      </Button>
      {range && setRange && (
        <DateRangePicker range={range} setRange={setRange} />
      )}
    </Paper>
  )
}

export default DataControls
