import _startCase from 'lodash/startCase'
import { Box, useMediaQuery, Theme } from '@mui/material'

import { InputSelect, TitledWrapper, InputMultiSelect } from 'components'

export interface Filters {
  minAge: number
  maxAge: number
  genders: string[]
  provinces: string[]
  hasChildren: string[]
  hasPartner: string[]
  charities: string[]
}

const StatsFilter = ({
  filters,
  updateFilters,
  initialFilterData,
}: {
  filters: Filters
  initialFilterData: Filters
  updateFilters: (newFilter: object) => void
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'))

  const {
    minAge,
    maxAge,
    genders,
    provinces,
    hasChildren,
    hasPartner,
    charities,
  } = initialFilterData

  const ageValues = [...Array(maxAge - minAge + 1).keys()].map((age) => ({
    value: age + minAge,
    label: (age + minAge).toString(),
  }))

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
        gridTemplateRows: '1fr',
        rowGap: 2,
        columnGap: 2,
      }}
    >
      <TitledWrapper
        title="Age Range"
        sx={{ py: 2, display: 'flex', columnGap: 2, alignItems: 'center' }}
      >
        <InputSelect
          fullWidth
          label=""
          value={filters.minAge}
          options={ageValues}
          onChange={(value) => {
            updateFilters({ minAge: value as number })
          }}
        />
        to
        <InputSelect
          fullWidth
          label=""
          value={filters.maxAge}
          options={ageValues}
          onChange={(value) => {
            updateFilters({ maxAge: value as number })
          }}
        />
      </TitledWrapper>
      <TitledWrapper
        title="Genders"
        sx={{ py: 2, display: 'flex', columnGap: 2, alignItems: 'center' }}
      >
        <InputMultiSelect
          label="Genders"
          hideLabel
          values={genders}
          transformSelectText={_startCase}
          selectedValues={filters.genders}
          onChange={(genders) => {
            updateFilters({ genders })
          }}
        />
      </TitledWrapper>
      <TitledWrapper
        title="Provinces"
        sx={{ py: 2, display: 'flex', columnGap: 2, alignItems: 'center' }}
      >
        <InputMultiSelect
          label=""
          values={provinces}
          transformSelectText={_startCase}
          selectedValues={filters.provinces}
          onChange={(provinces) => {
            updateFilters({ provinces })
          }}
        />
      </TitledWrapper>
      <TitledWrapper
        title="Has Partner"
        sx={{ py: 2, display: 'flex', columnGap: 2, alignItems: 'center' }}
      >
        <InputMultiSelect
          label=""
          values={hasPartner}
          transformSelectText={_startCase}
          selectedValues={filters.hasPartner}
          onChange={(hasPartner) => {
            updateFilters({ hasPartner })
          }}
        />
      </TitledWrapper>
      <TitledWrapper
        title="Has Children"
        sx={{ py: 2, display: 'flex', columnGap: 2, alignItems: 'center' }}
      >
        <InputMultiSelect
          label=""
          values={hasChildren}
          transformSelectText={_startCase}
          selectedValues={filters.hasChildren}
          onChange={(hasChildren) => {
            updateFilters({ hasChildren })
          }}
        />
      </TitledWrapper>
      <TitledWrapper
        title="Charities"
        sx={{
          py: 2,
          display: 'flex',
          columnGap: 2,
          alignItems: 'center',
        }}
      >
        <InputMultiSelect
          label=""
          values={charities}
          selectedValues={filters.charities}
          onChange={(charities) => {
            updateFilters({ charities })
          }}
        />
      </TitledWrapper>
    </Box>
  )
}

export default StatsFilter
