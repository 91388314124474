import { useState } from 'react'
import _startCase from 'lodash/startCase'
import { Alert, Box, Button } from '@mui/material'

import { Modal } from 'components'
import { useMutateUserData, useGetMe } from 'utils'
import { UpdateUserRequest } from 'utils/api/user.types'
import { CheckCircleOutline } from '@mui/icons-material'

enum ChangeType {
  UpdatingRole,
  LockingAccount,
  UnlockingAccount,
}

const ConfirmationModal = ({
  show,
  userId,
  onClose,
  userEmail,
  updateRequestData,
}: {
  show: boolean
  userId: string
  userEmail: string
  onClose: () => void
  updateRequestData: UpdateUserRequest
}) => {
  const [error, setError] = useState<string | undefined>(undefined)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const { mutateAsync: mutateUserData, isLoading } = useMutateUserData()
  const { data: me } = useGetMe()

  const isUpdatingOwnAccount = me?._id === userId

  const changeType = updateRequestData.role
    ? ChangeType.UpdatingRole
    : updateRequestData.accountLocked
    ? ChangeType.LockingAccount
    : ChangeType.UnlockingAccount

  const resetState = () => {
    setIsSuccessful(false)
    setError(undefined)
  }

  return (
    <Modal
      preventClose={isLoading}
      working={isLoading}
      onClose={() => {
        resetState()
        onClose()
      }}
      show={show}
      title={
        changeType === ChangeType.UpdatingRole
          ? 'Update User Role'
          : changeType === ChangeType.LockingAccount
          ? 'Lock User Account'
          : 'Unlock User Account'
      }
      actions={
        <>
          <Button
            onClick={() => {
              resetState()
              onClose()
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              mutateUserData({
                userId: userId,
                data: updateRequestData,
              })
                .then(() => {
                  setIsSuccessful(true)
                })
                .catch((res) => {
                  setError(
                    res.response.data.errorMessage ||
                      'Failed to update user locked status',
                  )
                })
            }}
            disabled={isSuccessful || isLoading}
          >
            Confirm
          </Button>
        </>
      }
    >
      <Box sx={{ textAlign: 'center', lineHeight: 1.5, width: '30rem' }}>
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}

        {!isSuccessful && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: 2,
            }}
          >
            {isUpdatingOwnAccount && (
              <Alert severity="warning">
                Hold up! You are about to make a change to your own account!
              </Alert>
            )}
            {changeType === ChangeType.UpdatingRole
              ? `Are you sure you want to
          change the role for ${userEmail} to '${_startCase(
                  updateRequestData.role,
                )}'?`
              : changeType === ChangeType.LockingAccount
              ? `Are you sure you want to
          lock the user account for ${userEmail}?`
              : `Are you sure you want to
          unlock the user account for ${userEmail}?`}
          </Box>
        )}

        {isSuccessful && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: 2,
            }}
          >
            <CheckCircleOutline fontSize="large" color="success" />
            <>
              {changeType === ChangeType.UpdatingRole
                ? `Role for ${userEmail} has successfully been changed to '${_startCase(
                    updateRequestData.role,
                  )}'`
                : changeType === ChangeType.LockingAccount
                ? `User account for ${userEmail} has been locked successfully`
                : `User account for ${userEmail} has been unlocked successfully`}
            </>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
