import { useState } from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import { Box, Divider } from '@mui/material'

import { DistributionCharitiesResponse } from 'utils/api/data.types'

import ChartsByAge from './ChartsByAge'
import { InputSelect } from 'components'
import ChartsByProvince from './ChartsByProvince'
import getTotalledData from '../utils/getTotalledData'
import StatsFilter, { Filters } from '../../components/StatsFilter'
import SingleStat from 'views/AnalyticsAndReportsViews/components/SingleStat'
import LeaderboardStat from 'views/AnalyticsAndReportsViews/components/LeaderboardStat'

enum DataBy {
  Province = 'province',
  Age = 'age',
}

const DistributionCharitiesGiftsStats = ({
  data,
  filters,
  updateFilters,
  initialFilterData,
}: {
  filters: Filters
  initialFilterData: Filters
  data: DistributionCharitiesResponse[]
  updateFilters: (newFilter: object) => void
}) => {
  const clonedData = _cloneDeep(data)
  const filteredData = clonedData
  const totalledFilteredData = getTotalledData(data)

  const [dataBy, setDataBy] = useState<DataBy>(DataBy.Province)

  const topCharityByDonationCount = [...totalledFilteredData]
    .sort((a, b) => b.count - a.count)
    .slice(0, 5)

  // const topCharityByAverageDonation = [...totalledFilteredData]
  //   .sort((a, b) => b.averagePercentage - a.averagePercentage)
  //   .slice(0, 5)

  const totalDonatedPortions = [...filteredData]
    .map((gift) => gift.portions)
    .reduce((a, b) => a + b, 0)

  const totalOverallPortions = [...filteredData]
    .map((gift) => gift.totalPortions)
    .reduce((a, b) => a + b, 0)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        rowGap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <StatsFilter
          initialFilterData={initialFilterData}
          filters={filters}
          updateFilters={updateFilters}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: '1fr',
          rowGap: 2,
          columnGap: 2,
        }}
      >
        <SingleStat
          title="Number of Donations"
          value={filteredData.length.toString()}
        />
        <SingleStat
          title="Average Donation"
          value={`${(
            (totalDonatedPortions / totalOverallPortions) *
            100
          ).toFixed(2)}%`}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridTemplateRows: '1fr',
          rowGap: 2,
          columnGap: 2,
        }}
      >
        <LeaderboardStat
          title="Top charities by donation count"
          values={topCharityByDonationCount.map((charity) => ({
            amount: charity.count.toString(),
            attribution: charity.name,
          }))}
        />
        {/* <LeaderboardStat
          title="Top charities by average donation"
          values={topCharityByAverageDonation.map((charity) => ({
            amount: `${(charity.averagePercentage * 100).toFixed(2)}%`,
            attribution: charity.name,
          }))}
        /> */}
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <Box sx={{ width: '18rem' }}>
          <InputSelect
            fullWidth
            label="Display Data By"
            value={dataBy}
            onChange={(value) => setDataBy(value as DataBy)}
            options={[
              { label: 'Province', value: DataBy.Province },
              { label: 'Age', value: DataBy.Age },
            ]}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
          }}
        >
          {dataBy === DataBy.Province && (
            <ChartsByProvince filteredData={filteredData} />
          )}

          {dataBy === DataBy.Age && <ChartsByAge filteredData={filteredData} />}
        </Box>
      </Box>
    </Box>
  )
}

export default DistributionCharitiesGiftsStats
