import { useQuery } from 'react-query'

import { getUserDetails } from 'utils/api/user.api'
import { UserEndpoint } from 'utils/api/user.types'

const useGetUserDetails = (userId: string) => useQuery({
  queryKey: `${UserEndpoint.User}/${userId}`,
  queryFn: () => getUserDetails(userId),
  retry: false,
  staleTime: 60000, // 1 minute
  refetchOnWindowFocus: false,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetUserDetails