import {
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { QRCodeCanvas } from 'qrcode.react'

import { Text } from 'components'

const QrCodeModal = ({
  isOpen,
  setIsOpen,
  value,
}: {
  isOpen: boolean
  setIsOpen: (arg0: boolean) => void
  value: string
}) => {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            rowGap: 2,
          }}
        >
          <Box sx={{ m: 'auto' }}>
            <QRCodeCanvas size={256} value={value} />
          </Box>
          <Text align="center">
            Right click QR Code and 'Save Image As' to download
          </Text>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsOpen(false)
          }}
          variant="contained"
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QrCodeModal
