import _startCase from 'lodash/startCase'

const doctype = (doctype: string): string => {
  switch (doctype) {
    case 'personalPoa':
      return 'Personal POA'
    case 'propertyPoa':
      return 'Property POA'
    case 'will':
      return 'Last Will & Testament'
    case 'wishes':
      return 'Funeral & Burial Wishes'
    case 'social':
      return 'Social Media Will'
    case 'willPoa':
      return 'Will and POA'
    default:
      return doctype
  }
}

const products = (productId: number): string => {
  switch (productId) {
    case 0:
      return 'Add-On Only'
    case 1:
      return 'Will'
    case 2:
      return 'Will + POAs'
    case 3:
      return 'Will(x2)'
    case 4:
      return 'Will + POAs (x2)'
    default:
      return `Product ID ${productId.toString()}`
  }
}

const questionnaireKeys = (key: string): string => {
  return _startCase(key.replace('social_', ''))
    .replace('Co Trustee', 'Co-trustee')
    .replace('Alt Trustee', 'Alt-trustee')
    .replace('Poa', 'POA')
    .replace('Dnr', 'DNR')
    .replace('Co Property', 'Co-property')
    .replace('Co Personal', 'Co-personal')
    .replace('Alt Partner', 'Alt-partner')
    .replace('Resp', 'RESP')
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  doctype,
  products,
  questionnaireKeys
}