import { LineChart } from 'components'
import calculateGraphData from '../utils/calculateGraphData'
import { DistributionCharitiesResponse } from 'utils/api/data.types'

const ChartsByAge = ({
  filteredData,
}: {
  filteredData: DistributionCharitiesResponse[]
}) => {
  const averageDonationsByAge =
    calculateGraphData.averageDonationsByAge(filteredData)

  const countOfDonationsByAge =
    calculateGraphData.countOfDonationsByAge(filteredData)

  return (
    <>
      <LineChart
        height="20rem"
        leftLabel="Average Donated Amount"
        bottomLabel="Age"
        data={[averageDonationsByAge]}
        yFormat={(value) => `${((value as number) * 100).toFixed(2)}%`}
      />
      <LineChart
        height="20rem"
        leftLabel="Donation Count"
        bottomLabel="Age"
        data={[countOfDonationsByAge]}
      />
    </>
  )
}

export default ChartsByAge
