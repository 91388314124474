import { useQuery } from 'react-query'
import { DataEndpoint } from 'utils/api/data.types'
import { getDistributionCharitiesJson } from 'utils/api/data.api'

const useGetDistributionCharitiesJson = () => useQuery({
  queryKey: [DataEndpoint.DistributionCharities, 'json'],
  queryFn: () => getDistributionCharitiesJson(),
  retry: false,
  staleTime: 21600000, // 6 hours
  refetchOnWindowFocus: false,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetDistributionCharitiesJson