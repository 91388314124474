import { Province } from 'global.types'

export enum LawyerEndpoint {
  Lawyer = '/lawyer',
  FlatFee = '/lawyer/flat_fee',
  Active = '/lawyer/active/'
}

export interface Lawyer {
  _id?: string
  bio?: string
  city: string
  email: string
  imageUrl: string
  worksRemote: boolean
  active: boolean
  province: Province
  lawyerFirstName?: string
  lawyerLastName?: string
  contactFirstName?: string
  contactLastName?: string
  firm?: string
  highlights?: string[]
  flatFeeData?: FlatFeeHighlights
}

export interface FlatFeeHighlights {
  default: FlatFeeHighlight
  spousalTrust?: FlatFeeHighlight
  familyDisability?: FlatFeeHighlight
  notRightForMe?: FlatFeeHighlight
}

export interface FlatFeeHighlight {
  price: number
  highlights: string[]
}
