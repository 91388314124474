import { forwardRef } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, AppBar, Button, Toolbar, IconButton } from '@mui/material'

import { Text } from 'components'
import views, { View } from 'config/views'
import { logout } from 'utils/api/user.api'
import { Environment } from 'utils/helpers/getEnv'
import { getEnv, queryClient, useAuth } from 'utils'

interface TopMenuBarProps {
  isMainMenuDisplayed: boolean
  setIsMainMenuDisplayed: Function
}

const TopMenuBar = forwardRef<HTMLDivElement, TopMenuBarProps>(
  ({ isMainMenuDisplayed, setIsMainMenuDisplayed }: TopMenuBarProps, ref) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { data: isAuth } = useAuth()
    const env = getEnv()
    const location = useLocation()

    const [viewData]: View[] = views
      .flatMap((parent) => parent.children)
      .filter((child) => child.path === location.pathname)

    return (
      <AppBar
        ref={ref}
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setIsMainMenuDisplayed(!isMainMenuDisplayed)
            }}
            disabled={pathname === '/login'}
          >
            {isMainMenuDisplayed ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Text variant="h6">Epilogue Admin Panel</Text>
          <Text variant="body" sx={{ ml: '5px', mt: '2px' }}>
            {env === Environment.Staging
              ? '(staging)'
              : env === Environment.Dev
              ? '(dev)'
              : ''}
          </Text>
          <Box
            sx={{
              flexGrow: 1,
              ml: '0.75rem',
              borderLeft: viewData ? 'solid 1px #FFFFFF' : undefined,
              pl: '0.75rem',
            }}
          >
            {viewData && (
              <Text
                sx={{ position: 'relative', top: '1px' }}
                variant="subtitle1"
              >
                {viewData.title}
              </Text>
            )}
          </Box>
          <Button
            color="inherit"
            disabled={pathname === '/login'}
            onClick={() => {
              if (isAuth) {
                logout()
                  .then(() => {
                    queryClient.invalidateQueries()
                  })
                  .finally(() => {
                    navigate('/login')
                  })
              } else {
                navigate('/login')
              }
            }}
          >
            {isAuth ? 'Logout' : 'Login'}
          </Button>
        </Toolbar>
      </AppBar>
    )
  },
)

export default TopMenuBar
