import _capitalize from 'lodash/capitalize'
import { GridColDef } from '@mui/x-data-grid'

import { dates } from 'utils'

const allData: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 350,
    description: 'Name of the charity',
    valueFormatter: ({ value }) => value.toUpperCase(),
  },
  {
    field: 'number',
    headerName: 'Number',
    width: 150,
    description: 'Registration number of charity if available',
    valueFormatter: ({ value }) => (value ? value : 'N/A'),
  },
  {
    field: 'portions',
    headerName: 'Portions',
    width: 100,
    align: 'right',
    headerAlign: 'right',
    description: 'Value of donation as portions of remaining estate at time of distribution',
  },
  {
    field: 'portionsAsPercentage',
    headerName: 'Portions as Percentage',
    width: 200,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    description: 'Value of donation as portions of remaining estate at time of distribution, described as a percentage',
  },
  {
    field: 'mostRecentGenerationDate',
    headerName: 'Latest Doc Generated',
    width: 200,
    valueFormatter: ({ value }) => (value ? dates.format(value) : 'N/A'),
    description: 'Date document including donation was generated',
  },
  {
    field: 'coreProductPaymentDate',
    headerName: 'Payment Date',
    width: 200,
    valueFormatter: ({ value }) => (value ? dates.format(value) : 'N/A'),
    description: 'Date user made their original purchase',
  },
  {
    field: 'gender',
    headerName: 'Gender',
    width: 75,
    description: 'User\'s gender',
    valueFormatter: ({ value }) => (value ? _capitalize(value) : undefined),
  },
  {
    field: 'province',
    headerName: 'Province',
    width: 150,
    description: 'Province in which user resides',
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 75,
    description: 'Age of user',
  },
  {
    field: 'hasPartner',
    headerName: 'Has Partner',
    width: 150,
    description: 'If user has a partner',
    valueFormatter: ({ value }) => (value ? _capitalize(value) : undefined),
  },
  {
    field: 'allToPartner',
    headerName: 'All To Partner',
    width: 150,
    description: 'If user has left full estate to partner',
    valueFormatter: ({ value }) => (value ? _capitalize(value) : 'No'),
  },
  {
    field: 'hasChildren',
    headerName: 'Has Children',
    width: 150,
    description: 'If user has any children',
    valueFormatter: ({ value }) => (value ? _capitalize(value) : undefined),
  },
]

const totalledData: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 350,
    description: 'Name of the charity',
    valueFormatter: ({ value }) => value.toUpperCase(),
  },
  {
    field: 'number',
    headerName: 'Number',
    width: 150,
    description: 'Registration number of charity if available',
    valueFormatter: ({ value }) => (value ? value : 'N/A'),
  },
  {
    field: 'count',
    headerName: 'Count',
    width: 75,
    align: 'right',
    headerAlign: 'right',
    description: 'Total count of distribution gifts left to charity',
  },
  {
    field: 'averagePercentage',
    headerName: 'Average Portion (as Percentage)',
    width: 250,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    description: 'Average value of donation as portions of remaining estate at time of distribution, described as a percentage',
  },
  {
    field: 'averageAge',
    headerName: 'Average Age',
    type: 'number',
    width: 125,
    description: 'Average age of donor',
    valueFormatter: ({ value }) => value.toFixed(2),
  },
  {
    field: 'countGenerated',
    headerName: 'Count Generated',
    width: 150,
    description: 'Count of donors who have generated their documents',
    type: 'number'
  },
  {
    field: 'countPaid',
    headerName: 'Count Paid',
    width: 150,
    description: 'Count of donors who have paid',
    type: 'number'
  },
  {
    field: 'countHasPartner',
    headerName: 'Count Has Partner',
    width: 150,
    description: 'Count of donors who have a partner',
    type: 'number'
  },
  {
    field: 'countNoPartner',
    headerName: 'Count No Partner',
    width: 150,
    description: 'Count of donors who do not have a partner',
    type: 'number'
  },
  {
    field: 'countHasChildren',
    headerName: 'Count Has Children',
    width: 150,
    description: 'Count of donors who have children',
    type: 'number'
  },
  {
    field: 'countNoChildren',
    headerName: 'Count No Children',
    width: 150,
    description: 'Count of donors who have no children',
    type: 'number'
  },
  {
    field: 'countGenderMale',
    headerName: 'Count Male Donors',
    width: 150,
    description: 'Count of donors who identify as Male',
    type: 'number'
  },
  {
    field: 'countGenderFemale',
    headerName: 'Count Female Donors',
    width: 150,
    description: 'Count of donors who identify as Female',
    type: 'number'
  },
  {
    field: 'countGenderOther',
    headerName: 'Count Other Donors',
    width: 150,
    description: 'Count of donors who identify as neither Male nor Female',
    type: 'number'
  },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  allData,
  totalledData
}