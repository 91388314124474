import { useQuery } from 'react-query'

import { queryClient } from 'utils'
import { getRoles, RoleEndpoint } from 'utils/api/role.api'

const useRoles = () => useQuery({
  queryKey: RoleEndpoint.Role,
  queryFn: getRoles,
  retry: false,
  staleTime: 30000,
  refetchOnWindowFocus: true,
  enabled: !window.location.pathname.includes('/login'),
  onError: () => {
    queryClient.invalidateQueries()
    window.location.href = '/login'
  },
})

export default useRoles