import { useQuery } from 'react-query'
import { CharityPartnerEndpoint, getCharityPartners, GetCharityPartnersParams } from 'utils/api/charityPartner.api'

const useGetCharityPartners = (params: GetCharityPartnersParams) => useQuery({
  queryKey: [CharityPartnerEndpoint.CharityPartner, params],
  queryFn: () => getCharityPartners(params),
  retry: false,
  staleTime: 60000, // 1 minute
  refetchOnWindowFocus: false,
  onError: (error) => {
    console.error(error)
  },
})


export default useGetCharityPartners